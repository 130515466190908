import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";
import { doDeleteUserAccount, doReauthenticate, doSocialReauthenticate, getCurrentUser } from "../firebase/auth";
import * as routes from "../constants/routes";

import lockIcon from "../assets/img/icons/icon-lock.svg";
import { appleAuthProvider, googleAuthProvider } from "../firebase/firebase";

const DEFAULT_DELETE_ACCOUNT_STATE = {
  currentPassword: "",
  isLoading: false,
  isFocused: false,
};

const DeleteAccountForm = ({ setError }) => {
  const currentPasswordRef = useRef(null);
  const history = useHistory();

  const [passwordUpdateState, setPasswordUpdateState] = useState(DEFAULT_DELETE_ACCOUNT_STATE);

  const user = getCurrentUser();
  const hasPasswordProvider = !!user.providerData.find(provider => provider.providerId === "password");
  const hasGoogleProvider = !!user.providerData.find(provider => provider.providerId.includes("google"));
  const hasAppleProvider = !!user.providerData.find(provider => provider.providerId.includes("apple"));

  useEffect(() => {
    if (hasPasswordProvider && !passwordUpdateState.isFocused) {
      currentPasswordRef.current.focus();
    }
  }, [hasPasswordProvider, passwordUpdateState.isFocused]);

  const handleFieldChange = e => {
    setError("");
    const { name, value } = e.currentTarget;
    setPasswordUpdateState({ ...passwordUpdateState, [name]: value });
  };

  const isInvalid = !hasPasswordProvider ? false : passwordUpdateState.currentPassword === "";

  const handleSubmit = async event => {
    const { currentPassword } = passwordUpdateState;
    event.preventDefault();
    try {
      if (hasPasswordProvider) {
        await doReauthenticate(currentPassword);
      } else if (hasGoogleProvider) {
        await doSocialReauthenticate(googleAuthProvider);
      } else if (hasAppleProvider) {
        await doSocialReauthenticate(appleAuthProvider);
      }
      await doDeleteUserAccount();
      setError("");
      history.push(routes.SIGN_IN);
    } catch (err) {
      setError(err?.message);
    }
  };

  return (
    <div>
      {hasPasswordProvider ? (
        <p className="login-form-text">Please enter your current password to deactive account</p>
      ) : (
        <p className="login-form-text">Are you sure that you want to deactivate account?</p>
      )}
      <form className="login-form" onSubmit={handleSubmit}>
        {hasPasswordProvider ? (
          <div className="input-wrapper">
            <img className="input-icon" src={lockIcon} alt="" />
            <input
              name="currentPassword"
              type="password"
              ref={currentPasswordRef}
              placeholder="Current password"
              value={passwordUpdateState.currentPassword}
              onChange={handleFieldChange}
            />
          </div>
        ) : null}
        <div className="login-form-buttons">
          <button
            className="custom_button custom_button__outlined"
            type="button"
            onClick={() => history.push(routes.ACCOUNT)}
          >
            Cancel
          </button>
          <button className="custom_button" type="submit" disabled={passwordUpdateState.isLoading || isInvalid}>
            {passwordUpdateState.isLoading ? "Deactivating..." : "Deactivate"}
          </button>
        </div>
      </form>
    </div>
  );
};

DeleteAccountForm.propTypes = {
  setError: PropTypes.func.isRequired,
};

export default DeleteAccountForm;

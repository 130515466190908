import React from "react";

import logo from "../assets/img/logo.png";

export default function Header() {
  return (
    <div className="is-header">
      <img src={logo} alt="Skyward's logo" />
    </div>
  );
}

import React from "react";
import { useLocation } from "react-router-dom";
import * as PropTypes from "prop-types";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";

const Stepper = ({ currentStep }) => {
  // if

  const location = useLocation();

  if (location.pathname.includes("edit")) {
    return null;
  }

  return (
    <Steps current={currentStep}>
      <Steps.Step title="ADD TOUR DETAILS" />
      <Steps.Step title="UPLOAD IMAGES" />
      <Steps.Step title="LINK ROOMS" />
    </Steps>
  );
};

Stepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default Stepper;

import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";
import * as routes from "../constants/routes";
import { doSignInWithEmailAndPassword, getCurrentUser } from "../firebase/auth";

import emailIcon from "../assets/img/icons/icon-email.svg";
import lockIcon from "../assets/img/icons/icon-lock.svg";
import { doUpdateUser } from "../firebase/db";

const DEFAULT_SIGNIN_STATE = {
  username: "",
  password: "",
  isLoading: false,
  isFocused: false,
};

const LoginForm = ({ setError, withError, rememberMe, setRememberMe }) => {
  const usernameRef = useRef(null);

  const [signInState, setSignInState] = useState(DEFAULT_SIGNIN_STATE);

  const history = useHistory();

  const setSuccessLogin = () => {
    setSignInState({ ...signInState, isLoading: false });
  };

  const setErrorLogin = error => {
    setSignInState({
      username: "",
      password: "",
      isLoading: false,
      isFocused: true,
    });
    setError(error);
  };

  const handleFieldChange = e => {
    const { name, value } = e.currentTarget;
    setSignInState({ ...signInState, [name]: value });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await doSignInWithEmailAndPassword(signInState.username, signInState.password);
      const user = getCurrentUser();
      await doUpdateUser(user.uid, { rememberMe });
    } catch (err) {
      return setErrorLogin(err?.message);
    }
    setSuccessLogin();

    return history.push(routes.TOURS);
  };

  useEffect(() => {
    if (signInState.isFocused) {
      usernameRef.current.focus();
    }
  }, [signInState.isFocused]);

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="input-wrapper">
        <img className="input-icon" src={emailIcon} alt="" />
        <input
          name="username"
          type="email"
          ref={usernameRef}
          placeholder="Enter email"
          value={signInState.username}
          onChange={handleFieldChange}
          className={`${withError ? "with-error" : ""}`}
        />
      </div>
      <div className="input-wrapper">
        <img className="input-icon" src={lockIcon} alt="" />
        <input
          name="password"
          type="password"
          placeholder="Enter password"
          value={signInState.password}
          onChange={handleFieldChange}
          className={`${withError ? "with-error" : ""}`}
        />
      </div>

      <div className="login-info-row">
        <div className="checkbox">
          <label htmlFor="rememberMe" className={`${rememberMe ? "checked" : ""}`}>
            <input
              type="checkbox"
              id="rememberMe"
              name="rememberMe"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
            <span>Remember me</span>
          </label>
        </div>

        <a href={routes.PASSWORD_FORGET} className="forgot-password">
          Forgot password?
        </a>
      </div>

      <button className="custom_button" type="submit" disabled={signInState.isLoading}>
        {signInState.isLoading ? "Signing In....." : "Continue"}
      </button>
    </form>
  );
};

LoginForm.propTypes = {
  setError: PropTypes.func.isRequired,
  withError: PropTypes.bool,
  rememberMe: PropTypes.bool.isRequired,
  setRememberMe: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  withError: undefined,
};

export default LoginForm;

import React from "react";
import AccountSettingsWrapper from "../../components/AccountSettings/AccountSettingsWrapper";
import AccountDeactivation from "../../components/AccountDeactivation";

const AccountMobileDeactivation = () => {
  return (
    <AccountSettingsWrapper title="Account Deactivation">
      <AccountDeactivation />
    </AccountSettingsWrapper>
  );
};

export default AccountMobileDeactivation;

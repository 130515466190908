import React from "react";
import * as routes from "../constants/routes";

import deleteIcon from "../assets/img/icons/icon-cancel.svg";

const AccountDeactivation = () => {
  return (
    <div className="settings-form__wrapper">
      <div className="settings-form__title">
        <img src={deleteIcon} alt="" />
        Account Deactivation
      </div>
      <div className="settings-form">
        <p>What happens when you deactivate your account?</p>
        <ul>
          <li>Your tour links will be deactivated and won&apos;t be shown in public anymore.</li>
          <li>Active subscription will be cancelled.</li>
          <li>All tours will be archived automatically.</li>
        </ul>
        <a className="custom_button__light" href={routes.DELETE_ACCOUNT}>
          Deactivate
        </a>
      </div>
    </div>
  );
};

export default AccountDeactivation;

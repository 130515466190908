import React from "react";
import { getCurrentUser } from "../firebase/auth";
import LoginWrapper from "../components/LoginWrapper";

const CompleteSignUp = () => {
  const authUser = getCurrentUser();

  return (
    <LoginWrapper loginFormWide title="Thanks For Signing Up!">
      <div className="login-form-text">
        <p>
          Please check your email and click <strong>Activate Account</strong> in the message we just sent to
          <span className="login-form-link"> {authUser.email} </span>and complete sign up.
        </p>
        <p>
          Once your account is activated, we will send you an email with some information to help you get started with
          Skyward
        </p>
      </div>
    </LoginWrapper>
  );
};

export default CompleteSignUp;

import React from "react";
import LoginWrapper from "../components/LoginWrapper";

const ResetLinkSent = () => {
  return (
    <LoginWrapper title="Reset link sent" page="resetPassword">
      <p className="login-form-text">Please check your mailbox in order to restore your password</p>
    </LoginWrapper>
  );
};

export default ResetLinkSent;

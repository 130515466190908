import React, { useState, useCallback } from "react";
import { doSendEmailVerification } from "../firebase/auth";
import LoginWrapper from "../components/LoginWrapper";

const NeedEmailVerification = () => {
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState("");

  const onResendEmailVerification = useCallback(async () => {
    try {
      await doSendEmailVerification();
    } catch (err) {
      return setError(err?.message);
    }

    return setIsSent(true);
  }, []);

  return (
    <LoginWrapper title={isSent ? "E-Mail confirmation sent" : "Verify your E-Mail"} error={error} page="verifyEmail">
      {isSent ? (
        <p className="login-form-text">
          Check you E-Mails (Spam folder included) for a confirmation E-Mail. Refresh this page once you confirmed your
          E-Mail.
        </p>
      ) : (
        <p className="login-form-text">
          Check you E-Mails (Spam folder included) for a confirmation E-Mail or send another confirmation E-Mail.
        </p>
      )}
      <button className="custom_button" type="button" onClick={onResendEmailVerification} disabled={isSent}>
        Resend confirmation E-Mail
      </button>
    </LoginWrapper>
  );
};

export default NeedEmailVerification;

import React, { useState, useEffect, useRef, useCallback } from "react";
import * as PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import qs from "qs";
import { ReactComponent as PlusIcon } from "../assets/img/plus-circle.svg";
import { ReactComponent as MinusIcon } from "../assets/img/minus-circle.svg";

import iconPlusLG from "../assets/img/icons/icon-plus-circle.svg";
import iconMinusLG from "../assets/img/icons/icon-minus-circle.svg";

import iconClose from "../assets/img/icons/icon-close-modal.svg";

const MinMaxFilter = ({
  min,
  max,
  title,
  placeholder,
  defaultMinValue,
  defaultMaxValue,
  handleClear,
  handleChange,
  isVisibleOverride,
  minCount,
  setMinCount,
  maxCount,
  setMaxCount,
  largeIcons,
}) => {
  const [isVisibleValue, setIsVisible] = useState(false);
  const isVisible = isVisibleValue || isVisibleOverride;
  const [currentValues, setCurrentValues] = useState({ min: null, max: null });

  const ref = useRef();

  useEffect(() => {
    if (minCount !== defaultMinValue || maxCount !== defaultMaxValue) {
      setCurrentValues({
        min: minCount,
        max: maxCount,
      });
    }
  }, [defaultMaxValue, defaultMinValue, maxCount, minCount]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const handleUpdate = (minValue, maxValue) => {
    handleChange({ min: minValue, max: maxValue });
    setCurrentValues({ min: minValue, max: maxValue });
    // setIsVisible(false);
  };

  const handleDecreaseMinCount = () => {
    if (minCount > min) {
      setMinCount(minCount - 1);
      handleUpdate(minCount - 1, maxCount);
    }
  };

  const handleIncreaseMinCount = () => {
    if (minCount < max && minCount < maxCount) {
      setMinCount(minCount + 1);
      handleUpdate(minCount + 1, maxCount);
    }
  };

  const handleDecreaseMaxCount = () => {
    if (maxCount > min && maxCount > minCount) {
      setMaxCount(maxCount - 1);
      handleUpdate(minCount, maxCount - 1);
    }
  };

  const handleIncreaseMaxCount = () => {
    if (maxCount < max) {
      setMaxCount(maxCount + 1);
      handleUpdate(minCount, maxCount + 1);
    }
  };

  const handleReset = event => {
    event.stopPropagation();
    handleClear();
    setCurrentValues({ min: null, max: null });
    setIsVisible(false);
    setMinCount(defaultMinValue);
    setMaxCount(defaultMaxValue);
  };

  return (
    <div className={`range-filter ${largeIcons && "large-icons"} `}>
      <div className={`range-value ${isVisible ? "is-visible" : ""}`} onClick={() => setIsVisible(!isVisible)}>
        <div className="title">{title}</div>

        {currentValues.min && currentValues.max ? (
          <div className="range-value-wrapper">
            <div className="placeholder">{`${currentValues.min} - ${currentValues.max}`}</div>

            <button className="clear-button" type="button" onClick={handleReset}>
              <img src={iconClose} alt="" />
            </button>
          </div>
        ) : (
          <div className="placeholder">{placeholder}</div>
        )}
      </div>
      {isVisible && (
        <div className="count-popup" ref={ref}>
          <div className="container">
            <div>MAX</div>
            <div className="counter">
              <button type="button" className="decrease" onClick={handleDecreaseMaxCount}>
                <span>
                  <img src={iconMinusLG} className="counter-icon" alt="" />
                  <MinusIcon />
                </span>
              </button>
              <div className="current-value">{maxCount}</div>
              <button type="button" className="increase" onClick={handleIncreaseMaxCount}>
                <span>
                  <img src={iconPlusLG} className="counter-icon" alt="" />
                  <PlusIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="container">
            <div>MIN</div>
            <div className="counter">
              <button type="button" className="decrease" onClick={handleDecreaseMinCount}>
                <span>
                  <img src={iconMinusLG} className="counter-icon" alt="" />
                  <MinusIcon />
                </span>
              </button>
              <div className="current-value">{minCount}</div>
              <button type="button" className="increase" onClick={handleIncreaseMinCount}>
                <span>
                  <img src={iconPlusLG} className="counter-icon" alt="" />
                  <PlusIcon />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MinMaxFilter.propTypes = {
  defaultMinValue: PropTypes.number,
  defaultMaxValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,

  handleClear: PropTypes.func,
  handleChange: PropTypes.func,
  isVisibleOverride: PropTypes.bool,
  minCount: PropTypes.number.isRequired,
  setMinCount: PropTypes.func.isRequired,
  maxCount: PropTypes.number.isRequired,
  setMaxCount: PropTypes.func.isRequired,
  largeIcons: PropTypes.bool,
};

MinMaxFilter.defaultProps = {
  defaultMinValue: 1,
  defaultMaxValue: 1,
  min: 1,
  max: 1,

  handleClear: () => {},
  handleChange: () => {},

  isVisibleOverride: false,
  largeIcons: false,
};

export const useMinMaxFilter = (defaultMinValue, defaultMaxValue, name) => {
  const [minCount, setMinCount] = useState(defaultMinValue);
  const [maxCount, setMaxCount] = useState(defaultMaxValue);

  const location = useLocation();

  useEffect(() => {
    const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (searchParams?.[`filter_${name}`]) {
      setMinCount(Number(searchParams?.[`filter_${name}`]?.min));
      setMaxCount(Number(searchParams?.[`filter_${name}`]?.max));
    }
  }, [location.search, name]);

  const clear = useCallback(() => {
    setMinCount(defaultMinValue);
    setMaxCount(defaultMaxValue);
  }, [defaultMaxValue, defaultMinValue]);

  return { minCount, setMinCount, maxCount, setMaxCount, clear };
};

export default MinMaxFilter;

// eslint-disable-next-line import/prefer-default-export
export const SET_TOUR_DATA = "SET_TOUR_DATA";
export const SET_TOURS_DATA = "SET_TOURS_DATA";

export const SET_SUBSCRIPTIONS_STARTED = "SET_SUBSCRIPTIONS_STARTED";
export const SET_SUBSCRIPTIONS_DATA = "SET_SUBSCRIPTIONS_DATA";
export const SET_SUBSCRIPTIONS_ERROR = "SET_SUBSCRIPTIONS_ERROR";

export const SET_CURRENT_SUBSCRIPTION_STARTED = "SET_CURRENT_SUBSCRIPTION_STARTED";
export const SET_CURRENT_SUBSCRIPTION_DATA = "SET_CURRENT_SUBSCRIPTION_DATA";
export const SET_CURRENT_SUBSCRIPTION_ERROR = "SET_CURRENT_SUBSCRIPTION_ERROR";

export const SET_BILLING_INFO_STARTED = "SET_BILLING_INFO_STARTED";
export const SET_BILLING_INFO_DATA = "SET_BILLING_INFO_DATA";
export const SET_BILLING_INFO_ERROR = "SET_BILLING_INFO_ERROR";

export const SET_INVOICES_STARTED = "SET_INVOICES_STARTED";
export const SET_INVOICES_DATA = "SET_INVOICES_DATA";
export const SET_INVOICES_ERROR = "SET_INVOICES_ERROR";

export const UPDATE_USER_PLAN_STARTED = "UPDATE_USER_PLAN_STARTED";
export const UPDATE_USER_PLAN_DATA = "UPDATE_USER_PLAN_DATA";
export const UPDATE_USER_PLAN_ERROR = "UPDATE_USER_PLAN_ERROR";
export const RESET_USER_PLAN_FIELDS = "RESET_USER_PLAN_FIELDS";

export const CANCEL_SUBSCRIPTION_STARTED = "CANCEL_SUBSCRIPTION_STARTED";
export const CANCEL_SUBSCRIPTION_DATA = "CANCEL_SUBSCRIPTION_DATA";
export const CANCEL_SUBSCRIPTION_ERROR = "CANCEL_SUBSCRIPTION_ERROR";

export const RESET_RECURLY_STORE = "RESET_RECURLY_STORE";

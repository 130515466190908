export const roomTypeOptions = [
  { label: "Bedroom", value: "bed" },
  { label: "Living room", value: "living" },
  { label: "Kitchen", value: "kitchen" },
  { label: "Bathroom", value: "bathroom" },
  { label: "Home office", value: "home_office" },
  {
    label: "Dining room",
    value: "dining",
  },
];

export const roomStyleOptions = [
  { label: "Standard", value: "standard" },
  { label: "Modern", value: "modern" },
  { label: "Scandinavian", value: "scandinavian" },
  { label: "Industrial", value: "industrial" },
  { label: "Midcentury", value: "midcentury" },
  { label: "Luxury", value: "luxury" },
  { label: "Coastal", value: "coastal" },
  { label: "Farmhouse", value: "farmhouse" },
];

export const renderStatuses = {
  error: "error",
  done: "done",
  rendering: "rendering",
  queued: "queued",
};

export const inProgressStatuses = [renderStatuses.rendering, renderStatuses.queued];
export const finishedStatuses = [renderStatuses.done, renderStatuses.error];

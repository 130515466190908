import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalSubscription from "../modals/ModalSubscription";

import ModalPurchasePlan from "../modals/ModalPurchasePlan";
import { resetUserPlanFields } from "../../redux/actions/recurlyActions";
import ModalPlanUpdated from "../modals/ModalPlanUpdated";
import ModalCancelSubscription from "../modals/ModalCancelSubscription";

const SubscriptionFlow = ({ children, isSubscribeModalVisible, onSubscribeModalClose }) => {
  const dispatch = useDispatch();

  const [shownSubModal, setShownSubModal] = useState(false);
  const [selectedSub, setSelectedSub] = useState(null);

  const updatingPlanSuccess = useSelector(state => state.recurly.updatingPlanSuccess);

  const closeModal = () => {
    setShownSubModal(false);
  };
  const togglePurchaseModal = () => {
    setSelectedSub(null);
  };

  const setPlanId = id => {
    setSelectedSub(id);
    setShownSubModal(false);
  };

  const onCloseSuccessModal = () => {
    setSelectedSub(null);
    resetUserPlanFields(dispatch);
  };

  return (
    <>
      {children}

      {(shownSubModal || isSubscribeModalVisible) && (
        <ModalSubscription
          onPress={setPlanId}
          onToggle={() => {
            closeModal();
            onSubscribeModalClose();
          }}
        />
      )}
      {selectedSub && selectedSub !== "01" && selectedSub !== "02" && updatingPlanSuccess !== true && (
        <ModalPurchasePlan id={selectedSub} onToggle={togglePurchaseModal} />
      )}
      {(selectedSub === "01" || selectedSub === "02") && <ModalCancelSubscription onToggle={onCloseSuccessModal} />}
      {updatingPlanSuccess === true && <ModalPlanUpdated onToggle={onCloseSuccessModal} />}
    </>
  );
};

export default SubscriptionFlow;

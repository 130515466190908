import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import * as PropTypes from "prop-types";

import { useDispatch } from "react-redux";

import { FREE_SUBSCRIPTION_PLAN, UNLIMITED_SUBSCRIPTION_PLAN } from "../../constants/subscriptions";
import { loadCurrentSubscriptions } from "../../redux/actions/recurlyActions";
import useSubscriptionType from "../../hooks/useSubscriptionType";
import { formatPrice } from "../../utils";
import ModalEnterpisePricing from "../modals/ModalEnterpisePricing";

const SubscriptionItem = ({ id, type, price, options, monthly, onPress, currency }) => {
  const [shownModal, setShownModal] = useState(false);
  const toggleModal = () => setShownModal(!shownModal);
  const dispatch = useDispatch();
  const { type: subscriptionType, currentSubscriptionMonthly } = useSubscriptionType();
  const formattedPrice = formatPrice(price, currency);
  useEffect(() => {
    loadCurrentSubscriptions(dispatch);
  }, [dispatch]);

  let isActiveSub = false;

  if (subscriptionType === type && type === FREE_SUBSCRIPTION_PLAN.type) {
    isActiveSub = true;
  } else if (
    subscriptionType === type &&
    ((monthly && currentSubscriptionMonthly) || (!monthly && !currentSubscriptionMonthly))
  ) {
    isActiveSub = true;
  }

  const onPaymentAddMethodClick = () => {
    onPress(id);
  };

  return (
    <>
      <div className="subscription-item">
        <div className="main-section">
          {type === UNLIMITED_SUBSCRIPTION_PLAN.type ? (
            <div>
              <p className="subscription-type">Unlimited Plan</p>
              <p>Affordable Virtual Staging for Every Budget</p>
            </div>
          ) : (
            <p className="subscription-type">{type}</p>
          )}
          <div>
            <p>Price off</p>
            <p className="subscription-price subscription-price__month">
              <span>{formattedPrice}</span> / {monthly ? "month" : "year"}
            </p>
          </div>
          <div className="subscribe-section">
            {isActiveSub ? (
              <p className="subscription-item__alert">You are already using this plan</p>
            ) : (
              <>
                <button className="custom_button" type="button" onClick={onPaymentAddMethodClick}>
                  SUBSCRIBE NOW
                </button>
                <button className="custom_button custom_button__outlined" type="button" onClick={toggleModal}>
                  CONTACT
                </button>
              </>
            )}
          </div>
        </div>
        <div className="options">
          <p>What you'll receive</p>
          <ul className="subscription-options">
            {options.map(option => (
              <li key={option}>{option}</li>
            ))}
          </ul>
        </div>
      </div>
      {shownModal && createPortal(<ModalEnterpisePricing onToggle={toggleModal} />, document.body, "contact")}
    </>
  );
};

SubscriptionItem.defaultProps = {
  monthly: true,
  onPress: () => null,
};

SubscriptionItem.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  monthly: PropTypes.bool,
  onPress: PropTypes.func,
};

export default SubscriptionItem;

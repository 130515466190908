import React, { useMemo, useState } from "react";
import moment from "moment";

import addressIcon from "../../assets/img/icons/icon-address-card.svg";
import { capitalize } from "../../utils";
import useSubscriptionType from "../../hooks/useSubscriptionType";
import SubscriptionFlow from "../SubscriptionFlow";

const SubscriptionLevel = () => {
  const [shownSubModal, setShownSubModal] = useState(false);
  const { type, pendingChange, currentPeriodEndsAt, isCanceled } = useSubscriptionType();

  let subName = "loading...";
  if (type) {
    subName = type;
  }
  const toggleSubModal = () => {
    setShownSubModal(value => !value);
  };

  const detailsText = useMemo(() => {
    if (pendingChange) {
      return `Your ${pendingChange.plan.name.toUpperCase()} plan should start on ${moment(currentPeriodEndsAt).format(
        "DD/MM/YYYY"
      )}`;
    }
    if (isCanceled) {
      return `Your FREE plan should start on ${moment(currentPeriodEndsAt).format("DD/MM/YYYY")}`;
    }

    return `Your current subscription will renew on ${moment(currentPeriodEndsAt).format("DD/MM/YYYY")}`;
  }, [currentPeriodEndsAt, pendingChange, isCanceled]);

  return (
    <SubscriptionFlow isSubscribeModalVisible={shownSubModal} onSubscribeModalClose={() => toggleSubModal(false)}>
      <section className="settings-subscription__row">
        <div className="two-lines">
          <div className="settings-form__title">
            <img src={addressIcon} alt="" />
            Subscription Level - {capitalize(subName)}
          </div>
          {currentPeriodEndsAt && <p className="subscription-date-info">{detailsText}</p>}
        </div>

        <button className="custom_button__light custom_button-sm-width" onClick={toggleSubModal} type="button">
          Change
        </button>
      </section>
    </SubscriptionFlow>
  );
};

export default SubscriptionLevel;

import { db } from "./firebase";

export const doCreateUser = (id, { username, email, rememberMe = false }) =>
  db.ref(`users/${id}`).set({
    username,
    email,
    rememberMe,
  });

export const onceGetUsers = () => db.ref("users").once("value");

export const doGetUser = uid => db.ref(`users/${uid}/`);
export const doUpdateUser = (uid, params) => db.ref(`users/${uid}/`).update({ ...params });
export const doGetUserValue = uid => db.ref(`users/${uid}/`).once("value");

export const doRemoveUser = async uid => {
  const ref = await doGetUser(uid);
  await ref.remove();
};

export const doGetAgent = uid => db.ref(`agents/${uid}`).once("value");

export const doCreateAgent = (id, { name, title, businessName, address, email, phone }) =>
  db.ref(`agents/${id}`).set({
    name,
    title,
    businessName,
    address,
    email,
    phone,
  });

export const doUpdateAgent = (id, params) => db.ref(`agents/${id}`).update({ ...params });

export const doUpdateAgentAvatarURL = (id, { avatarURL }) => db.ref(`agents/${id}`).update({ avatarURL });

export const doGetToursByUserSubscription = userId => db.ref(`db/${userId}/tours`);
export const doGetArchivedToursByUserSubscription = userId => db.ref(`db/${userId}/archive/tours`);

export const doGetToursByUser = userId => db.ref(`db/${userId}/tours`).once("value");
export const doGetArchivedToursByUser = userId => db.ref(`db/${userId}/archive/tours`).once("value");

export const doUpdateToursByUser = (userId, params) =>
  db.ref(`db/${userId}/tours`).update({
    ...params,
  });

export const doUpdateTourById = (userId, tourId, tour) =>
  db.ref(`db/${userId}/tours/${tourId}`).update({
    ...tour,
  });

export const doUpdateTourViews = async (userId, tourId, timestamp, fingerPrint) => {
  const ref = db.ref(`db/${userId}/tours/${tourId}/views/${timestamp}`);
  const views = (await ref.once("value")).val();
  const fingerPrints = views?.fingerPrints || [];

  if (!fingerPrints.find(value => value === fingerPrint)) {
    fingerPrints.push(fingerPrint);
  }

  ref.set({
    dailyViews: (views?.dailyViews || 0) + 1,
    fingerPrints,
  });
};

export const doCreateToursByUser = async (userId, params) => db.ref(`db/${userId}/tours`).push({ ...params });
export const doCreatePublicTour = async params => db.ref(`db/public/tours`).push({ ...params });

export const doEditTour = async (userId, tourId, wasPublic, tour) => {
  if (wasPublic === tour.publicTour) {
    return db.ref(`db/${tour.publicTour ? "public" : userId}/tours/${tourId}`).update(tour);
  }

  const oldTour = (await db.ref(`db/${tour.publicTour ? "public" : userId}/tours/${tourId}`).once("value")).val();

  await db.ref(`db/${tour.publicTour ? "public" : userId}/tours/${tourId}`).set({ ...oldTour, ...tour });

  return db.ref(`db/${wasPublic ? "public" : userId}/tours/${tourId}`).remove();
};

export const doCreateImages = async (userId, tourId, images) => {
  try {
    const imageRef = db.ref(`db/${userId}/tours/${tourId}/images`);

    const updates = images.reduce((update, data) => {
      const id = imageRef.push().key;
      // eslint-disable-next-line no-param-reassign
      update[id] = { ...data, id };

      return update;
    }, {});

    const response = await imageRef.update(updates);

    return response;
  } catch (e) {
    return e;
  }
};

export const doUpdateImage = (userId, tourId, data) => {
  const imageRef = db.ref(`db/${userId}/tours/${tourId}/images`);

  return imageRef.update(data);
};

export const doRemoveTour = async (userId, tourId) => {
  const tourRef = await db.ref(`db/${userId}/tours/${tourId}`);

  return tourRef.remove();
};

export const doArchiveTour = async (userId, tourId, isPublic) => {
  const tour = (await db.ref(`db/${isPublic ? "public" : userId}/tours/${tourId}`).once("value")).val();
  await db.ref(`db/${userId}/archive/tours/${tourId}`).set(tour);

  return db.ref(`db/${isPublic ? "public" : userId}/tours/${tourId}`).remove();
};

export const doDearchiveTour = async (userId, tourId) => {
  const tour = (await db.ref(`db/${userId}/archive/tours/${tourId}`).once("value")).val();

  await db.ref(`db/${tour.publicTour ? "public" : userId}/tours/${tourId}`).set(tour);

  return db.ref(`db/${userId}/archive/tours/${tourId}`).remove();
};

export const doSetImagePins = async (userId, tourId, pins) => {
  const imageRef = await db.ref(`db/${userId}/tours/${tourId}/images`);

  const updates = {};

  // eslint-disable-next-line array-callback-return
  Object.keys(pins).map(imageId => {
    updates[`${imageId}/pins`] = pins[imageId].reduce((acc, prev) => {
      acc[prev.id] = prev;

      return acc;
    }, {});
  });

  return imageRef.update(updates);
};

export const doCreatePublicTourImage = (tourId, data) => {
  const imageRef = db.ref(`db/public/tours/${tourId}/images`);
  const newImageRef = imageRef.push();

  return newImageRef.set({ ...data, id: newImageRef.key });
};

export const doGetToursByID = async (userId, tourId) => {
  return db.ref(`/db/${userId}/tours/${tourId}`).once("value");
};

export const doArchiveTours = async (userId, size) => {
  const tours = (await db.ref(`db/${userId}/tours`).orderByChild("created_at").once("value")).val();
  const archivedTours = (await db.ref(`db/${userId}/archive/tours`).once("value")).val();

  const ids = Object.keys(tours).reverse().slice(size);

  const archived = {
    ...archivedTours,
  };

  const active = {
    ...tours,
  };

  ids.forEach(id => {
    archived[id] = tours[id];
    active[id] = null;
  });

  await db.ref(`db/${userId}/archive/tours`).set(archived);
  await db.ref(`db/${userId}/tours`).set(active);
};

export const doCreateVirtualStagingByUser = async (userId, id, params) =>
  db.ref(`db/${userId}/virtualStaging/${id}`).set({ ...params });

export const doGetVirtualStagingById = async (userId, id) =>
  (await db.ref(`db/${userId}/virtualStaging/${id}/`).once("value")).val();

export const doGetVirtualStagingsRefByUser = userId => db.ref(`db/${userId}/virtualStaging`);

export const doGetVirtualStagingRefById = (userId, id) => db.ref(`db/${userId}/virtualStaging/${id}/`);

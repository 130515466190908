import { SET_TOUR_DATA, SET_TOURS_DATA } from "../types";

const initialState = {
  tour: null,
  tourIsLoaded: false,
  tours: {},
  toursAreLoaded: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_TOUR_DATA:
      return {
        ...state,
        tour: payload,
        tourIsLoaded: true,
      };
    case SET_TOURS_DATA:
      return {
        ...state,
        tours: payload,
        tourAreLoaded: true,
      };
    default:
      return state;
  }
}

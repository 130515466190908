import React from "react";
import { NavLink } from "react-router-dom";

import * as routes from "../../constants/routes";
import Alert from "../Alert";

const AlertTourWithoutAgentInfo = () => {
  return (
    <Alert>
      Your personal agent info is still empty. Please, make sure that you fill all forms. Your data will be
      automatically shown on each your tour as your personal real estate agent data. Go to{" "}
      <NavLink to={routes.AGENT}>Settings &gt; Agent Info</NavLink> and add your information.
    </Alert>
  );
};

export default AlertTourWithoutAgentInfo;

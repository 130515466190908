import React from "react";
import * as PropTypes from "prop-types";

import Footer from "../Footer";

import HeaderCustom from "../HeaderCustom";

const AccountSettingsWrapper = ({ title, children }) => {
  return (
    <>
      <div className="acw-wrapper">
        <HeaderCustom title={title} />

        <div className="acw-body">{children}</div>
      </div>

      <Footer />
    </>
  );
};

AccountSettingsWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AccountSettingsWrapper;

import React, { useState, useLayoutEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import * as PropTypes from "prop-types";

import arrow from "../assets/img/icons/icon-arrow-top.svg";

const ArrowScrollTop = ({ onlyIcon, desktopVisible }) => {
  const [scrollPosition, setPosition] = useState(0);
  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return (
    <AnchorLink
      className={`scrolltopLink ${scrollPosition > window.innerHeight / 2 ? "is-active" : ""} ${
        desktopVisible ? "desktop-visible" : ""
      } ${onlyIcon ? "onlyIcon" : ""}`}
      href="#hero"
    >
      {onlyIcon ? undefined : "Back to top"}
      <img src={arrow} alt="" />
    </AnchorLink>
  );
};

ArrowScrollTop.propTypes = {
  desktopVisible: PropTypes.bool,
  onlyIcon: PropTypes.bool,
};

ArrowScrollTop.defaultProps = {
  desktopVisible: undefined,
  onlyIcon: undefined,
};

export default ArrowScrollTop;

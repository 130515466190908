import React, { useEffect, useState } from "react";
import { v4 } from "uuid";

import Footer from "../components/Footer";
import HeaderMain from "../components/HeaderMain";
import TourCard from "../components/TourCard";

import checkMobile from "../hooks/checkMobile";
import useScrollTopOnHistory from "../hooks/useScrollTopOnHistory";
import { doGetToursByUserSubscription } from "../firebase/db";

import heroMobile from "../assets/img/landing/community-bg__mobile.jpg";
import heroDesktop from "../assets/img/landing/community_bg__desktop.jpg";
import communityPlaceholderImage from "../assets/img/community-tour-placeholder.jpg";

const HeroBanner = () => {
  const isMobile = checkMobile();

  useScrollTopOnHistory();

  return (
    <div
      id="hero"
      className="hero_banner hero_banner__community-tours"
      style={{ backgroundImage: `url(${isMobile ? heroMobile : heroDesktop})` }}
    >
      <div className="is-container">
        <h1 className="heading_h1">Community Tours Feature</h1>
        <h2 className="heading_h2">
          Create, view and share your tours among other agents and freely use theirs. Subscribe in order to get access
          to the whole library of community tours.
        </h2>

        <a className="custom_button" href={`${window.location.origin}/#pricing`}>
          Pricing
        </a>
      </div>
    </div>
  );
};

const CommunityTours = () => {
  const [communityTours, setCommunityTours] = useState();
  const isMobile = checkMobile();

  const blurredCardsLength = isMobile ? 10 : 20;

  useEffect(() => {
    const ref = doGetToursByUserSubscription("public", {});
    const sequence = [
      "4f6f62ed-6aed-4d09-87f0-98f3c5cc7439",
      "-MNsz0mqkBmaKbpKJD2R",
      "-MNK0WTUQvu9weAONPFd",
      "-MMHXSfdf_Hlwei8YwjC",
      "e04259e7-8ae3-4360-8a6a-1fbdd5142c45",
    ];
    const listener = ref.on("value", snapshot => {
      const data = snapshot.val();
      const tours = Object.keys(data)
        .map(tourId => ({ ...data[tourId], tourId }))
        .filter(tour => tour.images)
        .sort(tour => (sequence.find(item => item === tour.tourId) ? 1 : -1));

      setCommunityTours(tours.slice(-5));
    });

    return () => (listener ? ref.off("value", listener) : null);
  }, []);

  const renderfilledTours = existingTours =>
    new Array(blurredCardsLength - existingTours)
      .fill(true)
      .map(() => (
        <TourCard
          wrapperClassName="tour-item__disabled"
          key={v4()}
          tourId=""
          name=""
          imageUrl={communityPlaceholderImage}
          bathroomCount={0}
          bedroomCount={0}
          area={0}
          created={0}
          daysInterval={1}
          changeTourOrder={() => {}}
          isLoading
          tourView="grid"
          ownerId=""
          totalViews={0}
          dndEnabled={false}
        />
      ));

  return (
    <div>
      <HeaderMain homepage />
      <HeroBanner />

      <div className="page-wrapper community-tours__page">
        <div className="is-container">
          <div className="tours-container grid-tours-view">
            {communityTours &&
              communityTours.map(
                ({
                  tourId,
                  ownerId,
                  name,
                  images,
                  bathroomCount,
                  bedroomCount,
                  area,
                  created_at,
                  views,
                  totalViews,
                  price,
                }) => {
                  const sortedImages = Object.values(images ?? {}).sort((a, b) => a.index - b.index);
                  const imageUrl = sortedImages[0]?.img_url;

                  return (
                    <TourCard
                      key={tourId}
                      tourId={tourId}
                      ownerId={ownerId}
                      name={name}
                      imageUrl={imageUrl}
                      tourView="grid"
                      bathroomCount={Number(bathroomCount)}
                      bedroomCount={Number(bedroomCount)}
                      area={Number(area)}
                      price={Number(price)}
                      created={created_at}
                      views={views}
                      totalViews={totalViews}
                      changeTourOrder={() => {}}
                    />
                  );
                }
              )}

            {communityTours && renderfilledTours(communityTours.length)}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CommunityTours;

import React, { useState } from "react";
import * as PropTypes from "prop-types";
import QRCode from "react-qr-code";
import Modal from "../Modal";

import iconAlert from "../../assets/img/icons/icon-alert-red.svg";
import iconCheckedSuccess from "../../assets/img/icons/icon-checked-success.svg";

const TABS = {
  link: "Link",
  embed: "Embed",
  qrcode: "QR-code",
};

const ModalShareTour = ({ onToggle, tourLink, embedLink, linkRef, embedRef, onCopyLink, onCopyEmbed, error }) => {
  const [activeTab, setActiveTab] = useState(TABS.link);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);

  const handleActiveTab = tab => {
    setActiveTab(tab);
    setIsSuccessVisible(false);
  };

  const handleCopyLink = event => {
    onCopyLink(event);
    setIsSuccessVisible(true);
  };

  const handleCopyEmbed = event => {
    onCopyEmbed(event);
    setIsSuccessVisible(true);
  };

  return (
    <Modal title="Share Tour" size="sm" type="share-tour" onToggle={onToggle}>
      <div className="share-tour-tabs-header">
        <button
          className={`${activeTab === TABS.link ? "is-active" : ""} settings-tabs-button`}
          type="button"
          onClick={() => handleActiveTab(TABS.link)}
        >
          {TABS.link}
        </button>
        <button
          className={`${activeTab === TABS.embed ? "is-active" : ""} settings-tabs-button`}
          type="button"
          onClick={() => handleActiveTab(TABS.embed)}
        >
          {TABS.embed}
        </button>
        <button
          className={`${activeTab === TABS.qrcode ? "is-active" : ""} settings-tabs-button`}
          type="button"
          onClick={() => handleActiveTab(TABS.qrcode)}
        >
          {TABS.qrcode}
        </button>
      </div>

      <div className="modal-body">
        {activeTab === TABS.link && (
          <div className="btn-group">
            <input ref={linkRef} value={tourLink} type="text" readOnly />
            <button onClick={handleCopyLink} className="custom_button__light" type="button">
              Copy
            </button>
          </div>
        )}
        {activeTab === TABS.embed && (
          <div className="btn-group">
            <input ref={embedRef} value={embedLink} type="text" readOnly />
            <button onClick={handleCopyEmbed} className="custom_button__light" type="button">
              Copy
            </button>
          </div>
        )}
        {activeTab === TABS.qrcode && (
          <div className="btn-group center">
            <QRCode value={tourLink} />
          </div>
        )}

        {error && (
          <p className="error">
            <img src={iconAlert} alt="" />
            {error}
          </p>
        )}

        {isSuccessVisible && (
          <p className="success">
            <img src={iconCheckedSuccess} alt="" />
            Copied!
          </p>
        )}
      </div>
    </Modal>
  );
};

ModalShareTour.propTypes = {
  onToggle: PropTypes.func.isRequired,
  tourLink: PropTypes.string.isRequired,
  embedLink: PropTypes.string.isRequired,
  linkRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  embedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  onCopyLink: PropTypes.func.isRequired,
  onCopyEmbed: PropTypes.func.isRequired,
  error: PropTypes.string,
};

ModalShareTour.defaultProps = {
  linkRef: null,
  embedRef: null,

  error: null,
};

export default ModalShareTour;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AccountSettingsWrapper from "../../components/AccountSettings/AccountSettingsWrapper";
import SubscriptionPaymentMethod from "../../components/AccountSettings/SubscriptionPaymentMethod";
import { loadBillingInfo } from "../../redux/actions/recurlyActions";

const mask = (value, limit, separator) => {
  const output = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < value.length; i++) {
    if (i !== 0 && i % limit === 0) {
      output.push(separator);
    }

    output.push(value[i]);
  }

  return output.join("");
};

const SubscriptionMobilePaymentMethod = () => {
  const dispatch = useDispatch();
  const billingInfo = useSelector(state => state.recurly.billingInfo);
  let placeholder = "Right now you don't have any payment methods and you can add new one.";
  if (billingInfo) {
    const card = `${billingInfo.firstSix}******${billingInfo.lastFour}`;
    const cardPlaceholder = mask(card, 4, " ");
    placeholder = `You already have payment method. Your credit card: ${cardPlaceholder}.`;
  }

  useEffect(() => {
    loadBillingInfo(dispatch);
  }, [dispatch]);

  return (
    <AccountSettingsWrapper title="Payment method">
      <div className="acw-content">
        <p>{placeholder}</p>
        <SubscriptionPaymentMethod />
      </div>
    </AccountSettingsWrapper>
  );
};

export default SubscriptionMobilePaymentMethod;

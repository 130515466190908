import { useState, useEffect } from "react";
import { detectMob } from "../utils";

export const createHotSpot = (hotSpotDiv, { image, text }) => {
  if (image) {
    const tooltip = document.createElement("div");
    tooltip.classList.add("custom-hotspot__tooltip");
    tooltip.dataset.tooltip = text;
    hotSpotDiv.appendChild(tooltip);
    // eslint-disable-next-line no-param-reassign
    hotSpotDiv.style.cssText = `background-image: url('${image.thumbnail_url}'`;
  } else {
    const circle = document.createElement("div");
    const dot = document.createElement("div");
    circle.classList.add("custom-hotspot__circle");
    dot.classList.add("custom-hotspot__dot");
    circle.appendChild(dot);
    hotSpotDiv.appendChild(circle);
  }
};

export const usePannelum = (tourImages, pins, activeTourId, clickHandlerFunc = () => undefined) => {
  const [viewer, setViewer] = useState();
  const isInitialized = !!tourImages && !!pins && !!activeTourId;
  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    setViewer(
      window.pannellum.viewer("panorama", {
        default: {
          northOffset: 247.5,
          autoLoad: true,
          autoRotate: -2,
          firstScene: activeTourId,
          sceneFadeDuration: 1300,
          hfov: detectMob() ? 100 : 200,
          horizonPitch: 0,
          horizonRoll: 0,
          keyboardZoom: false,
          autoRotateInactivityDelay: 2000,
        },
        scenes: tourImages.reduce((acc, prev) => {
          acc[prev.id] = {
            panorama: prev.img_url,
            hotSpots: pins[prev.id]
              ? pins[prev.id].map(pin => ({
                  ...pin,
                  clickHandlerFunc,
                  clickHandlerArgs: { id: pin.id, tour_id: pin.tour_id },
                  text: pin.name,
                  cssClass: "custom-hotspot",
                  createTooltipFunc: createHotSpot,
                  createTooltipArgs: {
                    image: tourImages.find(item => item.id === pin.tour_id),
                    text: pin.name,
                  },
                }))
              : [],
          };

          return acc;
        }, {}),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  useEffect(() => {
    const controls = document.querySelector(".pnlm-controls-container");

    if (!viewer || !controls) {
      return undefined;
    }

    const controlClickHandler = () => {
      viewer.stopMovement();
    };

    controls.addEventListener("click", controlClickHandler, true);

    return () => controls.removeEventListener("click", controlClickHandler, true);
  }, [viewer]);

  useEffect(() => {
    if (viewer) {
      viewer.loadScene(activeTourId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTourId]);

  return viewer;
};

import React from "react";
import { Route, useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import * as routes from "../constants/routes";

import HeaderMain from "../components/HeaderMain";
import PageWrapper from "../components/PageWrapper";
import TourCreateForm from "../components/TourCreateForm";
import TourUploadImages from "../components/TourUploadImages";
import TourLinkRooms from "../components/TourLinkRooms";
import HeaderCustom from "../components/HeaderCustom";

import checkMobile from "../hooks/checkMobile";

import arrowLeft from "../assets/img/icons/icon-arrow-left-blue.svg";

const TourCreate = ({ match }) => {
  const isMobile = checkMobile(767);
  const history = useHistory();

  return (
    <>
      {isMobile ? (
        <HeaderCustom onClick={() => history.push(routes.TOURS)} title="Back to all tours" />
      ) : (
        <HeaderMain />
      )}

      <PageWrapper>
        {!isMobile && (
          <div className="edit-tour-header">
            <div className="page-back-link">
              <a href={routes.TOURS}>
                {" "}
                <img src={arrowLeft} alt="" />
                <span>BACK TO ALL TOURS</span>
              </a>
            </div>
          </div>
        )}

        <div className="tour-wrapper">
          <div className="tour-wrapper__header">Add new tour</div>
          <Route exact path={`${match.path}/:tourId?`} component={TourCreateForm} />
          <Route exact path={`${match.path}${routes.TOUR_CREATE_IMAGES}`} component={TourUploadImages} />
          <Route exact path={`${match.path}${routes.TOUR_CREATE_ROOMS}`} component={TourLinkRooms} />
        </div>
      </PageWrapper>
    </>
  );
};

TourCreate.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
};

export default TourCreate;

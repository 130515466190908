export const detectMob = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const ASCSortFunction = value => (a, b) => {
  if (a[value] > b[value]) {
    return 1;
  }
  if (a[value] < b[value]) {
    return -1;
  }

  return 0;
};

export const DESCSortFunction = value => (a, b) => {
  if (a[value] > b[value]) {
    return -1;
  }
  if (a[value] < b[value]) {
    return 1;
  }

  return 0;
};

function move(array, oldIndex, newIndex) {
  if (newIndex >= array.length) {
    // eslint-disable-next-line no-param-reassign
    newIndex = array.length - 1;
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);

  return [...array];
}

export function formatPrice(price, currency) {
  if (typeof price !== "number") {
    return NaN;
  }
  if (typeof currency !== "string") {
    return NaN;
  }

  return price.toLocaleString("en-EN", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
  });
}

export function moveElement(array, index, offset) {
  const newIndex = index + offset;

  return move([...array], index, newIndex);
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

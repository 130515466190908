import React, { useCallback, useEffect, useState } from "react";
import Spinner from "react-spinkit";
import LoginWrapper from "./LoginWrapper";
import * as routes from "../constants/routes";
import { confirmPasswordReset, verifyPasswordResetCode } from "../firebase/auth";

import lockIcon from "../assets/img/icons/icon-lock.svg";

const DEFAULT_STATE = {
  email: null,
  error: "",
  password: "",
  success: false,
  validCode: null,
  verifiedCode: false,
};

const ResetPassword = ({ actionCode }) => {
  const [state, setState] = useState(DEFAULT_STATE);

  const verifyCode = useCallback(async () => {
    try {
      const email = await verifyPasswordResetCode(actionCode);

      return setState(prevState => ({ ...prevState, email, validCode: true, verifiedCode: true }));
    } catch (err) {
      return setState(prevState => ({ ...prevState, error: err.message, validCode: false, verifiedCode: true }));
    }
  }, [actionCode]);

  useEffect(() => {
    verifyCode();
  }, [verifyCode]);

  const handleResetPassword = async event => {
    event.preventDefault();
    const newPassword = state.password;

    try {
      await confirmPasswordReset(actionCode, newPassword);
      setState({ ...state, success: true });
    } catch (err) {
      setState({ ...state, error: err.message });
    }
  };

  const setText = evt => {
    setState({ ...state, password: evt.currentTarget.value });
  };

  const { email, error, success, validCode, verifiedCode } = state;

  let component;
  if (!verifiedCode) {
    component = <Spinner name="three-bounce" />;
  } else if (success) {
    component = (
      <LoginWrapper page="resetPassword" title="Password has been changed">
        <p className="login-form-text">You can now sign in with your new password</p>
        <a className="custom_button" href={routes.SIGN_IN}>
          Sign in
        </a>
      </LoginWrapper>
    );
  } else if (verifiedCode && validCode) {
    component = (
      <LoginWrapper error={error} title="Reset your password" page="resetPassword">
        <div className="ResetPassword">
          <p className="login-form-text">
            Reset password for <span>{email}</span>
          </p>

          <form className="login-form" onSubmit={handleResetPassword}>
            <div className="input-wrapper">
              <img className="input-icon" src={lockIcon} alt="" />
              <input onChange={setText} value={state.password} type="password" placeholder="New password" required />
            </div>

            <button className="custom_button" type="submit">
              Save
            </button>
          </form>
        </div>
      </LoginWrapper>
    );
  } else if (verifiedCode && !validCode) {
    component = (
      <LoginWrapper page="resetPassword" title="Try resetting your password again" titleBreak>
        <p className="login-form-text">{error}</p>
        <a className="custom_button" href={routes.SIGN_IN}>
          Sign in
        </a>
      </LoginWrapper>
    );
  }

  return component;
};

export default ResetPassword;

import React from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";
import CreditCard from "./CreditCard";
import { formatPrice } from "../utils";

const PurchasePlan = ({ id }) => {
  let discond;
  let overPrice;

  const subscription = useSelector(state => state.recurly.subscriptions.find(item => item.id === id));
  const monthly = subscription.interval === 1;
  const savedPrice = subscription.currencies[0].unitAmount;
  const { currency } = subscription.currencies[0];
  const opositeSubscription = useSelector(state =>
    state.recurly.subscriptions.find(item => item.type === subscription.type && item.interval !== subscription.interval)
  );

  if (!monthly) {
    overPrice = opositeSubscription.currencies[0].unitAmount * subscription.interval;
    discond = Math.floor(100 - (savedPrice / overPrice) * 100);
  }

  const formatedSavedPrice = formatPrice(savedPrice, currency);
  const formatedOverPrice = formatPrice(overPrice, currency);

  return (
    <div className="modal-purchase-plan">
      <div className="modal-purchase-plan__header">
        <p>
          Selected <b>{subscription.type}</b>
          {` subscription type ${formatedSavedPrice}/${monthly ? "month" : "year"}`}
        </p>
        <div className="billed-annually-container">
          <div className="billed-annually-text">
            <p>{`Billed ${monthly ? "monthly" : "annually"}`}&nbsp;</p>
            {discond ? <p className="is-sale">Save {discond}%</p> : null}
          </div>
          <div className="billed-annually-values">
            {savedPrice ? <p className="is-new-price">{formatedSavedPrice}</p> : null}
            {overPrice ? <p className="is-old-price">{formatedOverPrice}</p> : null}
          </div>
        </div>
      </div>
      <div className="modal-purchase-plan__body">
        <div className="modal-purchase-plan__body__content">
          <p className="modal-purchase-plan__body__title">Enter your credit card</p>
          <p className="modal-purchase-plan__body__subtitle">You will get further instructions on your email</p>
          <CreditCard id={id} currency={currency} price={formatedSavedPrice} />
        </div>

        <p className="modal-purchase-plan__body__payment__policy"> Payment Policy & Declaration</p>
      </div>
    </div>
  );
};

PurchasePlan.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PurchasePlan;

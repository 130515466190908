import React from "react";
import AccountSettingsWrapper from "../../components/AccountSettings/AccountSettingsWrapper";
import PasswordUpdateForm from "../../components/PasswordUpdateForm";

const AccountMobileChangePassword = () => {
  return (
    <AccountSettingsWrapper title="Change password">
      <PasswordUpdateForm />
    </AccountSettingsWrapper>
  );
};

export default AccountMobileChangePassword;

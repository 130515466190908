import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import dataReducer from "./reducers/dataReducer";
import recurlyReducer from "./reducers/recurlyReducer";

const initialState = {};
const middleware = [thunk];
const reducers = combineReducers({
  data: dataReducer,
  recurly: recurlyReducer,
});

const store = createStore(reducers, initialState, compose(applyMiddleware(...middleware)));

export default store;

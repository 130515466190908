import React, { useState, useEffect, useRef, useCallback } from "react";
import * as PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { Range } from "react-range";
import qs from "qs";

import iconClose from "../assets/img/icons/icon-close-modal.svg";

const RangeFilter = ({
  min,
  max,
  title,
  placeholder,
  handleClear,
  handleChange,
  isVisibleOverride,
  currentValues,
  setCurrentValues,
}) => {
  const [isVisibleValue, setIsVisible] = useState(false);
  const isVisible = isVisibleValue || isVisibleOverride;

  const ref = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const handleUpdate = (minValue, maxValue) => {
    handleChange({ min: minValue, max: maxValue });
    setCurrentValues({ min: minValue, max: maxValue });
  };

  const handleReset = event => {
    event.stopPropagation();
    handleClear();
    setCurrentValues({ min, max });
    setIsVisible(false);
  };

  const handleRangeChange = props => {
    const [minValue, maxValue] = props;
    handleUpdate(minValue, maxValue);
  };

  return (
    <div className="range-filter range-filter__square">
      <div className={`range-value ${isVisible ? "is-visible" : ""}`} onClick={() => setIsVisible(!isVisible)}>
        <div className="title">{title}</div>
        {currentValues.min !== min || currentValues.max !== max ? (
          <div className="range-value-wrapper">
            <div className="placeholder">{`${currentValues.min} - ${currentValues.max}`}</div>

            <button className="clear-button" type="button" onClick={handleReset}>
              <img src={iconClose} alt="" />
            </button>
          </div>
        ) : (
          <div className="placeholder">{placeholder}</div>
        )}
      </div>

      {isVisible && (
        <div className="count-popup range-popup" ref={ref}>
          <div className="container">
            <Range
              step={1}
              min={min}
              max={max}
              values={[currentValues.min, currentValues.max]}
              onChange={handleRangeChange}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    // eslint-disable-next-line react/prop-types
                    ...props.style,
                    height: "6px",
                    width: "100%",
                    backgroundColor: "rgba(44, 141, 172,.2)",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    // eslint-disable-next-line react/prop-types
                    ...props.style,
                    height: "32px",
                    width: "32px",
                    backgroundColor: "#2c8dac",
                    borderRadius: "16px",
                  }}
                >
                  {/* eslint-disable-next-line react/prop-types */}
                  <span className="range-mobile-values">{props.key ? currentValues.max : currentValues.min}</span>
                </div>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

RangeFilter.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,

  handleClear: PropTypes.func,
  handleChange: PropTypes.func,
  isVisibleOverride: PropTypes.bool,

  currentValues: PropTypes.shape({ min: PropTypes.number.isRequired, max: PropTypes.number.isRequired }).isRequired,
  setCurrentValues: PropTypes.func.isRequired,
};

RangeFilter.defaultProps = {
  handleClear: () => {},
  handleChange: () => {},

  isVisibleOverride: false,
};

export const useRangeFilter = (defaultMinValue, defaultMaxValue, name) => {
  const [currentValues, setCurrentValues] = useState({ min: defaultMinValue, max: defaultMaxValue });

  const location = useLocation();

  useEffect(() => {
    const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (searchParams?.[`filter_${name}`]) {
      setCurrentValues({
        min: Number(searchParams?.[`filter_${name}`]?.min),
        max: Number(searchParams?.[`filter_${name}`]?.max),
      });
    }
  }, [location.search, name]);

  const clear = useCallback(() => {
    setCurrentValues({ min: defaultMinValue, max: defaultMaxValue });
  }, [defaultMaxValue, defaultMinValue]);

  return { currentValues, setCurrentValues, clear };
};

export default RangeFilter;

import React, { useEffect, useState } from "react";
import { auth } from "../firebase/firebase";
import { doSignOut } from "../firebase/auth";
import useSubscriptionTrack from "../hooks/useSubscriptionTrack";
import * as db from "../firebase/db";

export const AuthUserContext = React.createContext();

const DEFAULT_USER = { isAuthChecked: false, data: null };

const withAuthentication = Component => {
  return props => {
    const [authUser, setAuthUser] = useState(DEFAULT_USER);
    const { isAuthChecked, data } = authUser;

    useSubscriptionTrack();

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        let sessionTimeout = null;
        if (user === null) {
          if (sessionTimeout) {
            clearTimeout(sessionTimeout);
          }
          sessionTimeout = null;
          doSignOut();
        } else {
          // User is logged in.
          // Fetch the decoded ID token and create a session timeout which signs the user out.
          user.getIdTokenResult().then(async idTokenResult => {
            const currentUser = await db.doGetUserValue(user.uid);
            const authTime = idTokenResult.claims.auth_time * 1000;
            const shortTimeInterval = 1000 * 60 * 60 * 24 * 7; // one week
            const longTimeInterval = 1000 * 60 * 60 * 24 * 24; // 24 days
            const sessionDuration = currentUser.val()?.rememberMe ? longTimeInterval : shortTimeInterval;
            const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
            sessionTimeout = setTimeout(() => {
              doSignOut();
            }, millisecondsUntilExpiration);
          });
        }
        setAuthUser({ isAuthChecked: true, data: user });
      });

      return () => unsubscribe();
    }, []);

    return (
      <AuthUserContext.Provider value={{ isAuthChecked, authUser: data }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  };
};

export default withAuthentication;

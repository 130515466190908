import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AccountSettingsWrapper from "../../components/AccountSettings/AccountSettingsWrapper";
import ModalCancelSubscription from "../../components/modals/ModalCancelSubscription";
import ModalPlanUpdated from "../../components/modals/ModalPlanUpdated";
import ModalPurchasePlan from "../../components/modals/ModalPurchasePlan";
import SubscriptionPlan from "../../components/SubscriptionPlan";
import { loadSubscriptions, resetUserPlanFields } from "../../redux/actions/recurlyActions";

const SubscriptionMobileLevel = () => {
  const dispatch = useDispatch();
  const [visiblePlans, setVisiblePlans] = useState(true);
  const [selectedSub, setSelectedSub] = useState(null);
  const updatingPlanSuccess = useSelector(state => state.recurly.updatingPlanSuccess);

  useEffect(() => {
    loadSubscriptions(dispatch);
  }, [dispatch]);

  const togglePurchaseModal = () => {
    setSelectedSub(null);
    setVisiblePlans(true);
  };

  const setPlanId = id => {
    setSelectedSub(id);
    setVisiblePlans(false);
  };

  const onCloseSuccessModal = () => {
    setSelectedSub(null);
    setVisiblePlans(true);
    resetUserPlanFields(dispatch);
  };

  const onToggleSubscriptionPlans = () => {
    setVisiblePlans(value => !value);
  };

  return (
    <AccountSettingsWrapper title="Subscription Level">
      {visiblePlans && <SubscriptionPlan setPlanId={setPlanId} onToggle={onToggleSubscriptionPlans} />}
      {selectedSub && selectedSub !== "01" && selectedSub !== "02" && updatingPlanSuccess !== true && (
        <ModalPurchasePlan id={selectedSub} onToggle={togglePurchaseModal} />
      )}
      {(selectedSub === "01" || selectedSub === "02") && <ModalCancelSubscription onToggle={onCloseSuccessModal} />}
      {updatingPlanSuccess === true && <ModalPlanUpdated onToggle={onCloseSuccessModal} />}
    </AccountSettingsWrapper>
  );
};

export default SubscriptionMobileLevel;

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as PropTypes from "prop-types";
import { useDrag, useDrop } from "react-dnd";
import deleteIcon from "../assets/img/icons/icon-close-small.svg";
import preloaderIcon from "../assets/img/preloader.gif";
import preloaderSpinner from "../assets/img/loading_spinner.gif";

import iconAlert from "../assets/img/icons/icon-alert-red.svg";
import reloadIcon from "../assets/img/icons/icon-reload.svg";
import { callErrorToast } from "../utils/imageUploader";

const TOUR_IMAGE_TYPE = "TOUR_IMAGE_TYPE";

const ERROR_CHANGE_IMAGE_ORDER = "You cannot change the image order until upload is complete.";
const ERROR_CHANGE_IMAGE_NAME = "You cannot change the image name until upload is complete.";
const ERROR_DELETE_IMAGE = "You cannot delete the image until upload is complete.";

const TourImageCard = ({
  id,
  thumbnail,
  handleDeleteImage,
  hasError,
  name,
  handleChangeName,
  changeImageOrder,
  reUploadImage,
  progress,
  canEditImage,
}) => {
  const [editedValue, setEditedValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const inputRef = useRef(null);

  const useOutsideAlerter = ref => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (isEdit) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsEdit(false);
            handleChangeName(editedValue);
          }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, editedValue]);
  };
  useOutsideAlerter(inputRef);

  const handleEditName = ({ target: { value } }) => {
    if (canEditImage) {
      setEditedValue(value);
    } else {
      callErrorToast(ERROR_CHANGE_IMAGE_NAME);
    }
  };

  const handleDeleteClick = () => {
    if (canEditImage) {
      if (isDeleting) {
        return;
      }

      handleDeleteImage();
      setIsDeleting(true);
    } else {
      callErrorToast(ERROR_DELETE_IMAGE);
    }
  };

  const handleNameClick = value => {
    setEditedValue(value);
    setIsEdit(true);
  };

  const onReUploadImage = useCallback(() => {
    reUploadImage(id);
  }, [id, reUploadImage]);

  const dragConfig = useMemo(
    () => ({
      item: { type: TOUR_IMAGE_TYPE, id },
      canDrag: true,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id]
  );

  const dndRef = useRef(null);
  const [{ isDragging }, connectDrag] = useDrag(dragConfig);
  const [, connectDrop] = useDrop({
    accept: TOUR_IMAGE_TYPE,
    drop({ id: draggedId }) {
      if (canEditImage) {
        if (draggedId !== id) {
          changeImageOrder(draggedId, id);
        }
      } else {
        callErrorToast(ERROR_CHANGE_IMAGE_ORDER);
      }
    },
  });

  connectDrag(dndRef);
  connectDrop(dndRef);

  const isProcessing = typeof progress === "number";

  const renderImageMask = () => {
    if (isProcessing) {
      return (
        <div className="image-progress-container">
          <div className="tour-image-progress-value">{`${progress}%`}</div>
        </div>
      );
    }
    if (hasError) {
      return (
        <div className="image-retry-container">
          <div className="warning-row">
            <img className="warning-icon" src={iconAlert} alt="" />
            <span>Photo upload failed</span>
          </div>
          <button type="button" onClick={onReUploadImage}>
            <img className="retry-icon" src={reloadIcon} alt="" />
            <span>Reupload</span>
          </button>
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ opacity: isDragging ? 0.3 : 1 }} ref={dndRef} className="tour-photo">
      {thumbnail ? (
        <div className="image-container">
          {renderImageMask()}
          {canEditImage ? (
            <button type="button" className="delete" onClick={handleDeleteClick}>
              <img src={isDeleting ? preloaderIcon : deleteIcon} alt="" />
            </button>
          ) : null}
          <img className={isProcessing ? "image-grayscale" : ""} src={thumbnail} alt="" />
        </div>
      ) : (
        <div className="image-container__empty">
          <img src={preloaderSpinner} alt="" />
        </div>
      )}

      {isEdit ? (
        <input
          className="tour-image-title__input"
          type="text"
          ref={inputRef}
          onChange={handleEditName}
          value={editedValue}
        />
      ) : (
        <div className="tour-image-title" onClick={() => handleNameClick(name)}>
          {name}
        </div>
      )}
    </div>
  );
};

TourImageCard.defaultProps = {
  thumbnail: null,
  name: null,
  progress: null,
  hasError: false,
};

TourImageCard.propTypes = {
  thumbnail: PropTypes.string,
  name: PropTypes.string,
  progress: PropTypes.number,
  hasError: PropTypes.bool,
  canEditImage: PropTypes.bool.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  changeImageOrder: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  reUploadImage: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default TourImageCard;

import React, { useEffect, useState } from "react";
import FileUploader from "react-firebase-file-uploader";
import * as PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import iconImage from "../assets/img/icons/icon-image.svg";
import { loadCurrentSubscriptions } from "../redux/actions/recurlyActions";
import useSubscriptionType from "../hooks/useSubscriptionType";
import { UNLIMITED_SUBSCRIPTION_PLAN } from "../constants/subscriptions";

const DEFAULT_STATE = {
  avatar: "",
  isUploading: false,
  progress: 0,
  avatarURL: "",
};

const UploadProfilePhoto = ({ getStorageRef, getAvatarURL, handleSuccessUpload }) => {
  const dispatch = useDispatch();
  const { type } = useSubscriptionType();
  const [state, setState] = useState(DEFAULT_STATE);

  const canUpdatePhoto = type === UNLIMITED_SUBSCRIPTION_PLAN.type;

  useEffect(() => {
    loadCurrentSubscriptions(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const setAvatar = async () => {
      const avatarURL = await getAvatarURL();
      if (avatarURL) {
        setState(prevAccountState => ({ ...prevAccountState, avatarURL }));
      }
    };
    setAvatar();
  }, [getAvatarURL]);

  const handleUploadStart = () => setState(prevState => ({ ...prevState, isUploading: true, progress: 0 }));
  const handleProgress = progress => setState(prevState => ({ ...prevState, progress }));
  const handleUploadError = () => {
    setState(prevState => ({ ...prevState, isUploading: false }));
  };
  const handleUploadSuccess = async filename => {
    setState(prevState => ({ ...prevState, avatar: filename, progress: 100, isUploading: false }));
    const downloadUrl = await getStorageRef().child(filename).getDownloadURL();
    await handleSuccessUpload(downloadUrl);
    setState(prevState => ({ ...prevState, avatarURL: downloadUrl }));
  };

  const onRemoveCompanyLogo = async () => {
    await handleSuccessUpload(null);
    setState(prevState => ({ ...prevState, avatarURL: "" }));
  };

  return canUpdatePhoto || state.avatarURL?.length ? (
    <div className="upload-company-logo">
      <div className="ucl__title">Company Logo</div>
      <div className="ucl__subtitle">
        Watermark your tours with <br /> personal logo
      </div>

      {state.avatarURL ? (
        <img className="dropzone_thumb__img" src={state.avatarURL} alt="" />
      ) : (
        <div className="dropzone">
          <div />
          <p className="dropzone__title">Upload</p>
          <img src={iconImage} className="dropzone__icon" alt="" />
          <p className="dropzone__subtitle">
            your logo image <br /> png / jpeg
          </p>
        </div>
      )}

      {canUpdatePhoto ? (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className="custom_button__light margin-button free-width-button">
          <span>Upload image</span>
          <FileUploader
            accept="image/*"
            name="avatar"
            randomizeFilename
            storageRef={getStorageRef()}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={handleUploadSuccess}
            onProgress={handleProgress}
            hidden
          />
        </label>
      ) : null}

      {state.avatarURL?.length ? (
        <button
          type="button"
          onClick={onRemoveCompanyLogo}
          className="custom_button__light free-width-button custom_button__light--outlined"
        >
          Delete image
        </button>
      ) : null}
    </div>
  ) : null;
};

UploadProfilePhoto.propTypes = {
  getStorageRef: PropTypes.func.isRequired,
  getAvatarURL: PropTypes.func.isRequired,
  handleSuccessUpload: PropTypes.func.isRequired,
};

export default UploadProfilePhoto;

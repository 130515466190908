import React, { useState, useEffect, useCallback } from "react";
import * as PropTypes from "prop-types";

import iconLoop from "../assets/img/icons/icon-loop.svg";
import iconClose from "../assets/img/icons/icon-close-modal.svg";

const SearchBar = ({ handleChangeSearchOptions, handleTouch, handleClearInput, inputValue, onEnterPress }) => {
  const [value, setValue] = useState(inputValue || "");

  useEffect(() => {
    setValue(inputValue || "");
  }, [inputValue]);

  const handleChange = useCallback(
    event => {
      setValue(event.target.value);
      handleChangeSearchOptions(event);
    },
    [handleChangeSearchOptions]
  );

  const handleClear = useCallback(() => {
    setValue("");
    handleClearInput();
  }, [handleClearInput]);

  const handleEnterPress = event => {
    if (event.key === "Enter") {
      onEnterPress();
    }
  };

  return (
    <div className="search-field" onClick={handleTouch}>
      <img src={iconLoop} alt="" />
      <input
        placeholder="Find a tour by location"
        type="search"
        onChange={handleChange}
        value={value}
        onKeyPress={handleEnterPress}
      />

      {value.length > 0 && (
        <button onClick={handleClear} className="btn-search-clear" type="button">
          <img src={iconClose} alt="" />
        </button>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  handleClearInput: PropTypes.func,
  handleChangeSearchOptions: PropTypes.func,
  handleTouch: PropTypes.func,
  inputValue: PropTypes.string,
  onEnterPress: PropTypes.func,
};

SearchBar.defaultProps = {
  handleClearInput: undefined,
  handleChangeSearchOptions: undefined,
  handleTouch: undefined,
  inputValue: "",
  onEnterPress: undefined,
};

export default SearchBar;

import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactGA from "react-ga";

import store from "./redux/store";
import Router from "./router";

import "./styles/index.scss";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
  useEffect(() => {
    ReactGA.initialize("UA-178898896-1", {
      siteSpeedSampleRate: 100,
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </DndProvider>
      </Provider>
    </>
  );
}

export default App;

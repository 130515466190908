import React from "react";
import * as PropTypes from "prop-types";

import NavBar from "./NavBar";

const HeaderMain = ({ homepage }) => {
  return (
    <header className={`header-main ${homepage ? "header-homepage" : ""}`}>
      <NavBar homepage={homepage} />
    </header>
  );
};

HeaderMain.propTypes = {
  homepage: PropTypes.bool,
};

HeaderMain.defaultProps = {
  homepage: undefined,
};

export default HeaderMain;

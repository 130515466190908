import { createButton } from "react-social-login-buttons";
import { ReactComponent as AppleSvgIcon } from "../assets/img/apple.svg";

const config = {
  text: "Continue with Apple",
  icon: AppleSvgIcon,
  className: "login-social-button login-social-apple",
};

const AppleLoginButton = createButton(config);

export default AppleLoginButton;

import React from "react";
import * as PropTypes from "prop-types";
import EnterpriseBanner from "./EnterpriseBanner";

const Pricing = ({ children }) => (
  <div id="pricing" className="is-pricing">
    <div className="is-container">
      <div className="heading-border">
        <h2>Subscription Pricing</h2>

        <p>Get More for Your Money with Our Pricing</p>
      </div>

      {children}

      <EnterpriseBanner />
    </div>
  </div>
);

Pricing.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Pricing;

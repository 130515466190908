import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import { doSignInWithEmailAndPassword, getCurrentUser } from "../firebase/auth";
import * as routes from "../constants/routes";

import emailIcon from "../assets/img/icons/icon-email.svg";
import lockIcon from "../assets/img/icons/icon-lock.svg";

const DEFAULT_EMAIL_UPDATE_STATE = {
  email: "",
  currentPassword: "",
  isLoading: false,
  isFocused: false,
};

const EmailUpdateForm = ({ setError }) => {
  const emailRef = useRef(null);
  const history = useHistory();

  const [emailUpdateState, setEmailUpdateState] = useState(DEFAULT_EMAIL_UPDATE_STATE);

  useEffect(() => {
    if (!emailUpdateState.isFocused) {
      emailRef.current.focus();
    }
  }, [emailUpdateState.isFocused]);

  const handleFieldChange = e => {
    const { name, value } = e.currentTarget;
    setEmailUpdateState({ ...emailUpdateState, [name]: value });
  };

  const isInvalid = emailUpdateState.email === "" || emailUpdateState.currentPassword === "";

  const handleSubmit = async event => {
    const { currentPassword, email } = emailUpdateState;
    event.preventDefault();
    try {
      const currentUser = getCurrentUser();
      const userCredential = await doSignInWithEmailAndPassword(currentUser.email, currentPassword);
      await userCredential.user.updateEmail(email);
    } catch (err) {
      return setError(err?.message);
    }

    return history.push(routes.ACCOUNT);
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="input-wrapper">
        <img className="input-icon" src={emailIcon} alt="" />
        <input
          name="email"
          type="text"
          ref={emailRef}
          placeholder="New email"
          value={emailUpdateState.email}
          onChange={handleFieldChange}
        />
      </div>
      <div className="input-wrapper">
        <img className="input-icon" src={lockIcon} alt="" />
        <input
          name="currentPassword"
          type="text"
          placeholder="Current password"
          value={emailUpdateState.currentPassword}
          onChange={handleFieldChange}
        />
      </div>
      <button className="custom_button" type="submit" disabled={emailUpdateState.isLoading || isInvalid}>
        {emailUpdateState.isLoading ? "Updating....." : "Update Email"}
      </button>
    </form>
  );
};

EmailUpdateForm.propTypes = {
  setError: PropTypes.func.isRequired,
};

export default EmailUpdateForm;

import React from "react";
import AccountSettingsWrapper from "../../components/AccountSettings/AccountSettingsWrapper";
import AgentView from "../../components/AgentView";

const AgentMobileInfo = () => {
  return (
    <AccountSettingsWrapper title="Agent Information">
      <AgentView />
    </AccountSettingsWrapper>
  );
};

export default AgentMobileInfo;

import React from "react";
import AccountSettingsWrapper from "../../components/AccountSettings/AccountSettingsWrapper";
import InvoicesList from "../../components/InvoicesList";

const SubscriptionMobileInvoices = () => {
  return (
    <AccountSettingsWrapper title="Past Invoices">
      <InvoicesList />
    </AccountSettingsWrapper>
  );
};

export default SubscriptionMobileInvoices;

import React, { useState } from "react";
import { GoogleLoginButton } from "react-social-login-buttons";

import SignUpForm from "../components/SignUpForm";
import SocialLogin from "../components/SocialLogin";
import AppleLoginButton from "../components/AppleLoginButton";
import LoginWrapper from "../components/LoginWrapper";

const SignUp = () => {
  const [error, setError] = useState("");

  return (
    <LoginWrapper title="Sign Up" error={error}>
      <SignUpForm setError={setError} />

      <div className="sign-in-with">
        <p>
          <span>or log in with</span>
        </p>
      </div>

      <SocialLogin
        AppleButton={() => <AppleLoginButton text="Sign up with Apple" />}
        GoogleButton={() => <GoogleLoginButton text="Sign up with Google" />}
        setError={setError}
        // rememberMe={}
      />
    </LoginWrapper>
  );
};

export default SignUp;

import React, { useState } from "react";
import * as PropTypes from "prop-types";

import iconAlert from "../assets/img/icons/icon-alert.svg";
import iconClose from "../assets/img/icons/icon-close-red.svg";

const Alert = ({ children }) => {
  const [closeAlert, setCloseAlert] = useState(false);

  return (
    <>
      {closeAlert ? undefined : (
        <div className="is-alert">
          <div className="is-alert__content">
            <img src={iconAlert} alt="" className="is-alert__icon" />
            <div className="is-alert__text">{children}</div>
          </div>
          <button className="is-alert__close" type="button" onClick={() => setCloseAlert(true)}>
            <img src={iconClose} alt="" />
          </button>
        </div>
      )}
    </>
  );
};

Alert.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Alert;

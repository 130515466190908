import React, { useState, useCallback, useEffect } from "react";
import AccountSettingsWrapper from "../../components/AccountSettings/AccountSettingsWrapper";
import AccountPhoto from "../../components/AccountSettings/AccountPhoto";
import AccountInfoForm from "../../components/AccountInfoForm";

import { getCurrentUser } from "../../firebase/auth";
import { doGetToursByUser } from "../../firebase/db";

const AccountMobileInfo = () => {
  const [error, setError] = useState("");
  const [userName, setUserName] = useState("");
  const [toursCount, setToursCount] = useState({ tours: 0, archivedTours: 0 });

  const authUser = getCurrentUser();

  const getToursByUserId = useCallback(async () => {
    let result;
    try {
      result = await doGetToursByUser(authUser.uid, {});
    } catch (err) {
      setError(err.message);
    }
    const tours = [];
    const archivedTours = [];
    Object.values(result.val() || {}).forEach(item => {
      tours.push(item);
      if (item.archived) {
        archivedTours.push(item);
      }
    });
    setToursCount({ tours: tours.length, archivedTours: archivedTours.length });
    setUserName(authUser.displayName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getToursByUserId();
  }, [getToursByUserId]);

  const handleConfirmUserName = async () => {
    await authUser.reload();
    const reloadedUserName = authUser.displayName;
    setUserName(reloadedUserName);
  };

  return (
    <AccountSettingsWrapper title="Account Information">
      <AccountPhoto userName={userName} toursCount={toursCount} />
      <AccountInfoForm handleConfirmUserName={handleConfirmUserName} />
      {error && <p className="error">{error}</p>}
    </AccountSettingsWrapper>
  );
};

export default AccountMobileInfo;

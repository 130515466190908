import React, { useState, useEffect } from "react";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import * as routes from "../constants/routes";
import {
  doUpdateDisplayName,
  getCurrentUser,
  doSignInWithEmailAndPassword,
  doSendEmailVerification,
} from "../firebase/auth";

import userIcon from "../assets/img/icons/icon-user.svg";

const DEFAULT_CURRENT_USER = { displayName: "", email: "", currentPassword: "" };

const AccountInfoForm = ({ handleConfirmUserName }) => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(DEFAULT_CURRENT_USER);

  const user = getCurrentUser();
  const [error, setError] = useState("");

  useEffect(() => {
    const { displayName, email } = user;
    setCurrentUser({ displayName, email });
    setCurrentUser(prevAccountState => ({ ...prevAccountState, displayName }));
  }, [user]);

  const handleConfirmDisplayName = async () => {
    try {
      await doUpdateDisplayName(currentUser.displayName);
    } catch (err) {
      return setCurrentUser({ ...currentUser, displayNameError: err?.message });
    }
    setCurrentUser({ ...currentUser, displayName: currentUser.displayName });
    handleConfirmUserName();

    return setCurrentUser({ ...currentUser, isEditDisplayName: false, displayNameError: "" });
  };

  const handleNameChange = async e => {
    const { value } = e.currentTarget;
    setCurrentUser({ ...currentUser, displayName: value });
  };

  const handleEmailChange = e => {
    const { value } = e.currentTarget;
    setCurrentUser({ ...currentUser, email: value });
  };

  const handleConfirmEmail = async () => {
    const { currentPassword, email } = currentUser;

    try {
      const userCredential = await doSignInWithEmailAndPassword(user.email, currentPassword);
      await userCredential.user.updateEmail(email);
      await doSendEmailVerification();
    } catch (err) {
      return setError(err?.message);
    }

    return history.push(routes.EMAIL_VERIFICATION);
  };

  const handlePasswordChange = e => {
    const { value } = e.currentTarget;
    setCurrentUser({ ...currentUser, currentPassword: value });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (currentUser.displayName !== user.displayName) {
      await handleConfirmDisplayName();
    }

    if (currentUser.email !== user.email) {
      await handleConfirmEmail();
    }
  };

  return (
    <div className="settings-form__wrapper">
      <div className="settings-form__title">
        <img src={userIcon} alt="" />
        Account Information
      </div>
      <form onSubmit={handleSubmit} className="settings-form">
        <div className="text-field">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="name">
            Full Name
            <span className="required">*</span>
          </label>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Enter your full name here"
            value={currentUser.displayName}
            onChange={handleNameChange}
          />
        </div>

        <div className="text-field">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="email">
            Email
            <span className="required">*</span>
          </label>

          <input
            name="email"
            type="text"
            placeholder="Enter your email here"
            value={currentUser.email}
            onChange={handleEmailChange}
          />
        </div>

        {currentUser.email !== user.email && (
          <div className="text-field">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="email">
              Confirm Password
              <span className="required">*</span>
            </label>

            <input
              name="currentPassword"
              type="password"
              placeholder="Enter your password here"
              value={currentUser.currentPassword}
              onChange={handlePasswordChange}
            />
          </div>
        )}
        <div className="settings-form__footer">
          <button
            className="custom_button__light"
            type="submit"
            disabled={currentUser.displayName === user.displayName && currentUser.email === user.email}
          >
            Save
          </button>
          {error && <p className="error">{error}</p>}
        </div>
      </form>
    </div>
  );
};

AccountInfoForm.propTypes = {
  handleConfirmUserName: PropTypes.func.isRequired,
};

export default AccountInfoForm;

import firebase from "firebase";
import { auth, appleAuthProvider, googleAuthProvider } from "./firebase";
import { doRemoveUser, doUpdateUser } from "./db";

export const getCurrentUser = () => auth.currentUser;
export const doCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

export const doReauthenticate = currentPassword => {
  const user = getCurrentUser();
  const cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);

  return user.reauthenticateWithCredential(cred);
};

export const doUpdatePassword = password => {
  const user = getCurrentUser();

  return user.updatePassword(password);
};

export const doSignInWithApple = () => auth.signInWithPopup(appleAuthProvider);
export const doSignInWithGoogle = () => auth.signInWithPopup(googleAuthProvider);
export const doSignInWithEmailAndPassword = (email, password) => auth.signInWithEmailAndPassword(email, password);
export const doSendPasswordResetEmail = email => auth.sendPasswordResetEmail(email);

export const doUpdateDisplayName = displayName => {
  const user = getCurrentUser();

  return Promise.all([user.updateProfile({ displayName }), doUpdateUser(user.uid, { username: displayName })]);
};

export const doSignOut = () => auth.signOut();

export const doDeleteUserAccount = async () => {
  const user = getCurrentUser();
  const { uid } = user;
  await user.delete();
  await doRemoveUser(uid);
};

export const doSendEmailVerification = () => {
  const user = getCurrentUser();
  user.sendEmailVerification({ url: window.location.href });
};

export const checkActionCode = actionCode => {
  return auth.checkActionCode(actionCode);
};

export const applyActionCode = actionCode => {
  return auth.applyActionCode(actionCode);
};

export const sendPasswordResetEmail = email => {
  return auth.sendPasswordResetEmail(email);
};

export const verifyPasswordResetCode = actionCode => {
  return auth.verifyPasswordResetCode(actionCode);
};

export const confirmPasswordReset = (actionCode, password) => {
  return auth.confirmPasswordReset(actionCode, password);
};

export const doSocialReauthenticate = async provider => {
  const user = getCurrentUser();

  const result = user.reauthenticateWithPopup(provider);

  return result;
};

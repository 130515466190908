import React, { useState } from "react";
import * as PropTypes from "prop-types";
import checkMobile from "../../hooks/checkMobile";
import ModalCancelSubscription from "../modals/ModalCancelSubscription";

const SubscriptionCancel = ({ disabled }) => {
  const isMobile = checkMobile();
  const [isOpened, setInOpened] = useState(false);

  const onCancelSubscriptionClick = () => {
    setInOpened(true);
  };
  const onCloseCancelSubscription = () => {
    setInOpened(false);
  };

  return (
    <>
      <button disabled={disabled} onClick={onCancelSubscriptionClick} type="button" className="custom_button__light">
        {`Cancel ${isMobile ? "" : "Subscription"}`}
      </button>
      {isOpened && <ModalCancelSubscription onToggle={onCloseCancelSubscription} />}
    </>
  );
};

SubscriptionCancel.propTypes = {
  disabled: PropTypes.bool,
};

SubscriptionCancel.defaultProps = {
  disabled: false,
};

export default SubscriptionCancel;

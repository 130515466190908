import React from "react";
import * as PropTypes from "prop-types";

import Modal from "../Modal";

import SubscriptionPlan from "../SubscriptionPlan";

const ModalSubscription = ({ onToggle, onPress }) => {
  return (
    <Modal type="subscription" onToggle={onToggle} title="Choose a plan">
      <SubscriptionPlan setPlanId={onPress} />
    </Modal>
  );
};

ModalSubscription.propTypes = {
  onPress: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default ModalSubscription;

import React from "react";
import * as PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import Select from "react-select";

const SelectField = ({ label, required, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(props);

  const errorEmpty = "Please, select your country";

  function handleOptionChange(selection) {
    // eslint-disable-next-line react/prop-types
    setFieldValue(props.name, selection);
  }

  function updateBlur() {
    // eslint-disable-next-line react/prop-types
    setFieldTouched(props.name, true);
  }

  return (
    <div className="text-field">
      <label htmlFor={label}>
        {label}
        {required ? <span className="required">*</span> : null}{" "}
      </label>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div className="text-field__input">
        <Select
          {...field}
          {...props}
          onBlur={updateBlur}
          onChange={handleOptionChange}
          name={field.name}
          className="react-select-container"
          classNamePrefix="react-select"
        />
        {meta.touched && meta.error ? <div className="error">{errorEmpty}</div> : null}
      </div>
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};

SelectField.defaultProps = {
  label: "",
  required: false,
};

export default SelectField;

import React, { useEffect } from "react";
import * as PropTypes from "prop-types";

import iconClose from "../assets/img/icons/icon-close-modal.svg";

const Modal = ({ title, children, size, onToggle, type }) => {
  useEffect(() => {
    document.body.classList.add("modal-is-opened");

    return () => {
      document.body.classList.remove("modal-is-opened");
    };
  }, []);

  return (
    <div className={`modal-wrapper modal-window-${type}`}>
      <div className="modal-overlay" onClick={onToggle} />
      <div className={`modal-window modal-window-${size}`}>
        <div className="modal-header">
          <div className="modal-header__ghost" />
          <div className="modal-title">{title}</div>
          <button className="modal-close" type="button" onClick={onToggle}>
            <img src={iconClose} alt="" />
          </button>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onToggle: PropTypes.func,
};

Modal.defaultProps = {
  size: null,
  onToggle: null,
  type: null,
};

export default Modal;

import React, { useMemo } from "react";
import * as PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal";

import iconCancel from "../../assets/img/icons/icon-cancel-modal.svg";
import { cancelSubscription } from "../../redux/actions/recurlyActions";
import useSubscriptionType from "../../hooks/useSubscriptionType";

const ModalCancelSubscription = ({ onToggle }) => {
  const dispatch = useDispatch();
  const { id } = useSubscriptionType();

  const cancelSubscriptionIsLoaded = useSelector(state => state.recurly.cancelSubscriptionIsLoaded);

  const buttonText = useMemo(() => {
    if (cancelSubscriptionIsLoaded) {
      return "Processing.....";
    }

    return "Confirm";
  }, [cancelSubscriptionIsLoaded]);

  const onConfirm = () => {
    if (id) {
      cancelSubscription(dispatch, id, onToggle);
    }
  };

  return (
    <Modal onToggle={onToggle} title="Cancel Subscription" size="sm">
      <div className="modal-default">
        <img src={iconCancel} className="modal-default__icon" alt="" />

        <p>
          Are you sure you want to cancel
          <br />
          your subscription plan?
        </p>

        <button type="button" className="custom_button__light custom_button-sm-width" onClick={onConfirm}>
          {buttonText}
        </button>
      </div>
    </Modal>
  );
};

ModalCancelSubscription.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

export default ModalCancelSubscription;

import React from "react";
import * as PropTypes from "prop-types";

import iconConfirmPin from "../assets/img/icons/icon-confirm-pin.svg";

const ConfirmPinButton = ({ isVisible, onClick }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <button onClick={onClick} type="button" className="btn-default-pin btn-remove-pin">
        <img src={iconConfirmPin} alt="" />
        <span>Confirm</span>
      </button>
    </div>
  );
};

ConfirmPinButton.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ConfirmPinButton;

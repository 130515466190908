import { createButton } from "react-social-login-buttons";
import { ReactComponent as GoogleSvgIcon } from "../assets/img/icons/icon-google.svg";

const config = {
  text: "Continue with Google",
  icon: GoogleSvgIcon,
  className: "login-social-button login-social-google",
};

const GoogleLoginButton = createButton(config);

export default GoogleLoginButton;

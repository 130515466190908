import React from "react";
import * as PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import thumbnailDefaultImage from "../assets/img/placeholder.png";

function SliderVertical({ rooms, active, onPress, linked }) {
  return (
    <SimpleBar className="thumbs-vertical">
      {rooms.map(
        value =>
          value.id !== active && (
            <div
              key={value.id}
              onClick={() => onPress(value.id)}
              className={`thumb ${value.id === linked ? "is-active" : ""}`}
            >
              <p>{value.name}</p>
              <img src={value.thumbnail_url || thumbnailDefaultImage} draggable="false" alt={`${value.name}'s`} />
            </div>
          )
      )}
    </SimpleBar>
  );
}

SliderVertical.defaultProps = {
  active: "",
  linked: "",
};

SliderVertical.propTypes = {
  rooms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  active: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  linked: PropTypes.string,
};

export default SliderVertical;

import React from "react";
import AccountSettingsWrapper from "../../components/AccountSettings/AccountSettingsWrapper";
import SubscriptionCancel from "../../components/AccountSettings/SubscriptionCancel";
import { FREE_SUBSCRIPTION_PLAN } from "../../constants/subscriptions";
import useSubscriptionType from "../../hooks/useSubscriptionType";

const SubscriptionMobileCancel = () => {
  const { type, isCanceled } = useSubscriptionType();

  return (
    <AccountSettingsWrapper title="Cancel Subscription">
      <div className="acw-content">
        <p>Your subscription plan will be canceled immediately.</p>
        <div className="settings-subscription__row">
          <div />
          <SubscriptionCancel disabled={type === FREE_SUBSCRIPTION_PLAN.type || isCanceled} />
        </div>
      </div>
    </AccountSettingsWrapper>
  );
};

export default SubscriptionMobileCancel;

import { useState, useEffect } from "react";

const CheckMobile = (width = 1199) => {
  const isClient = typeof window === "object";

  const detectMobileWidth = () => (isClient ? window.innerWidth < width : undefined);

  const [isMobile, setWindowSize] = useState(detectMobileWidth);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = () => setWindowSize(detectMobileWidth());

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  return isMobile;
};

export default CheckMobile;

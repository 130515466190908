import React, { useState, useCallback } from "react";
import * as PropTypes from "prop-types";

import Modal from "../Modal";

import envelope from "../../assets/img/icons/icon-envelope-modal.svg";
import emailIcon from "../../assets/img/icons/icon-email.svg";

const ModalEnterpisePricing = ({ onToggle }) => {
  const [email, setEmail] = useState("");
  const handleEmailChange = useCallback(e => setEmail(e.target.value), []);

  const handleSubmit = e => {
    e.preventDefault();
    onToggle();
  };

  return (
    <Modal onToggle={onToggle} title="Request Enterprise Pricing" size="sm">
      <div className="modal-enterprise">
        <form action="" onSubmit={handleSubmit}>
          <img src={envelope} className="modal-enterprise__image" alt="" />

          <div className="input-wrapper">
            <img className="input-icon" src={emailIcon} alt="" />
            <input name="email" type="email" placeholder="Email" value={email} onChange={handleEmailChange} required />
          </div>
          <button type="submit" className="custom_button__light custom_button-sm-width">
            Request
          </button>
        </form>
      </div>
    </Modal>
  );
};

ModalEnterpisePricing.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

export default ModalEnterpisePricing;

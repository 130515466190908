import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";
import * as routes from "../constants/routes";
import { doSendPasswordResetEmail } from "../firebase/auth";

import lockIcon from "../assets/img/icons/icon-lock.svg";

const DEFAULT_RESET_STATE = {
  email: "",
  isLoading: false,
  isFocused: false,
};

const LoginForm = ({ setError }) => {
  const emailRef = useRef(null);

  const [resetState, setResetState] = useState(DEFAULT_RESET_STATE);

  const history = useHistory();

  useEffect(() => {
    if (resetState.isFocused) {
      emailRef.current.focus();
    }
  }, [resetState.isFocused]);

  const setSuccessReset = () => {
    setResetState({ ...resetState, isLoading: false });
  };

  const setErrorReset = error => {
    setResetState({ ...DEFAULT_RESET_STATE });
    setError(error);
  };

  const handleFieldChange = e => {
    const { name, value } = e.currentTarget;
    setResetState({ ...resetState, [name]: value });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await doSendPasswordResetEmail(resetState.email);
    } catch (err) {
      return setErrorReset(err?.message);
    }
    setSuccessReset();

    return history.push(routes.RESET_LINK_SENT);
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <p className="login-form-text">Enter your email address and we will send you a link to reset your password</p>

      <div className="input-wrapper">
        <img className="input-icon" src={lockIcon} alt="" />
        <input
          name="email"
          type="text"
          ref={emailRef}
          placeholder="Enter email"
          value={resetState.username}
          onChange={handleFieldChange}
        />
      </div>
      <button className="custom_button" type="submit" disabled={resetState.isLoading}>
        {resetState.isLoading ? "Resetting ....." : "Reset password"}
      </button>
    </form>
  );
};

LoginForm.propTypes = {
  setError: PropTypes.func.isRequired,
};

export default LoginForm;

import React, { useState } from "react";
import PasswordUpdateForm from "../components/PasswordUpdateForm";
import LoginWrapper from "../components/LoginWrapper";

const PasswordUpdate = () => {
  const [error, setError] = useState("");

  return (
    <LoginWrapper title="Restore Password" error={error} page="restorePassword">
      <PasswordUpdateForm setError={setError} />
    </LoginWrapper>
  );
};

export default PasswordUpdate;

import React from "react";
import * as PropTypes from "prop-types";

import iconRemovePin from "../assets/img/icons/icon-cancel-pin.svg";

const RemovePinButton = ({ isVisible, onClick }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <button onClick={onClick} type="button" className="btn-default-pin btn-remove-pin">
        <img src={iconRemovePin} alt="" />
        <span>Delete</span>
      </button>
    </div>
  );
};

RemovePinButton.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RemovePinButton;

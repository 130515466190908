import moment from "moment";
import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { RadialBarChart, RadialBar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import checkMobile from "../hooks/checkMobile";

import { doGetToursByID } from "../firebase/db";
import { getCurrentUser } from "../firebase/auth";

const EmptyComponent = () => {
  return (
    <div className="empty-analytics">
      <p>Sorry, this tour doesn&apos;t have any views so we cannot display analytics.</p>
    </div>
  );
};

const TourAnalytics = () => {
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { tourId } = useParams();
  const [error, setError] = useState("");
  const currentUser = getCurrentUser();

  const [views, setViews] = useState();
  const [totalViews, setTotalViews] = useState(0);
  const [uniqueViews, setUniqueViews] = useState(0);
  const isMobile = checkMobile(767);

  const [opacity, setOpacity] = useState({
    uv: 1,
    pv: 1,
  });

  const handleMouseEnter = o => {
    const { dataKey } = o;
    setOpacity({ ...opacity, [dataKey]: 0.5 });
  };

  const handleMouseLeave = o => {
    const { dataKey } = o;

    setOpacity({ ...opacity, [dataKey]: 1 });
  };

  useEffect(() => {
    const getUserTour = async () => {
      let fetchedTours;
      try {
        fetchedTours = await doGetToursByID(search.get("public") ? "public" : currentUser.uid, tourId);

        const tours = fetchedTours.val();

        if (tours.views) {
          setViews(tours.views);
          setTotalViews(tours.totalViews);
          setUniqueViews(Object.values(tours.views).map(value => value.fingerPrints).length);
        }
      } catch (err) {
        setError(err?.message);
      }
    };

    getUserTour();
  }, [currentUser.uid, search, tourId]);

  const radialData = [
    {
      name: "Unique",
      uv: uniqueViews,
      pv: 2400,
      fill: "#2c8dac",
    },

    {
      name: "Total",
      uv: totalViews,
      pv: 2300,
      fill: "#004f6b",
    },
  ];

  const lineData =
    views &&
    Object.keys(views).map(el => ({
      name: moment(Number(el)).format("D MMM"),
      total: views[el].dailyViews,
      unique: views[el].fingerPrints && views[el].fingerPrints.length,
    }));

  return (
    <>
      {error && <p className={error}>{error}</p>}
      {views && uniqueViews && totalViews ? (
        <div className="charts-wrapper">
          <div className="chart-rad">
            <div className="chart-rad-wrapper" style={{ width: isMobile ? "auto" : 360 }}>
              <RadialBarChart
                width={isMobile ? 300 : 360}
                height={300}
                cx={isMobile ? 150 : 180}
                cy={150}
                innerRadius={105}
                outerRadius={170}
                barSize={isMobile ? 15 : 20}
                data={radialData}
              >
                <RadialBar minAngle={15} background clockWise dataKey="uv" />
              </RadialBarChart>
              <div className="chart-rad-views">
                <span>
                  <span>{uniqueViews}</span>/{totalViews}
                </span>
                <br />
                unique views
              </div>
            </div>
            <div className="chart-rad-legend">
              <div className="chart-rad-legend__item">
                <span className="chart-rad-legend__icon unique-icon" />
                Unique views ({uniqueViews})
              </div>
              <div className="chart-rad-legend__item">
                <span className="chart-rad-legend__icon total-icon" />
                Total views ({totalViews})
              </div>
            </div>
          </div>
          <div className="chart-wrapper-graph">
            <LineChart width={isMobile ? 300 : 650} height={400} data={lineData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
              <Line
                type="monotone"
                dataKey="total"
                strokeOpacity={opacity.pv}
                strokeWidth={3}
                stroke="#004f6b"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="unique"
                strokeOpacity={opacity.uv}
                strokeWidth={3}
                stroke="rgb(44,141,172)"
              />
            </LineChart>
          </div>
        </div>
      ) : (
        <EmptyComponent />
      )}
    </>
  );
};

export default TourAnalytics;

import React, { useState } from "react";
import LoginWrapper from "../components/LoginWrapper";
import DeleteAccountForm from "../components/DeleteAccountForm";

const DeleteAccount = () => {
  const [error, setError] = useState("");

  return (
    <LoginWrapper page="resetPassword" error={error} titleBreak title="Account deactivation">
      <DeleteAccountForm setError={setError} />
    </LoginWrapper>
  );
};

export default DeleteAccount;

import React from "react";
import * as PropTypes from "prop-types";

import Modal from "../Modal";
import PaymentMethod from "../PaymentMethod";

const ModalPaymentMethod = ({ onToggle }) => {
  return (
    <Modal onToggle={onToggle} title="Enter your credit card">
      <PaymentMethod />
    </Modal>
  );
};

ModalPaymentMethod.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

export default ModalPaymentMethod;

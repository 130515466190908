import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Select from "react-select";
import * as PropTypes from "prop-types";
import { SORT_PARAMS } from "../constants/SortFilterParams";

const options = [
  { value: SORT_PARAMS.CUSTOM, label: "Custom" },
  { value: SORT_PARAMS.NEWEST, label: "The newest first" },
  { value: SORT_PARAMS.OLDEST, label: "The oldest first" },
  { value: SORT_PARAMS.MOST_VIEWED, label: "The most viewed" },
  { value: SORT_PARAMS.LEAST_VIEWED, label: "The least viewed" },
];

const SortToursSelect = ({ handleChangeSortOptions }) => {
  const location = useLocation();
  const history = useHistory();
  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const selectOptions = useMemo(
    () => (search.get("tab") === "community" ? options.filter(x => x.label !== "Custom") : options),
    [search]
  );
  const getValue = useCallback(key => selectOptions.find(({ value }) => value === key) || options[1], [selectOptions]);
  const [value, setValue] = useState(getValue(search.get("sort")));

  useEffect(() => {
    if (search.get("sort")) {
      setValue(getValue(search.get("sort")));
    }
  }, [getValue, search]);

  useEffect(() => {
    if (search.get("tab") === "community" && (search.get("sort") === "custom" || !search.get("sort"))) {
      setValue(options[1]);
      search.set("sort", options[1].value);
      history.push({ search: search.toString() });
    }
  }, [history, search]);

  return (
    <div className="select-field">
      <div className="select-label">Sort by:</div>
      <Select
        id={`sort-tours ${value.value}`}
        classNamePrefix="react-select"
        className="react-select-container"
        options={selectOptions}
        onChange={handleChangeSortOptions}
        value={value}
      />
    </div>
  );
};

SortToursSelect.propTypes = {
  handleChangeSortOptions: PropTypes.func.isRequired,
};

export default SortToursSelect;

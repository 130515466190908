import qs from "qs";

import React, { useRef, useEffect, useMemo, useCallback, useReducer } from "react";
import FileUploader from "react-firebase-file-uploader";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as PropTypes from "prop-types";

import * as routes from "../constants/routes";
import checkMobile from "../hooks/checkMobile";

import { storage } from "../firebase/firebase";
import { getCurrentUser } from "../firebase/auth";
import { doCreateImages, doGetToursByID, doUpdateImage } from "../firebase/db";

import TourImageCard from "./TourImageCard";

import iconPlus from "../assets/img/icons/icon-plus.svg";
import iconAlert from "../assets/img/icons/icon-alert-red.svg";

import preloader from "../assets/img/preloader.gif";
import { PUBLIC_TOUR } from "../constants/searchParam";
import { moveElement } from "../utils";
import {
  ACTION_TYPES,
  DEFAULT_STATE,
  reducer,
  sortImages,
  formatImages,
  handleFileChosen,
  callSuccessfulyToast,
  callErrorToast,
} from "../utils/imageUploader";

const ERROR_IMAGE_FORMAT = "File format is not supported.";
const ERROR_DUPLICATE_IMAGE = "Some images were ignored as they were already added to the stack.";

const TourImageUploader = ({ setSave }) => {
  const fileUploaderRef = useRef(null);

  const storageRef = storage().ref("tourImages");

  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

  const history = useHistory();
  const location = useLocation();
  const { tourId } = useParams();
  const isMobile = checkMobile();
  const authUser = getCurrentUser();
  const isEdit = location.pathname.includes("edit");

  const searchParams = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);
  const userIdOrPublic = searchParams[PUBLIC_TOUR] ? "public" : authUser.uid;
  const allImages = sortImages([...state.loadedImages, ...state.localImages, ...state.erroneousImages]);
  const canEditImage = allImages.every(item => typeof item.progress !== "number");
  const haveUnsavedChanges =
    !!state.removedImages.length || !!state.localImages.length || !!state.loadedImages.find(item => item.updated);
  const loading = state.loading || !!allImages.some(item => item.progress);

  const loadImages = useCallback(async () => {
    try {
      const fetchedTours = await doGetToursByID(userIdOrPublic, tourId);
      const tours = fetchedTours.val();
      const tourImages = Object.values(tours?.images || {});
      const preparedImages = formatImages(tourImages);

      return preparedImages;
    } catch (error) {
      // eslint-disable-next-line no-console

      // console.log("Error:[loadImages] ===>", error);

      return error;
    }
  }, [tourId, userIdOrPublic]);

  const setLoadedImages = useCallback(async () => {
    try {
      const payload = await loadImages();
      dispatch({ type: ACTION_TYPES.SET_LOADED_IMAGES, payload });
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log("Error:[loadImages] ===>", error);
    }
  }, [loadImages]);

  const formatFilename = (file, customName = "") => {
    const fileName = customName || file.name;

    return `${tourId}-${file.size}-${file.lastModified}-${fileName}`;
  };

  const customOnChangeHandler = async e => {
    try {
      dispatch({ type: ACTION_TYPES.LOADING, payload: true });

      e.preventDefault();
      const { files } = e.target;

      const results = await Promise.all(
        [...files]
          .filter(file => {
            const filename = formatFilename(file, file.name.split(".").slice(0, -1).join());
            const isExistFile = !!allImages.find(image => formatFilename(image) === filename);
            if (isExistFile) {
              callErrorToast(ERROR_DUPLICATE_IMAGE);
            }

            return !isExistFile;
          })
          .map(async (file, index) => {
            const maxIndex = Math.max(...allImages.map(image => image.index));
            // eslint-disable-next-line no-restricted-globals
            const preIndex = isFinite(maxIndex) ? maxIndex : 0;
            const fileContents = await handleFileChosen(file, index + 1 + preIndex);

            return fileContents;
          })
      );

      dispatch({ type: ACTION_TYPES.SET_LOCAL_IMAGES, payload: results });
    } catch (error) {
      callErrorToast(ERROR_IMAGE_FORMAT);
      dispatch({ type: ACTION_TYPES.LOADING, payload: false });
    }
  };

  const handleDeleteImage = index => {
    const image = allImages[index];
    if (image) {
      dispatch({ type: ACTION_TYPES.DROP_IMAGE, payload: image });
    }
  };

  const handleChangeName = (index, name) => {
    const image = allImages[index];

    if (image) {
      dispatch({ type: ACTION_TYPES.CHANGE_UPDATED_TO_TRUE, payload: { id: image.id, name } });
    }
  };

  const changeImageOrder = async (sourceId, destinationId) => {
    const images = {};
    const sourceIndex = allImages.findIndex(prev => prev.id === sourceId);
    const destinationIndex = allImages.findIndex(prev => prev.id === destinationId);
    if (sourceId === -1 || destinationId === -1) {
      return;
    }
    const offset = destinationIndex - sourceIndex;
    const newPreviews = moveElement(allImages, sourceIndex, offset).map((item, index) => ({ ...item, index }));
    newPreviews.forEach(item => {
      images[item.id] = item;
    });

    const sortStateArray = array => {
      return array.map(item => {
        const updatedImage = images[item.id];
        if (updatedImage) {
          return { ...item, ...updatedImage, updated: true };
        }

        return item;
      });
    };

    const localImages = sortStateArray(state.localImages);
    const loadedImages = sortStateArray(state.loadedImages);
    const erroneousImages = sortStateArray(state.erroneousImages);

    dispatch({ type: ACTION_TYPES.UPDATE_IMAGES, payload: { loadedImages, localImages, erroneousImages } });
  };

  const formatImageBeforeSaving = async (filename, image) => {
    const downloadUrl = await storageRef.child(filename).getDownloadURL();
    let thumbnailUrl = null;
    if (image.thumbnail) {
      await storageRef.child(`thumbnail_${filename}`).putString(image.thumbnail, "data_url");
      thumbnailUrl = await storageRef.child(`thumbnail_${filename}`).getDownloadURL();
    }

    return {
      img_url: downloadUrl,
      name: image.name,
      thumbnail_url: thumbnailUrl,
      created_at: Date.now(),
      index: image.index,
      size: image.file.size,
      lastModified: image.file.lastModified,
    };
  };

  const onUploadLocalImageSuccess = async (filename, image) => {
    try {
      const body = await formatImageBeforeSaving(filename, image);

      dispatch({
        type: ACTION_TYPES.MOVE_IMAGE_TO_PREPARED,
        payload: { localImageId: image.id, preparedImage: body },
      });
    } catch (e) {
      const copyLocalImages = Object.assign([], state.localImages, state.erroneousImages);
      const payload = copyLocalImages.find(({ file }) => formatFilename(file) === filename);
      if (payload) {
        dispatch({ type: ACTION_TYPES.SET_ERRONEOUS_IMAGES, payload });
      }
    }
  };

  const onUploadErroneousImageSuccess = async (filename, image) => {
    const body = await formatImageBeforeSaving(filename, image);

    await doCreateImages(userIdOrPublic, tourId, [body]);
    const loadedImages = await loadImages();
    const payload = {
      loadedImages,
      idToDrop: image.id,
    };
    dispatch({ type: ACTION_TYPES.CLEAR_STORAGE_AFTER_UPLOAD, payload });

    callSuccessfulyToast();
  };

  const onUploadSuccess = async filename => {
    try {
      const copyLocalImages = Object.assign([], state.localImages);
      const copyErroneousImages = Object.assign([], state.erroneousImages);
      const localImage = copyLocalImages.find(({ file }) => formatFilename(file) === filename);
      const erroneousImage = copyErroneousImages.find(({ file }) => formatFilename(file) === filename);

      if (localImage) {
        onUploadLocalImageSuccess(filename, localImage);
      } else if (erroneousImage) {
        onUploadErroneousImageSuccess(filename, erroneousImage);
      } else {
        throw Error("NOT FOUND IMAGE");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log("Error:[onUploadSuccess] ==>", error);
    }
  };

  const onUploadError = (_, task) => {
    const filename = formatFilename(task?.blob_?.data_);
    const copyLocalImages = Object.assign([], state.localImages, state.erroneousImages);
    const payload = copyLocalImages.find(({ file }) => formatFilename(file) === filename);
    if (payload) {
      dispatch({ type: ACTION_TYPES.SET_ERRONEOUS_IMAGES, payload });
    }
  };

  const onFinishSaving = useCallback(() => {
    dispatch({ type: ACTION_TYPES.LOADING, payload: false });
    if (isEdit) {
      callSuccessfulyToast();
    } else if (searchParams[PUBLIC_TOUR]) {
      history.push(`${routes.TOUR_CREATE}/${tourId}/link-rooms?${PUBLIC_TOUR}=true`);
    } else {
      history.push(`${routes.TOUR_CREATE}/${tourId}/link-rooms`);
    }
  }, [history, searchParams, tourId, isEdit]);

  const removeImages = useCallback(
    async files => {
      try {
        const updates = {};
        files.forEach(file => {
          updates[`${file.id}`] = null;
          dispatch({ type: ACTION_TYPES.DROP_IMAGE_FROM_REMOVED, payload: file });
        });

        await doUpdateImage(userIdOrPublic, tourId, updates);

        return true;
      } catch (error) {
        return error;
      }
    },
    [userIdOrPublic, tourId, dispatch]
  );

  const updateImages = useCallback(
    async files => {
      try {
        const updates = {};
        files.forEach(file => {
          if (file.updated) {
            updates[`${file.id}/name`] = file.name;
            updates[`${file.id}/index`] = file.index;
            dispatch({ type: ACTION_TYPES.CHANGE_UPDATED_TO_FALSE, payload: file });
          }
        });

        await doUpdateImage(userIdOrPublic, tourId, updates);

        return true;
      } catch (error) {
        return error;
      }
    },
    [userIdOrPublic, tourId]
  );

  const startUploadManually = useCallback(async () => {
    try {
      dispatch({ type: ACTION_TYPES.LOADING, payload: true });
      const localImages = Object.assign([], state.localImages);
      const removedImages = Object.assign([], state.removedImages);
      const loadedImages = Object.assign([], state.loadedImages);

      localImages.forEach(item => {
        fileUploaderRef.current.startUpload(item.file);
      });

      await removeImages(removedImages);
      await updateImages(loadedImages);
      if (!state.localImages.length) {
        onFinishSaving();
      }
    } catch (error) {
      dispatch({ type: ACTION_TYPES.SET_ERROR, payload: error?.message });
      dispatch({ type: ACTION_TYPES.LOADING, payload: false });
    }
  }, [state.localImages, state.removedImages, state.loadedImages, removeImages, updateImages, onFinishSaving]);

  const updalodImages = useCallback(
    async images => {
      try {
        await doCreateImages(userIdOrPublic, tourId, images);

        const loadedImages = await loadImages();
        const payload = {
          loadedImages,
        };
        dispatch({ type: ACTION_TYPES.CLEAR_STORAGE_AFTER_UPLOAD, payload });

        onFinishSaving();
      } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_ERROR, payload: error?.message });
      }
    },
    [userIdOrPublic, tourId, loadImages, onFinishSaving]
  );

  const reUploadImage = useCallback(
    async id => {
      try {
        const removedImages = Object.assign([], state.removedImages);
        const loadedImages = Object.assign([], state.loadedImages);

        await removeImages(removedImages);
        await updateImages(loadedImages);

        const payload = state.erroneousImages.find(item => item.id === id);
        if (!payload) {
          throw new Error("Image is not found");
        }
        dispatch({ type: ACTION_TYPES.RUN_REUPLOAD_IMAGE, payload });
        fileUploaderRef.current.startUpload(payload.file);
      } catch (error) {
        // eslint-disable-next-line no-console
        // console.log("Error:[reUploadImage] ==>", error);
      }
    },
    [state.removedImages, state.loadedImages, state.erroneousImages, removeImages, updateImages]
  );

  const handleProgress = (progress, task) => {
    if (progress) {
      // eslint-disable-next-line no-underscore-dangle
      const filename = formatFilename(task?.blob_?.data_);
      const copyImages = Object.assign([], state.localImages, state.erroneousImages);
      const image = copyImages.find(({ file }) => formatFilename(file) === filename);

      if (image) {
        dispatch({ type: ACTION_TYPES.UPDATE_UPLOAD_PROGRESS, payload: { id: image.id, progress } });
      }
    }
  };

  useEffect(() => {
    setSave(() => startUploadManually);
  }, [setSave, startUploadManually]);

  useEffect(() => {
    const isUpdated = state.localImages.every(item => item.preparedToUpload);
    if (isUpdated && state.preparedImages.length) {
      updalodImages(state.preparedImages);
    }
  }, [state.localImages, state.preparedImages, updalodImages]);

  useEffect(() => {
    setLoadedImages();
  }, [setLoadedImages]);

  return (
    <div className="upload-images__wrapper">
      {isMobile && state.loadedImages.length >= 1 && !state.error && (
        <div className="upload-image__info">
          <img src={iconAlert} alt="" />
          Check the name for your images
        </div>
      )}
      <div className="upload-images">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="tour-upload-input-wrapper">
          <img src={iconPlus} alt="" />

          <FileUploader
            name="image-uploader-multiple"
            accept="image/*"
            storageRef={storageRef}
            filename={formatFilename}
            onChange={customOnChangeHandler}
            onUploadSuccess={onUploadSuccess}
            onUploadError={onUploadError}
            ref={fileUploaderRef}
            onProgress={handleProgress}
            metadata={{ cacheControl: "private,max-age=3601" }}
            multiple
            hidden
          />
        </label>

        {allImages.map(({ thumbnail, name, id, progress, hasError }, index) => (
          <TourImageCard
            handleDeleteImage={async () => {
              await handleDeleteImage(index);
            }}
            handleChangeName={value => handleChangeName(index, value)}
            changeImageOrder={changeImageOrder}
            reUploadImage={reUploadImage}
            canEditImage={canEditImage}
            thumbnail={thumbnail}
            index={index}
            name={name}
            hasError={hasError}
            progress={progress}
            key={id}
            id={id}
          />
        ))}
      </div>

      <div className="tour-wrapper__footer">
        <div className="upload-image__error">
          {state.error && (
            <>
              <img src={iconAlert} alt="" />
              {state.error}
            </>
          )}
          {!isMobile && allImages.length >= 1 && !state.error && (
            <div className="upload-image__info">
              <img src={iconAlert} alt="" />
              Rename your files according to the name you want to see on tour page.
            </div>
          )}
        </div>

        <div className="tour-wrapper__buttons">
          <button
            className="custom_button-nav custom_button-nav__light custom_button-nav-outlined"
            type="button"
            onClick={() =>
              history.replace(
                `${routes.TOUR_CREATE}/${tourId}${searchParams[PUBLIC_TOUR] ? `?${PUBLIC_TOUR}=true` : ""}`
              )
            }
          >
            <span>BACK</span>
          </button>
          <button
            className={`custom_button-nav custom_button-nav__light ${loading ? "btn-loading" : ""}`}
            type="button"
            onClick={startUploadManually}
            disabled={!allImages.length || !haveUnsavedChanges}
          >
            {loading ? <img src={preloader} width="20" alt="" /> : <span>SAVE</span>}
          </button>
        </div>
      </div>
    </div>
  );
};

TourImageUploader.defaultProps = {
  setSave: () => undefined,
};

TourImageUploader.propTypes = {
  setSave: PropTypes.func,
};

export default TourImageUploader;

import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ArrowScrollTop from "../components/ArrowScrollTop";
import Footer from "../components/Footer";
import HeaderMain from "../components/HeaderMain";
import PageWrapper from "../components/PageWrapper";
import iconPlus from "../assets/img/icons/icon-plus-white.svg";
import iconClock from "../assets/img/icons/icon-clock.svg";
import * as routes from "../constants/routes";
import { doGetVirtualStagingsRefByUser } from "../firebase/db";
import { getCurrentUser } from "../firebase/auth";
import Badge from "../components/Badge";
import { finishedStatuses, inProgressStatuses } from "../constants/virtualStaging";
import no_image from "../assets/img/no_image.jpg";
import { useSelector } from "react-redux";
import SubscriptionFlow from "../components/SubscriptionFlow";

const VirtualStaging = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentSubscription = useSelector(state => state.recurly.currentSubscription);
  const [shownModal, setShownModal] = useState(false);

  const toggleModal = () => setShownModal(!shownModal);

  const checkSubscription = () => {
    if (currentSubscription[0].type === "free") {
      toggleModal();
    } else {
      history.push(routes.VIRTUAL_STAGING_UPLOAD);
    }
  };

  useLayoutEffect(() => {
    const ref = doGetVirtualStagingsRefByUser(user?.uid);
    const listener = ref.on("value", snapshot => {
      setItems(Object.values(snapshot.val() || {}).sort((a, b) => b.created_at - a.created_at));
      setIsLoading(false);
    });

    return () => ref.off("value", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  return (
    <>
      <HeaderMain />

      <PageWrapper className="virtual-staging">
        <div className="tours-filter-panel ">
          <span className="title">All photos</span>
          <button
            type="button"
            className="custom_button__light custom_button__icon custom_button__md"
            onClick={checkSubscription}
          >
            <img src={iconPlus} alt="" />
            Upload image
          </button>
        </div>
        <SubscriptionFlow onSubscribeModalClose={() => toggleModal(false)} isSubscribeModalVisible={shownModal} />
        <div className="tours-container">
          {isLoading
            ? Array.from({ length: 16 }).map((_v, index) => (
                <div className="tour-item blur" key={index}>
                  <div className="tour-image-container">
                    <div className="tour-image" style={{ backgroundImage: `url(${no_image})` }} />
                  </div>
                  <div className="tour-info">
                    <div className="tour-condition__row">
                      <div className="tour-condition__row">
                        <img src={iconClock} alt="" />
                        {moment().format("MMMM D, YYYY")}
                      </div>
                      <div className="tour-condition__row">Results 0</div>
                    </div>
                  </div>
                </div>
              ))
            : items.map(({ source_image, created_at, id, render, render_id }) => (
                <div
                  className="tour-item"
                  key={id}
                  onClick={() => {
                    finishedStatuses.includes(render.status) && !render.outputs
                      ? history.push(`${routes.VIRTUAL_STAGING_UPLOAD}/${id}`)
                      : history.push(`${routes.VIRTUAL_STAGING}/${id}/variants`);
                  }}
                >
                  <div className="tour-image-container">
                    {inProgressStatuses.includes(render.status) && <Badge text="Rendering" />}
                    {finishedStatuses.includes(render.status) && !render.outputs && <Badge text="Pending" />}
                    <div className="tour-image" style={{ backgroundImage: `url(${source_image})` }} />
                  </div>
                  <div className="tour-info">
                    <div className="tour-condition__row">
                      <div className="tour-condition__row">
                        <img src={iconClock} alt="" />
                        {moment(created_at).format("MMMM D, YYYY")}
                      </div>
                      <div className="tour-condition__row">{`Results ${
                        Object.keys(render?.outputs ?? {}).length
                      }`}</div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </PageWrapper>

      <Footer />
      <div className="tour-scroll-top">
        <ArrowScrollTop onlyIcon desktopVisible />
      </div>
    </>
  );
};

export default VirtualStaging;

import React from "react";
import * as PropTypes from "prop-types";

import Modal from "../Modal";

import iconSuccess from "../../assets/img/icons/icon-success-modal.svg";

const ModalDearchiveTour = ({ onToggle, onConfirm }) => {
  return (
    <Modal onToggle={onToggle} title="Tour dearchiving" size="sm">
      <div className="modal-default">
        <img src={iconSuccess} className="modal-default__icon" alt="" />

        <p>
          Are you sure you want to dearchive
          <br />
          this tour?
        </p>

        <button type="button" className="custom_button__light custom_button-sm-width" onClick={onConfirm}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

ModalDearchiveTour.propTypes = {
  onToggle: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ModalDearchiveTour;

import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams, useHistory, useLocation } from "react-router-dom";
import browserFingerPrint from "browser-fingerprint";

import Header from "../components/Header";
import Slider from "../components/Slider";
import TourDetails from "../components/TourDetails";

import * as routes from "../constants/routes";
import { doUpdateTourViews, doGetAgent, doGetUserValue } from "../firebase/db";
import { fetchDB } from "../redux/actions/dataActions";

import iconArrow from "../assets/img/icon-arrow.svg";
import { checkSubscriptionType } from "../redux/actions/recurlyActions";
import { usePannelum } from "../hooks/usePannelum";
import { convertPins } from "../components/TourLinkRooms";
import logo from "../assets/img/skyward-panorama-logo.png";

function Tour() {
  const { userId, tourId, roomId } = useParams();
  const [thumbnailToggler, setThumbnailToggler] = useState();
  const [agentInfo, setAgentInfo] = useState();
  const [userInfo, setUserInfo] = useState();
  const [userSubscription, setUserSubscription] = useState();
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const isTopBarHidden = urlSearchParams.get("isTopBarHidden");
  const isWaterMarkHidden = urlSearchParams.get("isWaterMarkHidden");
  const isTourDetailsHidden = urlSearchParams.get("isTourDetailsHidden");

  const history = useHistory();

  const tourIsLoaded = useSelector(state => state.data.tourIsLoaded);

  const dispatch = useDispatch();
  const getTourById = useCallback(payload => fetchDB(dispatch, payload), [dispatch]);

  const toggleThumbnailBar = () => setThumbnailToggler(!thumbnailToggler);

  const tour = useSelector(state => state.data.tour);
  const rooms = tour ? Object.values(tour.images).sort((a, b) => a.index - b.index) : [];
  const [activeTourId, setActiveTourId] = useState();

  const selectedRoom = roomId ? rooms.find(room => room.id === activeTourId) || rooms[0] : rooms[0];

  const pins =
    tour && tour.images
      ? Object.keys(tour.images ?? {}).reduce((acc, imageId) => {
          if (tour && tour.images && tour.images[imageId]?.pins) {
            acc[imageId] = convertPins(Object.values(tour.images[imageId].pins));
          }

          return acc;
        }, {})
      : {};

  const tourImages = useMemo(() => tour && Object.values(tour.images ?? {}), [tour]);

  const viewer = usePannelum(tourImages, pins, activeTourId, (_e, { tour_id }) => {
    if (tour_id !== "new-pin") {
      setActiveTourId(tour_id);
    }
  });

  useEffect(() => {
    if (selectedRoom) {
      setActiveTourId(selectedRoom.id);
    }
  }, [selectedRoom]);

  useEffect(() => {
    getTourById({ userId, tourId });
  }, [userId, tourId, getTourById]);

  useEffect(() => {
    async function getAgent() {
      try {
        if (tour) {
          setAgentInfo((await doGetAgent(tour.ownerId)).val());
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }

    getAgent();
  }, [tour]);

  useEffect(() => {
    async function getUser() {
      try {
        if (tour) {
          setUserInfo(await (await doGetUserValue(tour.ownerId)).val());
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }

    getUser();
  }, [tour]);

  useEffect(() => {
    async function getUser() {
      try {
        if (tour) {
          setUserSubscription(await checkSubscriptionType(tour.ownerId));
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }

    getUser();
  }, [tour]);

  useEffect(() => {
    if (userId && tour) {
      const date = new Date();
      const timestamp = new Date(date.getFullYear(), date.getMonth(), date.getDate()).valueOf();
      const fingerPrint = browserFingerPrint();

      doUpdateTourViews(userId, tourId, timestamp, fingerPrint);
    }
  }, [tour, tourId, userId]);

  const addLogo = useCallback(() => {
    const newPin = {
      id: "skyward-logo",
      is_panoramic: false,
      pitch: -90,
      yaw: 0,
    };

    viewer.addHotSpot({
      ...newPin,
      cssClass: "custom-hotspot logo",
      scale: true,
      avoidShowingBackground: true,
      createTooltipFunc: hotSpotDiv => {
        // eslint-disable-next-line no-param-reassign
        hotSpotDiv.style.cssText = `background-image: url('${logo}'`;
      },
    });
  }, [viewer]);

  useEffect(() => {
    if (viewer) {
      addLogo();
      viewer.on("scenechangefadedone", addLogo);
    }
  }, [viewer, addLogo]);

  if (!tour || !tour.images || !Object.values(tour.images).length) {
    if (tourIsLoaded) {
      history.push(routes.TOUR_NOT_FOUND);
    }

    return null;
  }

  const navigateToClip = clipId => {
    setActiveTourId(clipId);
  };

  return (
    <div className={`tour-scene-wrapper ${tour.isTopBarHidden || isTopBarHidden !== "true" ? "without-header" : ""}`}>
      {!tour.isTopBarHidden && isTopBarHidden !== "true" && <Header />}

      {isWaterMarkHidden !== "true" &&
        agentInfo &&
        agentInfo.avatarURL &&
        userSubscription &&
        userSubscription.type !== "free" && (
          <div className="watermark-wrapper">
            <p>Presented by:</p>
            <div
              style={{
                backgroundImage: `url('${agentInfo ? agentInfo.avatarURL : ""}')`,
              }}
            />
          </div>
        )}

      {tour && isTourDetailsHidden !== "true" && (
        <TourDetails
          userImage={userInfo && userInfo.avatarUrl}
          name={userInfo && userInfo.username}
          company={agentInfo && agentInfo.businessName}
          title={agentInfo && agentInfo.title}
          address={`${tour.address ? `${`${tour.address},`}` : ""} ${tour.unit ? `${`${tour.unit}, `}` : ""} ${
            tour.city ? `${`${tour.city}`}` : ""
          }${tour.state ? `${`, ${tour.state}`}` : ""}`}
          bathroom={Number(tour.bathroom_count)}
          bedroom={Number(tour.bedroom_count)}
          square={Number(tour.area)}
          MLSUrl={tour.MLSUrl}
          videoUrl={tour.videoUrl}
          zip={tour.zip}
        />
      )}

      <div className="is-scene-container separeted-scene-container" id="panorama" />
      <div className={`is-thumb-container ${thumbnailToggler ? "is-hidden" : ""}`}>
        <div className="thumbnails-header">
          <button className="tour-button is-btn-toggle" onClick={toggleThumbnailBar} type="button">
            <img src={iconArrow} alt="Arrow toggle" />
          </button>
        </div>
        <Slider rooms={rooms} active={activeTourId} onPress={navigateToClip} />
      </div>
    </div>
  );
}

export default Tour;

import React from "react";
import * as PropTypes from "prop-types";

import Modal from "../Modal";

import iconNotAllowed from "../../assets/img/icons/icon-not-allowed-red.svg";

const ModalArchiveTour = ({ onToggle, onConfirm }) => {
  return (
    <Modal onToggle={onToggle} title="Tour archiving" size="sm" type="tour-action">
      <div className="modal-default">
        <img src={iconNotAllowed} className="modal-default__icon" alt="" />

        <p>
          Are you sure you want to archive
          <br />
          your tour?
        </p>

        <button type="button" className="custom_button__light custom_button-sm-width" onClick={onConfirm}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

ModalArchiveTour.propTypes = {
  onToggle: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ModalArchiveTour;

import React from "react";

const TourNotFound = () => {
  return (
    <div className="container-404">
      <h1 className="title-tour-not-found">
        Tour <span>not</span> found
      </h1>
      <div className="subtitle-404">We are sorry, but the page you requested wasn&apos;t found.</div>
    </div>
  );
};

export default TourNotFound;

import React, { useCallback, useEffect, useState } from "react";
import Spinner from "react-spinkit";
import { applyActionCode, checkActionCode, sendPasswordResetEmail } from "../firebase/auth";
import LoginWrapper from "./LoginWrapper";

const DEFAULT_STATE = {
  error: "",
  restoredEmail: "",
  resetSent: false,
  validCode: null,
  verifiedCode: false,
};

const RecoverEmail = ({ actionCode }) => {
  const [state, setState] = useState(DEFAULT_STATE);

  const checkAndApplyActionCode = useCallback(async () => {
    try {
      const info = await checkActionCode(actionCode);
      const restoredEmail = info.data.email;
      await applyActionCode(actionCode);
      setState(prevState => ({ ...prevState, restoredEmail, validCode: true, verifiedCode: true }));
    } catch (err) {
      setState(prevState => ({ ...prevState, error: err?.message, validCode: false, verifiedCode: true }));
    }
  }, [actionCode]);

  useEffect(() => {
    checkAndApplyActionCode();
  }, [checkAndApplyActionCode]);

  const sendReset = async () => {
    await sendPasswordResetEmail();
    setState(prevState => ({ ...prevState, resetSent: true }));
  };

  const { error, restoredEmail, resetSent, validCode, verifiedCode } = state;

  let component;
  if (!verifiedCode) {
    component = <Spinner name="three-bounce" />;
  } else if (resetSent) {
    component = (
      <LoginWrapper page="resetPassword" title="Check your email">
        <p className="login-form-text">
          Follow the instructions sent to{" "}
          <em>
            <strong>{restoredEmail}</strong>
          </em>{" "}
          to recover your password.
        </p>
      </LoginWrapper>
    );
  } else if (verifiedCode && validCode) {
    component = (
      <LoginWrapper page="resetPassword" title="Updated email address">
        <p className="login-form-text">
          Your sign-in email address has been changed back to <span>{restoredEmail}</span>
        </p>
        <p className="login-form-text">
          If you didn&apos;t change your sign-in email, it is possible someone is trying to access your account and you
          should
        </p>

        <button className="custom_button" type="button" onClick={sendReset}>
          change your password right away
        </button>
      </LoginWrapper>
    );
  } else if (verifiedCode && !validCode) {
    component = (
      <LoginWrapper error={error} page="resetPassword" title="Unable to update your email address" titleBreak>
        <p className="login-form-text">There was a problem changing your sign-in email back.</p>
      </LoginWrapper>
    );
  }

  return component;
};

export default RecoverEmail;

import {
  SET_SUBSCRIPTIONS_DATA,
  SET_BILLING_INFO_DATA,
  SET_CURRENT_SUBSCRIPTION_DATA,
  SET_BILLING_INFO_ERROR,
  SET_CURRENT_SUBSCRIPTION_ERROR,
  SET_SUBSCRIPTIONS_ERROR,
  SET_INVOICES_ERROR,
  SET_INVOICES_DATA,
  SET_SUBSCRIPTIONS_STARTED,
  SET_CURRENT_SUBSCRIPTION_STARTED,
  SET_BILLING_INFO_STARTED,
  SET_INVOICES_STARTED,
  UPDATE_USER_PLAN_STARTED,
  UPDATE_USER_PLAN_ERROR,
  UPDATE_USER_PLAN_DATA,
  RESET_USER_PLAN_FIELDS,
  CANCEL_SUBSCRIPTION_STARTED,
  CANCEL_SUBSCRIPTION_DATA,
  CANCEL_SUBSCRIPTION_ERROR,
} from "../types";
import { subscriptionPlans as freePlans } from "../../constants/subscriptions";
import { getHeaders } from "../../utils/api";
import { baseURL } from "../../constants/api";

export const checkSubscriptionType = async userId => {
  try {
    const headers = await getHeaders();
    const response = await fetch(`${baseURL}subscription-type/${userId}`, {
      method: "GET",
      headers,
    });
    const payload = await response.json();

    return payload;
  } catch (error) {
    return error;
  }
};

export const loadSubscriptions = async dispatch => {
  try {
    dispatch({ type: SET_SUBSCRIPTIONS_STARTED });

    const headers = await getHeaders();
    const response = await fetch(`${baseURL}plans`, {
      method: "GET",
      headers,
    });
    const payload = await response.json();

    if (payload.code) {
      dispatch({ type: SET_SUBSCRIPTIONS_ERROR, payload: payload.message });
    } else {
      dispatch({ type: SET_SUBSCRIPTIONS_DATA, payload });
    }
  } catch (error) {
    dispatch({ type: SET_SUBSCRIPTIONS_ERROR, payload: error });
  }
};

export const loadCurrentSubscriptions = async dispatch => {
  try {
    dispatch({ type: SET_CURRENT_SUBSCRIPTION_STARTED });
    const headers = await getHeaders();
    const response = await fetch(`${baseURL}subscription`, {
      method: "GET",
      headers,
    });
    const payload = await response.json();

    if (payload.code) {
      dispatch({ type: SET_CURRENT_SUBSCRIPTION_ERROR, payload: payload.message });
    } else if (payload?.length === 0) {
      dispatch({ type: SET_CURRENT_SUBSCRIPTION_DATA, payload: freePlans });
    } else {
      dispatch({ type: SET_CURRENT_SUBSCRIPTION_DATA, payload });
    }
  } catch (error) {
    dispatch({ type: SET_CURRENT_SUBSCRIPTION_ERROR, payload: error });
  }
};

export const loadBillingInfo = async dispatch => {
  try {
    dispatch({ type: SET_BILLING_INFO_STARTED });
    const headers = await getHeaders();
    const response = await fetch(`${baseURL}billing_info`, {
      method: "GET",
      headers,
    });
    const payload = await response.json();

    if (payload.code) {
      dispatch({ type: SET_BILLING_INFO_ERROR, payload: null });
    } else {
      dispatch({ type: SET_BILLING_INFO_DATA, payload });
    }
  } catch (error) {
    dispatch({ type: SET_BILLING_INFO_ERROR, payload: error });
  }
};

export const putBillingInfo = async (dispatch, body, _cb) => {
  try {
    dispatch({ type: SET_BILLING_INFO_STARTED });
    const headers = await getHeaders();
    const response = await fetch(`${baseURL}billing_info`, {
      body: JSON.stringify(body),
      method: "PUT",
      headers,
    });
    const payload = await response.json();
    if (payload.code) {
      dispatch({ type: SET_BILLING_INFO_ERROR, payload: payload.message });
    } else {
      dispatch({ type: SET_BILLING_INFO_DATA, payload });
      if (typeof _cb === "function") {
        _cb();
      }
    }
  } catch (error) {
    dispatch({ type: SET_BILLING_INFO_ERROR, payload: error });
  }
};

export const loadInvoices = async (dispatch, body) => {
  try {
    dispatch({ type: SET_INVOICES_STARTED });
    const headers = await getHeaders();
    const response = await fetch(`${baseURL}invoices`, {
      body,
      method: "GET",
      headers,
    });
    const payload = await response.json();
    if (payload.code) {
      dispatch({ type: SET_INVOICES_ERROR, payload: payload.message });
    } else {
      dispatch({ type: SET_INVOICES_DATA, payload });
    }
  } catch (error) {
    dispatch({ type: SET_INVOICES_ERROR, payload: error });
  }
};

export const updateUserPlan = async (dispatch, action) => {
  try {
    dispatch({ type: UPDATE_USER_PLAN_STARTED });
    const headers = await getHeaders();
    const { billingInfo, body } = action;

    if (billingInfo) {
      const billingInfoResponse = await fetch(`${baseURL}billing_info`, {
        body: JSON.stringify(billingInfo),
        method: "PUT",
        headers,
      });
      const newBillingInfo = await billingInfoResponse.json();
      if (newBillingInfo.code) {
        dispatch({ type: SET_BILLING_INFO_ERROR, payload: newBillingInfo.message });
        dispatch({ type: UPDATE_USER_PLAN_ERROR, payload: newBillingInfo.message });

        return;
      }
      dispatch({ type: SET_BILLING_INFO_DATA, payload: newBillingInfo });
    }

    const response = await fetch(`${baseURL}subscription`, {
      body: JSON.stringify(body),
      method: "POST",
      headers,
    });
    const payload = await response.json();
    if (payload.code) {
      dispatch({ type: UPDATE_USER_PLAN_ERROR, payload: payload.message });
    } else {
      dispatch({ type: UPDATE_USER_PLAN_DATA, payload });
    }
  } catch (error) {
    dispatch({ type: UPDATE_USER_PLAN_ERROR, payload: error });
  }
};

export const cancelSubscription = async (dispatch, id, _cb) => {
  try {
    dispatch({ type: CANCEL_SUBSCRIPTION_STARTED });
    const headers = await getHeaders();
    const response = await fetch(`${baseURL}subscription`, {
      body: JSON.stringify({
        action_type: "cancel",
        id,
      }),
      method: "DELETE",
      headers,
    });
    const payload = await response.json();

    if (payload.code) {
      dispatch({ type: CANCEL_SUBSCRIPTION_ERROR, payload: payload.message });
    } else {
      dispatch({ type: CANCEL_SUBSCRIPTION_DATA, payload });
      if (typeof _cb === "function") {
        _cb();
      }
    }
  } catch (error) {
    dispatch({ type: CANCEL_SUBSCRIPTION_ERROR, payload: error });
  }
};

export const resetBillingInfoError = async dispatch => {
  dispatch({ type: SET_BILLING_INFO_ERROR, payload: null });
};

export const resetInvoicesError = async dispatch => {
  dispatch({ type: SET_INVOICES_ERROR, payload: null });
};

export const resetUserPlanFields = async dispatch => {
  dispatch({ type: RESET_USER_PLAN_FIELDS, payload: null });
};

import React, { useMemo, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { resetInvoicesError, loadInvoices } from "../redux/actions/recurlyActions";
import { formatPrice } from "../utils";

const InvoicesList = () => {
  const dispatch = useDispatch();
  const invoices = useSelector(state => state.recurly.invoices);
  const isLoaded = useSelector(state => state.recurly.invoicesAreLoaded);
  const errorText = useSelector(state => state.recurly.invoicesErrorText);

  useEffect(() => {
    loadInvoices(dispatch);

    return () => {
      resetInvoicesError(dispatch);
    };
  }, [dispatch]);

  const emptyStateText = useMemo(() => {
    if (typeof errorText === "string") {
      return errorText;
    }

    return isLoaded ? "Loading ...." : "You don't have any invoices here";
  }, [errorText, isLoaded]);

  return invoices && invoices.length ? (
    <table className="invoices-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Subscription Type</th>
          <th>Amount Charged</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map(invoice => {
          const closedAt = moment(invoice.closedAt).format("DD/MM/YYYY");
          const description = invoice.creditReasonCode ? "Refunds" : "Planned subscription payment";
          const formatedPrice = formatPrice(invoice.total, invoice.currency);

          return (
            <tr key={invoice.id}>
              <th>{closedAt}</th>
              <th>{invoice.type}</th>
              <th>{formatedPrice}</th>
              <th>{description}</th>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <p className="invoices-empty-table">{emptyStateText}</p>
  );
};

export default InvoicesList;

import { v4 } from "uuid";
import ImageResize from "image-resize";
import { Slide, toast } from "react-toastify";

const DEFAULT_STATE = {
  loadedImages: [],
  localImages: [],
  removedImages: [],
  preparedImages: [],
  erroneousImages: [],
  loading: false,
  error: "",
};

const ACTION_TYPES = {
  RESET: "RESET",
  LOADING: "LOADING",
  SET_ERROR: "SET_ERROR",
  DROP_IMAGE: "DROP_IMAGE",
  UPDATE_IMAGES: "UPDATE_IMAGES",
  SET_LOCAL_IMAGES: "SET_LOCAL_IMAGES",
  SET_LOADED_IMAGES: "SET_LOADED_IMAGES",
  MOVE_IMAGE_TO_PREPARED: "MOVE_IMAGE_TO_PREPARED",
  CHANGE_UPDATED_TO_TRUE: "CHANGE_UPDATED_TO_TRUE",
  DROP_IMAGE_FROM_REMOVED: "DROP_IMAGE_FROM_REMOVED",
  CHANGE_UPDATED_TO_FALSE: "CHANGE_UPDATED_TO_FALSE",
  CLEAR_STORAGE_AFTER_UPLOAD: "CLEAR_STORAGE_AFTER_UPLOAD",
  UPDATE_UPLOAD_PROGRESS: "UPDATE_UPLOAD_PROGRESS",
  SET_ERRONEOUS_IMAGES: "SET_ERRONEOUS_IMAGES",
  RUN_REUPLOAD_IMAGE: "RUN_REUPLOAD_IMAGE",
};

const IMAGE_THUMBNAIL_WIDTH = 538;

const imageResize = new ImageResize({
  format: "png",
  width: IMAGE_THUMBNAIL_WIDTH,
});

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADED_IMAGES:
      return {
        ...state,
        loadedImages: action.payload,
      };
    case ACTION_TYPES.SET_LOCAL_IMAGES:
      return {
        ...state,
        loading: false,
        localImages: [...state.localImages, ...action.payload],
      };

    case ACTION_TYPES.CHANGE_UPDATED_TO_FALSE:
      return {
        ...state,
        localImages: state.localImages.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, name: action.payload.name, updated: false };
          }

          return item;
        }),
        loadedImages: state.loadedImages.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, name: action.payload.name, updated: false };
          }

          return item;
        }),
        erroneousImages: state.erroneousImages.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, name: action.payload.name, updated: false };
          }

          return item;
        }),
      };
    case ACTION_TYPES.CHANGE_UPDATED_TO_TRUE:
      return {
        ...state,
        localImages: state.localImages.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, name: action.payload.name, updated: true };
          }

          return item;
        }),
        loadedImages: state.loadedImages.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, name: action.payload.name, updated: true };
          }

          return item;
        }),
        erroneousImages: state.erroneousImages.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, name: action.payload.name, updated: true };
          }

          return item;
        }),
      };

    case ACTION_TYPES.DROP_IMAGE:
      return {
        ...state,
        localImages: state.localImages.filter(item => item.id !== action.payload.id),
        loadedImages: state.loadedImages.filter(item => item.id !== action.payload.id),
        erroneousImages: state.erroneousImages.filter(item => item.id !== action.payload.id),
        removedImages: [...state.removedImages, action.payload],
      };

    case ACTION_TYPES.UPDATE_IMAGES:
      return {
        ...state,
        localImages: action.payload.localImages ?? state.localImages,
        loadedImages: action.payload.loadedImages ?? state.loadedImages,
        preparedImages: action.payload.preparedImages ?? state.preparedImages,
        erroneousImages: action.payload.erroneousImages ?? state.erroneousImages,
      };

    case ACTION_TYPES.MOVE_IMAGE_TO_PREPARED:
      return {
        ...state,
        localImages: state.localImages.map(item => {
          if (item.id === action.payload.localImageId) {
            return { ...item, preparedToUpload: true };
          }

          return item;
        }),

        preparedImages: [...state.preparedImages, action.payload.preparedImage],
      };
    case ACTION_TYPES.DROP_IMAGE_FROM_REMOVED:
      return {
        ...state,
        removedImages: state.removedImages.filter(item => item.id !== action.payload.id),
      };

    case ACTION_TYPES.UPDATE_UPLOAD_PROGRESS:
      return {
        ...state,
        localImages: state.localImages.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, progress: action.payload.progress };
          }

          return item;
        }),
        erroneousImages: state.erroneousImages.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, hasError: false, progress: action.payload.progress };
          }

          return item;
        }),
      };

    case ACTION_TYPES.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case ACTION_TYPES.CLEAR_STORAGE_AFTER_UPLOAD:
      return {
        ...state,
        loadedImages: action.payload.loadedImages ?? state.loadedImages,
        localImages: [],
        preparedImages: [],
        erroneousImages: state.erroneousImages.filter(item => item.id !== action.payload.idToDrop),
      };

    case ACTION_TYPES.RUN_REUPLOAD_IMAGE:
      return {
        ...state,
        erroneousImages: [
          ...state.erroneousImages.filter(item => item.id !== action.payload.id),
          { ...action.payload, hasError: false, progress: 0 },
        ],
      };

    case ACTION_TYPES.SET_ERRONEOUS_IMAGES:
      return {
        ...state,
        loading: false,
        localImages: state.localImages.filter(item => item.id !== action.payload.id),
        loadedImages: state.loadedImages.filter(item => item.id !== action.payload.id),
        erroneousImages: [
          ...state.erroneousImages.filter(item => item.id !== action.payload.id),
          { ...action.payload, hasError: true, progress: undefined },
        ],
      };

    case ACTION_TYPES.RESET:
      return DEFAULT_STATE;
    default:
      throw new Error();
  }
};

const formatImages = images => {
  return images.map(image => ({
    name: image.name,
    thumbnail: image.thumbnail_url,
    id: image.id,
    index: image.index || 0,
    updated: typeof image.index !== "number",
    size: image.size,
    lastModified: image.lastModified,
  }));
};

const sortImages = images => {
  return images.sort((a, b) => {
    return a.index - b.index;
  });
};

const handleFileChosen = async (file, index = 0) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    imageResize
      .play({ files: [file] })
      .then(thumbnail => {
        const name = file.name.split(".").slice(0, -1).join();

        reader.onloadend = () => {
          resolve({ index, file, thumbnail, name, id: v4() });
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const callSuccessfulyToast = () => {
  toast.success("Changes have been saved", {
    position: "top-right",
    autoClose: 3000,
    transition: Slide,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};

const callErrorToast = text => {
  toast.error(text, {
    position: "top-right",
    autoClose: 3000,
    transition: Slide,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};

export {
  handleFileChosen,
  sortImages,
  formatImages,
  imageResize,
  reducer,
  ACTION_TYPES,
  DEFAULT_STATE,
  callSuccessfulyToast,
  callErrorToast,
};

import React, { useEffect, useRef, useState } from "react";
import * as PropTypes from "prop-types";
import * as db from "../firebase/db";
import { doCreateUserWithEmailAndPassword, doSendEmailVerification, doUpdateDisplayName } from "../firebase/auth";

import userIcon from "../assets/img/icons/icon-user.svg";
import emailIcon from "../assets/img/icons/icon-email.svg";
import lockIcon from "../assets/img/icons/icon-lock.svg";

const DEFAULT_SIGNUP_STATE = {
  username: "",
  passwordOne: "",
  passwordTwo: "",
  accept: false,
  isLoading: false,
  isFocused: false,
};

const SignUpForm = ({ setError }) => {
  const usernameRef = useRef(null);

  const [signUpState, setSignUpState] = useState(DEFAULT_SIGNUP_STATE);

  useEffect(() => {
    if (!signUpState.isFocused) {
      usernameRef.current.focus();
    }
  }, [signUpState.isFocused]);

  const handleFieldChange = e => {
    const { name, value } = e.currentTarget;
    setSignUpState({ ...signUpState, [name]: value });
  };

  const handleCheckboxChange = e => {
    const { checked } = e.currentTarget;
    setSignUpState({ ...signUpState, accept: checked });
  };

  const isInvalid =
    signUpState.passwordOne !== signUpState.passwordTwo ||
    signUpState.passwordOne === "" ||
    signUpState.email === "" ||
    signUpState.username === "" ||
    signUpState.accept === false;

  const handleSubmit = async event => {
    const { username, email, passwordOne } = signUpState;
    event.preventDefault();

    try {
      const authUser = await doCreateUserWithEmailAndPassword(email, passwordOne);

      await doUpdateDisplayName(username);

      await db.doCreateAgent(authUser.user.uid, {
        name: "",
        title: "",
        businessName: "",
        address: "",
        email: "",
        phone: "",
        avatarURL: "",
      });

      await doSendEmailVerification();

      await db.doCreateUser(authUser.user.uid, { username, email });
    } catch (err) {
      setError(err?.message);
    }
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="input-wrapper">
        <img className="input-icon" src={userIcon} alt="" />
        <input
          name="username"
          type="text"
          ref={usernameRef}
          placeholder="Enter username"
          value={signUpState.username}
          onChange={handleFieldChange}
        />
      </div>
      <div className="input-wrapper">
        <img className="input-icon" src={emailIcon} alt="" />
        <input
          name="email"
          type="email"
          placeholder="Enter email"
          value={signUpState.email}
          onChange={handleFieldChange}
        />
      </div>
      <div className="input-wrapper">
        <img className="input-icon" src={lockIcon} alt="" />
        <input
          name="passwordOne"
          type="password"
          placeholder="Enter password"
          value={signUpState.passwordOne}
          onChange={handleFieldChange}
        />
      </div>
      <div className="input-wrapper">
        <img className="input-icon" src={lockIcon} alt="" />
        <input
          name="passwordTwo"
          type="password"
          placeholder="Confirm password"
          value={signUpState.passwordTwo}
          onChange={handleFieldChange}
        />
      </div>

      <div className="login-info-row">
        <div className="checkbox">
          <label htmlFor="accept" className={`${signUpState.accept ? "checked" : ""}`}>
            <input
              type="checkbox"
              id="accept"
              name="accept"
              value={signUpState.accept}
              checked={signUpState.accept}
              onChange={handleCheckboxChange}
            />
            <span>I accept terms and conditions</span>
          </label>
        </div>
      </div>

      <button className="custom_button" type="submit" disabled={signUpState.isLoading || isInvalid}>
        {signUpState.isLoading ? "Signing Up....." : "Sign Up"}
      </button>
    </form>
  );
};

SignUpForm.propTypes = {
  setError: PropTypes.func.isRequired,
};

export default SignUpForm;

import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../firebase/auth";

import { doGetAgent, doUpdateAgent } from "../firebase/db";

import suitcaseIcon from "../assets/img/icons/icon-suitcase.svg";
import iconCheckedSuccess from "../assets/img/icons/icon-checked-success.svg";

const DEFAULT_CURRENT_AGENT = {
  name: "",
  title: "",
  businessName: "",
  address: "",
  email: "",
  phone: "",
};
const DEFAULT_AGENT_STATE = {
  error: "",
  ...DEFAULT_CURRENT_AGENT,
};

const AgentView = () => {
  const [success, setSuccess] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [currentAgent, setCurrentAgent] = useState(DEFAULT_CURRENT_AGENT);
  const [agentState, setAgentState] = useState(DEFAULT_AGENT_STATE);
  const authUser = getCurrentUser();

  useEffect(() => {
    async function getAgent() {
      let agent;
      try {
        agent = await doGetAgent(authUser.uid);
      } catch (err) {
        return setAgentState(prevState => ({ ...prevState, error: err?.message }));
      }
      setCurrentAgent(prevState => ({ ...prevState, ...agent.val() }));

      return setAgentState(prevState => ({ ...prevState, ...agent.val() }));
    }
    getAgent();
    setSuccess(false);
  }, [authUser.uid]);

  const handleSubmit = async event => {
    const { title, businessName, address, email, phone } = agentState;
    event.preventDefault();
    try {
      await doUpdateAgent(authUser.uid, { title, businessName, address, email, phone });
    } catch (err) {
      return setAgentState(prevState => ({ ...prevState, error: err?.message }));
    }
    setCurrentAgent(prevState => ({ ...prevState, title, businessName, address, email, phone }));
    setSuccess(true);

    return setAgentState(prevState => ({
      ...prevState,
      title,
      businessName,
      address,
      email,
      phone,
    }));
  };

  const handleFieldChange = e => {
    setSuccess(false);
    const { name, value } = e.currentTarget;
    setAgentState(prevState => {
      return { ...prevState, [name]: value };
    });
  };

  return (
    <div className="settings-form__wrapper">
      <div className="settings-form__title">
        <img src={suitcaseIcon} alt="" />
        Agent Information
      </div>
      <div className="settings-form__subtitle">Viewers of your tour will see agent information as a watermark.</div>

      <form className="settings-form" onSubmit={handleSubmit}>
        <div className="text-field">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="name">
            Business Name
            <span className="required">*</span>
          </label>
          <input
            name="businessName"
            type="text"
            placeholder="Enter your data here"
            value={agentState.businessName}
            onChange={handleFieldChange}
            required
          />
        </div>

        <div className="text-field">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="name">Title</label>
          <input
            placeholder="Enter your data here"
            name="title"
            type="text"
            value={agentState.title}
            onChange={handleFieldChange}
          />
        </div>

        <div className="text-field">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="name">Address</label>
          <input
            name="address"
            type="text"
            placeholder="Enter your data here"
            value={agentState.address}
            onChange={handleFieldChange}
          />
        </div>

        <div className="text-field">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="name">Email</label>
          <input
            placeholder="Enter your data here"
            name="email"
            type="email"
            value={agentState.email}
            onChange={handleFieldChange}
          />
        </div>

        <div className="text-field">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="name">Phone</label>
          <input
            placeholder="Enter your data here"
            name="phone"
            type="tel"
            value={agentState.phone}
            onChange={handleFieldChange}
          />
        </div>

        <div className="settings-form__footer">
          <button className="custom_button__light" type="submit" disabled={!agentState.businessName.length}>
            Save
          </button>

          {agentState.error && <p className="error">{agentState.error}</p>}

          {success && !agentState.error && (
            <div className="success">
              <img src={iconCheckedSuccess} alt="" />
              Your information has been updated
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default AgentView;

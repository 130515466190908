import React, { useState } from "react";

import { useSelector } from "react-redux";
import cardIcon from "../../assets/img/icons/icon-credit-card.svg";
import iconPlus from "../../assets/img/icons/icon-plus-white.svg";
import ModalPaymentMethod from "../modals/ModalPaymentMethod";

const SubscriptionPaymentMethod = () => {
  const [shownModal, setShownModal] = useState(false);
  const billingInfo = useSelector(state => state.recurly.billingInfo);
  const toggleModal = () => setShownModal(!shownModal);

  const onPaymentAddMethodClick = () => toggleModal;

  return (
    <>
      <section className="settings-subscription__row">
        <div className="settings-form__title">
          <img src={cardIcon} alt="" />
          Payment Method
        </div>
        <button
          className="custom_button__light custom_button-sm-width custom_button__icon"
          onClick={onPaymentAddMethodClick()}
          type="button"
        >
          <img src={iconPlus} alt="" />
          {billingInfo ? "Edit" : "Add"}
        </button>
      </section>
      {shownModal && <ModalPaymentMethod onToggle={toggleModal} />}
    </>
  );
};

export default SubscriptionPaymentMethod;

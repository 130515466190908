import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { doReauthenticate, doSocialReauthenticate, doUpdatePassword, getCurrentUser } from "../firebase/auth";
import * as routes from "../constants/routes";

import lockIcon from "../assets/img/icons/icon-lock.svg";
import iconCheckedSuccess from "../assets/img/icons/icon-checked-success.svg";
import { appleAuthProvider, googleAuthProvider } from "../firebase/firebase";

const DEFAULT_PASSWORD_UPDATE_STATE = {
  currentPassword: "",
  newPasswordOne: "",
  newPasswordTwo: "",
  isLoading: false,
  isFocused: false,
};

const PasswordUpdateForm = () => {
  const history = useHistory();
  const user = getCurrentUser();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const hasPasswordProvider = !!user.providerData.find(provider => provider.providerId === "password");
  const hasGoogleProvider = !!user.providerData.find(provider => provider.providerId.includes("google"));
  const hasAppleProvider = !!user.providerData.find(provider => provider.providerId.includes("apple"));

  const [passwordUpdateState, setPasswordUpdateState] = useState(DEFAULT_PASSWORD_UPDATE_STATE);

  const handleFieldChange = e => {
    setError("");
    setSuccess(false);
    const { name, value } = e.currentTarget;
    setPasswordUpdateState({ ...passwordUpdateState, [name]: value });
  };

  const isInvalid =
    passwordUpdateState.newPasswordOne !== passwordUpdateState.newPasswordTwo ||
    passwordUpdateState.newPasswordOne === "";

  const handleSubmit = async event => {
    const { currentPassword, newPasswordOne } = passwordUpdateState;
    event.preventDefault();
    try {
      if (hasPasswordProvider) {
        await doReauthenticate(currentPassword);
      } else if (hasGoogleProvider) {
        await doSocialReauthenticate(googleAuthProvider);
      } else if (hasAppleProvider) {
        await doSocialReauthenticate(appleAuthProvider);
      }

      setError("");

      await doUpdatePassword(newPasswordOne);
      setSuccess(true);
      setPasswordUpdateState(DEFAULT_PASSWORD_UPDATE_STATE);

      setTimeout(() => {
        return history.push(routes.ACCOUNT);
      }, 2000);
    } catch (err) {
      setError(err?.message);
    }
  };

  const title = hasPasswordProvider ? "Change Password" : "Create Password";

  return (
    <div className="settings-form__wrapper">
      <div className="settings-form__title">
        <img src={lockIcon} alt="" />
        {title}
      </div>
      <form onSubmit={handleSubmit} className="settings-form">
        {hasPasswordProvider ? (
          <div className="text-field">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="name">
              Current Password
              <span className="required">*</span>
            </label>
            <input
              name="currentPassword"
              type="password"
              placeholder="Current password"
              value={passwordUpdateState.currentPassword}
              onChange={handleFieldChange}
              required
            />
          </div>
        ) : null}
        <div className="text-field">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="name">
            New Password
            <span className="required">*</span>
          </label>
          <input
            name="newPasswordOne"
            type="password"
            placeholder="New password"
            value={passwordUpdateState.newPasswordOne}
            onChange={handleFieldChange}
            required
          />
        </div>
        <div className="text-field">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="name">
            Confirm New Password
            <span className="required">*</span>
          </label>
          <input
            name="newPasswordTwo"
            type="password"
            placeholder="Confirm new password"
            value={passwordUpdateState.newPasswordTwo}
            onChange={handleFieldChange}
            required
          />
        </div>
        <div className="settings-form__footer">
          <button className="custom_button__light" type="submit" disabled={passwordUpdateState.isLoading || isInvalid}>
            {passwordUpdateState.isLoading ? "Updating..." : "Save"}
          </button>

          {error && <p className="error">{error}</p>}

          {success && !error && (
            <div className="success">
              <img src={iconCheckedSuccess} alt="" />
              Your password has been updated
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default PasswordUpdateForm;

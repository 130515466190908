import React from "react";
import AccountSettingsWrapper from "../../components/AccountSettings/AccountSettingsWrapper";
import AccountLogo from "../../components/AccountSettings/AccountLogo";

const AccountMobileLogo = () => {
  return (
    <AccountSettingsWrapper title="Company Logo">
      <AccountLogo />
    </AccountSettingsWrapper>
  );
};

export default AccountMobileLogo;

import React, { useState, useContext, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";

import * as PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { doSignOut } from "../firebase/auth";
import { AuthUserContext } from "../contextProviders/AuthContextProvider";

import * as routes from "../constants/routes";
import checkMobile from "../hooks/checkMobile";

import logo from "../assets/img/logo.png";
import iconHamburger from "../assets/img/icons/icon-menu.svg";
import iconClose from "../assets/img/icons/icon-close-bold.svg";
import iconlogOut from "../assets/img/icons/icon-logout.svg";
import { RESET_RECURLY_STORE } from "../redux/types";

const NavBar = ({ homepage }) => {
  const dispatch = useDispatch();
  const { authUser } = useContext(AuthUserContext);

  const isMobile = checkMobile();
  const history = useHistory();

  const handleLogoutClick = async () => {
    dispatch({ type: RESET_RECURLY_STORE });
    await doSignOut();

    return history.push(routes.SIGN_IN);
  };

  const [activeNavigation, setActiveNavigation] = useState(false);

  const toggleNavigation = () => {
    if (isMobile) {
      setActiveNavigation(!activeNavigation);
      document.querySelector("body").classList.toggle("nav-is-opened");
    }
  };

  useEffect(() => {
    return () => {
      if (activeNavigation) {
        document.querySelector("body").classList.remove("nav-is-opened");
      }
    };
  }, [activeNavigation]);

  const renderAccountLink = () => {
    if (authUser) {
      if (homepage) {
        return (
          <div>
            <ul className="header-main-list header-main-list__account">
              <li>
                <NavLink
                  activeClassName="navbar-link-active"
                  className="custom_button-nav custom_button-nav__lg"
                  to={routes.TOURS}
                >
                  <span>GO TO MY PROFILE</span>
                </NavLink>
              </li>
            </ul>
          </div>
        );
      }

      return (
        <div>
          <ul className="header-main-list header-main-list__account">
            <li>
              <div
                className="logout-button custom_button-nav custom_button-nav-outlined custom_button-nav__lg"
                onClick={handleLogoutClick}
              >
                <img className="icon" src={iconlogOut} alt="" />
                <span>SIGN OUT</span>
              </div>
            </li>
          </ul>
        </div>
      );
    }

    return (
      <div className="header-account-links">
        <ul className="header-main-list header-main-list__account">
          <li>
            <NavLink
              activeClassName="is-active"
              className="custom_button-nav custom_button-nav-outlined"
              to={routes.SIGN_UP}
            >
              <span>Sign Up</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="is-active" className="custom_button-nav" to={routes.SIGN_IN}>
              <span>LOGIN</span>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div className={`header-wrapper ${activeNavigation ? "is-active" : ""}`}>
      <div className="header-main-logo">
        <NavLink to={routes.LANDING}>
          <img src={logo} alt="" />
        </NavLink>
      </div>

      <button type="button" onClick={toggleNavigation} className="navToggle">
        <img src={iconHamburger} alt="" />
      </button>

      <div className="navigation-overlay" onClick={toggleNavigation} />

      <div className="navigation-wrapper">
        <button type="button" onClick={toggleNavigation} className="navClose">
          <img src={iconClose} alt="" />
        </button>

        <div className="nav-logo">
          <img src={logo} alt="" />
        </div>

        <div className="header-main-list__wrapper">
          <ul className="header-main-list">
            {homepage ? (
              <>
                <li>
                  <a onClick={toggleNavigation} className="header-main-link" href={`${window.location.origin}/#about`}>
                    About us
                  </a>
                  {/* <AnchorLink onClick={toggleNavigation} className="header-main-link" href="#about" offset={offset}>
                    About us
                  </AnchorLink> */}
                </li>
                <li>
                  <NavLink activeClassName="is-active" className="header-main-link" to={routes.COMMUNITY_TOURS}>
                    Community Tours
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                <li>
                  <NavLink activeClassName="is-active" className="header-main-link" to={routes.VIRTUAL_STAGING}>
                    Virtual Staging
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="is-active" className="header-main-link" to={routes.TOURS}>
                    My Tours
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="is-active" className="header-main-link" to={routes.ACCOUNT}>
                    Settings
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>

        {renderAccountLink()}
      </div>
    </div>
  );
};

NavBar.propTypes = {
  homepage: PropTypes.bool,
};

NavBar.defaultProps = {
  homepage: undefined,
};

export default NavBar;

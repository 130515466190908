import React from "react";
import * as PropTypes from "prop-types";

import UploadProfilePhoto from "../UploadProfilePhoto";
import { storage } from "../../firebase/firebase";
import { getCurrentUser } from "../../firebase/auth";
import { doGetUserValue, doUpdateUser } from "../../firebase/db";

const AccountPhoto = ({ userName, toursCount }) => {
  const getStoragePhotoRef = () => storage().ref("profilePicture");

  const authUser = getCurrentUser();

  const getAvatarUrl = async () => {
    const user = (await doGetUserValue(authUser.uid)).val();

    return user.avatarUrl;
  };

  const handleSuccessPhotoUpload = async avatarUrl => {
    await doUpdateUser(authUser.uid, { avatarUrl });
  };

  return (
    <div className="settings__images">
      <UploadProfilePhoto
        getStorageRef={getStoragePhotoRef}
        getAvatarURL={getAvatarUrl}
        handleSuccessUpload={handleSuccessPhotoUpload}
      />
      <p className="settings__name">{userName || "Your name"}</p>
      <div className="settings_info__row">
        <div className="settings__project">
          <p>
            {toursCount && toursCount.tours}
            <span>objects</span>
          </p>
        </div>
        <div className="settings__project">
          <p>
            {toursCount && toursCount.archivedTours}
            <span>archived</span>
          </p>
        </div>
      </div>
    </div>
  );
};

AccountPhoto.propTypes = {
  userName: PropTypes.string.isRequired,
  toursCount: PropTypes.shape({ tours: PropTypes.number.isRequired, archivedTours: PropTypes.number.isRequired })
    .isRequired,
};

export default AccountPhoto;

import React, { useState } from "react";
import * as PropTypes from "prop-types";

import userIcon from "../assets/img/icons/icon-user.svg";
import arrowIcon from "../assets/img/icons/tour-details/tour-arrow.svg";
import bathIcon from "../assets/img/icons/tour-details/tour-bath-icon.svg";
import bedIcon from "../assets/img/icons/tour-details/tour-bed-icon.svg";
import squareIcon from "../assets/img/icons/tour-details/tour-square-icon.svg";

const renderBusinessTitle = (title, company) => {
  if (title && company) {
    return (
      <p>
        {title} in {company}
      </p>
    );
  }

  if (company) {
    return <p>{company}</p>;
  }

  return <p>This agent has not provided personal information</p>;
};

const TourDetails = ({
  userImage,
  name,
  title,
  company,
  zip,
  address,
  bathroom,
  bedroom,
  square,
  MLSUrl,
  videoUrl,
}) => {
  const [activeBar, setActiveBar] = useState(false);

  const toggleActiveBar = () => setActiveBar(!activeBar);

  return (
    <div className={`tour-details ${activeBar ? "is-active" : ""}`}>
      <button className="tour-button tour-details__button" onClick={toggleActiveBar} type="button">
        <img src={arrowIcon} alt="" />
      </button>
      <div className="tour-details__header">
        <div className="tour-details__image">
          <img className={`${userImage ? "" : "userIcon"}`} src={userImage || userIcon} alt="" />
        </div>
        <div className="tour-details__name">
          <h3>{name || "N/A"}</h3>
          {renderBusinessTitle(title, company)}
        </div>
      </div>

      {address ? (
        <p className="tour-details__address">
          {address} {zip || null}
        </p>
      ) : null}

      {videoUrl || MLSUrl ? (
        <p className="tour-details__subtitle">
          {MLSUrl ? <a href={MLSUrl}>MLS url</a> : null}
          <br />
          {videoUrl ? <a href={videoUrl}>Video walkthrough</a> : null}
        </p>
      ) : null}

      {bathroom || bedroom || square ? <p className="tour-details__subtitle">Details:</p> : null}

      {bathroom && bathroom !== 0 ? (
        <div className="tour-details__row">
          <div>
            <img src={bathIcon} alt="" />
            Bathroom
          </div>
          <span>{bathroom}</span>
        </div>
      ) : null}

      {bedroom && bedroom !== 0 ? (
        <div className="tour-details__row">
          <div>
            <img src={bedIcon} alt="" />
            Bedroom
          </div>
          <span>{bedroom}</span>
        </div>
      ) : null}

      {square && square !== 0 ? (
        <div className="tour-details__row">
          <div>
            <img src={squareIcon} alt="" />
            Square
          </div>
          <span>{square} Sq Ft</span>
        </div>
      ) : null}
    </div>
  );
};

TourDetails.defaultProps = {
  userImage: null,
  name: null,
  company: null,
  title: null,
  address: null,
  bathroom: null,
  bedroom: null,
  square: null,
  MLSUrl: null,
  videoUrl: null,
  zip: null,
};

TourDetails.propTypes = {
  userImage: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  company: PropTypes.string,
  address: PropTypes.string,
  bathroom: PropTypes.number,
  bedroom: PropTypes.number,
  square: PropTypes.number,
  MLSUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  zip: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TourDetails;

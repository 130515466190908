import React from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import RecoverEmail from "./RecoverEmail";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";

const Action = () => {
  const location = useLocation();

  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { mode } = searchParams;

  const actionCode = searchParams.oobCode;

  switch (mode) {
    case "recoverEmail":
      return <RecoverEmail actionCode={actionCode} />;
    case "resetPassword":
      return <ResetPassword actionCode={actionCode} />;
    case "verifyEmail":
      return <VerifyEmail actionCode={actionCode} />;
    default:
      return (
        <div className="Action">
          <h1>Error encountered</h1>
          <p>The selected page mode is invalid.</p>
        </div>
      );
  }
};

export default Action;

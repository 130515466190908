import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import checkMobile from "../hooks/checkMobile";

import HeaderMain from "../components/HeaderMain";
import PageWrapper from "../components/PageWrapper";
import Footer from "../components/Footer";
import AccountView from "../components/AccountView";
import AgentView from "../components/AgentView";
import SubscriptionView from "../components/SubscriptionView";

import AccountMobile from "./AccountMobileNav";

import { getCurrentUser } from "../firebase/auth";
import { doGetToursByUser, doGetArchivedToursByUser } from "../firebase/db";

import alert from "../assets/img/icons/icon-alert.svg";
import AccountPhoto from "../components/AccountSettings/AccountPhoto";
import AccountLogo from "../components/AccountSettings/AccountLogo";
import useScrollTopOnHistory from "../hooks/useScrollTopOnHistory";
import {
  loadSubscriptions,
  loadCurrentSubscriptions,
  loadBillingInfo,
  loadInvoices,
} from "../redux/actions/recurlyActions";

const Account = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [toursCount, setToursCount] = useState({ tours: 0, archivedTours: 0 });
  const [userName, setUserName] = useState("");

  const authUser = getCurrentUser();
  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const tab = search.get("tab");
  const [activeTab, setActiveTab] = useState(tab || "accountInfo");

  useEffect(() => {
    if (tab && tab !== activeTab) {
      setActiveTab(tab);
    }
  }, [tab, activeTab]);

  const handleTabClick = clickedTab => {
    search.set("tab", clickedTab);
    search.delete("page");

    history.push({ pathname: location.pathname, search: search.toString() });
  };

  useScrollTopOnHistory();

  const getToursByUserId = useCallback(async () => {
    let resultActive;
    let resultArchived;

    try {
      resultActive = await doGetToursByUser(authUser.uid, {});
      resultArchived = await doGetArchivedToursByUser(authUser.uid, {});
    } catch (err) {
      setError(err.message);
    }

    const tours = [];
    Object.values(resultActive.val() || {}).forEach(item => {
      tours.push(item);
    });

    const archivedTours = [];
    Object.values(resultArchived.val() || {}).forEach(item => {
      archivedTours.push(item);
    });

    setToursCount({ tours: tours.length, archivedTours: archivedTours.length });
    setUserName(authUser.displayName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadInvoices(dispatch);
    loadSubscriptions(dispatch);
    loadCurrentSubscriptions(dispatch);
    loadBillingInfo(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getToursByUserId();
  }, [getToursByUserId]);

  const accountInfoActiveTab = activeTab === "accountInfo";
  const agentInfoActiveTab = activeTab === "agentInfo";
  const subscriptionActiveTab = activeTab === "subscription";

  const handleConfirmUserName = async () => {
    await authUser.reload();
    const reloadedUserName = authUser.displayName;
    setUserName(reloadedUserName);
  };

  return (
    <>
      <HeaderMain />

      <PageWrapper>
        <div className="settings__wrapper">
          <div className="settings-right__col">
            <AccountPhoto userName={userName} toursCount={toursCount} />

            <AccountLogo />
          </div>

          <div className="settings-left__col">
            {/* tabs header */}
            {error && (
              <div className="error-wrapper">
                <p className="error">
                  <img src={alert} alt="" />
                  {error}{" "}
                </p>
              </div>
            )}

            <div className="settings-tabs-header">
              <button
                className={`${accountInfoActiveTab ? "is-active" : ""} settings-tabs-button`}
                type="button"
                onClick={() => handleTabClick("accountInfo")}
              >
                Account info
              </button>
              <button
                className={`${agentInfoActiveTab ? "is-active" : ""} settings-tabs-button`}
                type="button"
                onClick={() => handleTabClick("agentInfo")}
              >
                Agent info
              </button>
              <button
                className={`${subscriptionActiveTab ? "is-active" : ""} settings-tabs-button`}
                type="button"
                onClick={() => handleTabClick("subscription")}
              >
                Subscription
              </button>
            </div>

            {/* tabs */}
            <div className="settings-tabs-wrapper">
              <div className={`${accountInfoActiveTab ? "is-active" : ""} settings-tab tab-account`}>
                <AccountView setError={setError} handleConfirmUserName={handleConfirmUserName} />
              </div>

              <div className={`${agentInfoActiveTab ? "is-active" : ""} settings-tab tab-agent`}>
                <AgentView />
              </div>

              <div className={`${subscriptionActiveTab ? "is-active" : ""} settings-tab tab-subscription`}>
                <SubscriptionView />
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>

      <Footer />
    </>
  );
};

const withMobile = (ComponentDesktop, ComponentMobile) => {
  return props => {
    const isMobile = checkMobile(767);

    if (isMobile) {
      return <ComponentMobile {...props} />;
    }

    return <ComponentDesktop {...props} />;
  };
};

export default withMobile(Account, AccountMobile);

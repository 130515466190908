import React from "react";
import * as PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import thumbnailDefaultImage from "../assets/img/placeholder.png";

function Slider({ rooms, active, onPress }) {
  return (
    <SimpleBar className="thumbs" forceVisible="y">
      {rooms.map(value => (
        <div
          key={`${value.id}_slider`}
          onClick={() => onPress(value.id)}
          className={value.id === active ? "is-active thumb" : "thumb"}
        >
          <p>{value.name}</p>
          <img src={value.thumbnail_url || thumbnailDefaultImage} draggable="false" alt={`${value.name}'s`} />
        </div>
      ))}
    </SimpleBar>
  );
}

Slider.defaultProps = {
  active: "",
};

Slider.propTypes = {
  rooms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  active: PropTypes.string,
  onPress: PropTypes.func.isRequired,
};

export default Slider;

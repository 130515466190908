import React, { useCallback, useEffect, useState } from "react";
import Spinner from "react-spinkit";
import * as routes from "../constants/routes";
import { applyActionCode } from "../firebase/auth";
import LoginWrapper from "./LoginWrapper";

const DEFAULT_STATE = {
  error: "",
  validCode: null,
  verifiedCode: false,
};

const VerifyEmail = ({ actionCode }) => {
  const [state, setState] = useState(DEFAULT_STATE);

  const handleActionCode = useCallback(async () => {
    try {
      await applyActionCode(actionCode);

      return setState(prevState => ({ ...prevState, validCode: true, verifiedCode: true }));
    } catch (err) {
      return setState(prevState => ({ ...prevState, error: err.message, validCode: false, verifiedCode: true }));
    }
  }, [actionCode]);

  useEffect(() => {
    handleActionCode();
  }, [handleActionCode]);

  const { error, validCode, verifiedCode } = state;

  let component;
  if (!verifiedCode) {
    component = <Spinner name="three-bounce" />;
  } else if (verifiedCode && validCode) {
    component = (
      <LoginWrapper title="Your email has been verified" titleBreak page="resetPassword">
        <p className="login-form-text">You can now sign in with your new account</p>
        <a className="custom_button" href={routes.LANDING}>
          Sign in
        </a>
      </LoginWrapper>
    );
  } else if (verifiedCode && !validCode) {
    component = (
      <LoginWrapper page="resetPassword" title="Try verifying your email again" titleBreak>
        <p className="login-form-text">{error}</p>
      </LoginWrapper>
    );
  }

  return component;
};

export default VerifyEmail;

import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Switch from "react-switch";
import * as PropTypes from "prop-types";

import { loadSubscriptions } from "../redux/actions/recurlyActions";

import SubscriptionItem from "./Landing/SubscriptionItem";
import EnterpriseBanner from "./Landing/EnterpriseBanner";

const SubscriptionPlan = ({ setPlanId }) => {
  const [anuallyBilled, setAnuallyBilled] = useState(false);

  const dispatch = useDispatch();
  const subscriptions = useSelector(state => state.recurly.subscriptions);
  const subscriptionPlans = useMemo(() => {
    return subscriptions.filter(item => (anuallyBilled ? item.interval === 12 : item.interval === 1));
  }, [anuallyBilled, subscriptions]);

  useEffect(() => {
    loadSubscriptions(dispatch);
  }, [dispatch]);

  return (
    <>
      <div className="modal-subscription">
        <div className={`subscription-switch ${anuallyBilled ? "anually" : "monthly"}`}>
          <p className="subscription-switch__label">Billed Monthly</p>
          <Switch
            onChange={() => setAnuallyBilled(!anuallyBilled)}
            checked={anuallyBilled}
            checkedIcon={false}
            uncheckedIcon={false}
            height={12}
            width={35}
            onHandleColor="#2B7992"
            offHandleColor="#2B7992"
            onColor="#91CFEB"
            offColor="#91CFEB"
            handleDiameter={18}
            boxShadow="0px 1.5px 6px rgba(0, 79, 107, 0.22)"
          />
          <p className="subscription-switch__label">Billed Annually</p>
        </div>

        <div className="modal-subscription__row">
          {subscriptionPlans.map(item => (
            <SubscriptionItem
              key={item.id}
              id={item.id}
              type={item.type}
              price={item.currencies[0].unitAmount}
              currency={item.currencies[0].currency}
              monthly={!anuallyBilled}
              options={item.options}
              onPress={setPlanId}
            />
          ))}
        </div>
        <EnterpriseBanner />
      </div>
    </>
  );
};
SubscriptionPlan.propTypes = {
  setPlanId: PropTypes.func.isRequired,
};
export default SubscriptionPlan;

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getCurrentUser } from "../firebase/auth";

import { loadCurrentSubscriptions } from "../redux/actions/recurlyActions";
import { RESET_RECURLY_STORE } from "../redux/types";

const useSubscriptionTrack = () => {
  const dispatch = useDispatch();
  const user = getCurrentUser();
  const userId = user?.uid;

  useEffect(() => {
    if (userId) {
      loadCurrentSubscriptions(dispatch);
    } else {
      dispatch({ type: RESET_RECURLY_STORE });
    }
  }, [dispatch, userId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userId) {
        loadCurrentSubscriptions(dispatch);
      }
    }, 30 * 1000);

    return () => clearInterval(interval);
  }, [dispatch, userId]);

  return null;
};

export default useSubscriptionTrack;

export const SORT_PARAMS = {
  CUSTOM: "custom",
  NEWEST: "newest",
  OLDEST: "oldest",
  MOST_VIEWED: "most_viewed",
  LEAST_VIEWED: "least_viewed",
};

export const FILTER_PARAMS = {
  BEDROOMS: "bedrooms",
  BATHROOM: "bathroom",
  SQUARE: "square",
};

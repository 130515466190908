import React, { useState } from "react";

import UploadAgentPhoto from "../UploadAgentPhoto";

import { storage } from "../../firebase/firebase";
import { doGetAgent, doUpdateAgentAvatarURL } from "../../firebase/db";
import { getCurrentUser } from "../../firebase/auth";

const AccountLogo = () => {
  const [logoError, setLogoError] = useState("");

  const authUser = getCurrentUser();
  const getStorageLogoRef = () => storage().ref("agentPicture");

  const getLogoUrl = async () => {
    let agent;
    try {
      agent = await doGetAgent(authUser.uid);
    } catch (err) {
      return setLogoError(err.message);
    }

    return agent.val()?.avatarURL;
  };

  const handleSuccessLogoUpload = async avatarURL => {
    try {
      await doUpdateAgentAvatarURL(authUser.uid, { avatarURL });
    } catch (err) {
      return setLogoError(err.message);
    }

    return null;
  };

  return (
    <>
      <UploadAgentPhoto
        getStorageRef={getStorageLogoRef}
        getAvatarURL={getLogoUrl}
        handleSuccessUpload={handleSuccessLogoUpload}
      />

      {logoError && (
        <div className="error-wrapper">
          <p className="error">
            <img src={alert} alt="" />
            {logoError}
          </p>
        </div>
      )}
    </>
  );
};

export default AccountLogo;

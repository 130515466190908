import firebase from "firebase";
import firebaseConfig from "./firebaseConfig";
import "firebase/database";

const APPLE_AUTH_PROVIDER_URL = "apple.com";

firebase.initializeApp(firebaseConfig);
export const db = firebase.database();
export const auth = firebase.auth();
export const appleAuthProvider = new firebase.auth.OAuthProvider(APPLE_AUTH_PROVIDER_URL);
appleAuthProvider.addScope("name");
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const { storage } = firebase;

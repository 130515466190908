import { getCurrentUser } from "../firebase/auth";

// eslint-disable-next-line import/prefer-default-export
export const getHeaders = async () => {
  const token = await getCurrentUser()?.getIdToken();

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

// import { GoogleLoginButton } from "react-social-login-buttons";
import React from "react";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import * as db from "../firebase/db";
import * as routes from "../constants/routes";
import { doSignInWithApple, doSignInWithGoogle, getCurrentUser } from "../firebase/auth";

import GoogleLoginButton from "./GoogleLoginButton";
import AppleLoginButton from "./AppleLoginButton";

const SocialLogin = ({ setError, rememberMe }) => {
  const history = useHistory();

  const appleLogin = async () => {
    try {
      const authUser = await doSignInWithApple();
      const createdUser = await db.doGetUserValue(authUser.user.uid);
      const createdAgent = await db.doGetAgent(authUser.user.uid);
      if (!createdUser.val()) {
        await db.doCreateUser(authUser.user.uid, {
          username: authUser.user.displayName,
          email: authUser.user.email,
          rememberMe,
        });
      } else {
        await db.doUpdateUser(authUser.user.uid, { rememberMe });
      }
      if (!createdAgent.val()) {
        await db.doCreateAgent(authUser.user.uid, {
          name: "",
          title: "",
          businessName: "",
          address: "",
          email: "",
          phone: "",
          avatarURL: "",
        });
      }
    } catch (err) {
      return setError(err?.message);
    } finally {
      const currentUser = getCurrentUser();
      if (currentUser) {
        history.push(routes.TOURS);
      }
    }

    return true;
  };

  const googleLogin = async () => {
    try {
      const authUser = await doSignInWithGoogle();
      const currentUser = getCurrentUser();
      const createdAgent = await db.doGetAgent(authUser.user.uid);
      const createdUser = await db.doGetUserValue(authUser.user.uid);
      if (!createdUser.val()) {
        await db.doCreateUser(authUser.user.uid, {
          username: authUser.user.displayName,
          email: currentUser.providerData[0].email,
          rememberMe,
        });
      } else {
        await db.doUpdateUser(authUser.user.uid, { rememberMe });
      }
      if (!createdAgent.val()) {
        await db.doCreateAgent(authUser.user.uid, {
          name: "",
          title: "",
          businessName: "",
          address: "",
          email: "",
          phone: "",
          avatarURL: "",
        });
      }
    } catch (err) {
      return setError(err?.message);
    } finally {
      const currentUser = getCurrentUser();
      if (currentUser) {
        history.push(routes.TOURS);
      }
    }

    return true;
  };

  return (
    <>
      <GoogleLoginButton onClick={googleLogin} />
      <AppleLoginButton onClick={appleLogin} />
    </>
  );
};

SocialLogin.defaultProps = {
  rememberMe: null,
};

SocialLogin.propTypes = {
  setError: PropTypes.func.isRequired,
  rememberMe: PropTypes.bool,
};

export default SocialLogin;

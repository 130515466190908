import React from "react";

const Page404 = () => {
  return (
    <div className="container-404">
      <h1 className="title-404">
        4<span>0</span>4
      </h1>
      <div className="subtitle-404">We are sorry, but the page you requested wasn&apos;t found.</div>
    </div>
  );
};

export default Page404;

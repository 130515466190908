import React from "react";
import * as PropTypes from "prop-types";
import { useField } from "formik";
import classNames from "classnames";

const TextField = ({ label, generalClassname, required, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <div className={classNames("text-field", generalClassname)}>
        <label htmlFor={label}>
          {label}
          {required ? <span className="required">*</span> : null}{" "}
        </label>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <div className="text-field__input">
          <input id={label} {...field} {...props} />
          {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        </div>
      </div>
    </>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  generalClassname: PropTypes.string,
  required: PropTypes.bool,
};

TextField.defaultProps = {
  label: undefined,
  generalClassname: "",
  required: false,
};

export default TextField;

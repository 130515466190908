import React from "react";
import * as PropTypes from "prop-types";

import Stepper from "./Stepper";
import TourImageUploader from "./TourImageUploader";

const TourUploadImages = ({ setSave }) => {
  return (
    <>
      <Stepper currentStep={1} />
      <TourImageUploader setSave={setSave} />
    </>
  );
};

TourUploadImages.defaultProps = {
  setSave: () => undefined,
};

TourUploadImages.propTypes = {
  setSave: PropTypes.func,
};

export default TourUploadImages;

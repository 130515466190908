import React, { useState } from "react";
import LoginForm from "../components/SignInForm";
import SocialLogin from "../components/SocialLogin";
import LoginWrapper from "../components/LoginWrapper";

const SignIn = () => {
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  return (
    <LoginWrapper title="Log In" error={error} page="signIn">
      <LoginForm setError={setError} withError={Boolean(error)} setRememberMe={setRememberMe} rememberMe={rememberMe} />

      <div className="sign-in-with">
        <p>
          <span>or log in with</span>
        </p>
      </div>

      <SocialLogin setError={setError} setRememberMe={setRememberMe} rememberMe={rememberMe} />
    </LoginWrapper>
  );
};

export default SignIn;

import React, { useState } from "react";
import EmailUpdateForm from "../components/EmailUpdateForm";
import LoginWrapper from "../components/LoginWrapper";

const EmailUpdate = () => {
  const [error, setError] = useState("");

  return (
    <LoginWrapper error={error} page="emailUpdate">
      <EmailUpdateForm setError={setError} />
    </LoginWrapper>
  );
};

export default EmailUpdate;

import React from "react";
import * as PropTypes from "prop-types";
import { useField } from "formik";
import classNames from "classnames";
import { ReactComponent as MinusIcon } from "../../assets/img/minus-circle.svg";
import { ReactComponent as PlusIcon } from "../../assets/img/plus-circle.svg";

const Counter = ({ label, generalClassname, required, min, max, defaultValue, ...props }) => {
  // eslint-disable-next-line react/destructuring-assignment,react/prop-types
  const [field, meta, helpers] = useField(props.name);

  const { value: valueString } = meta;
  const value = Number(valueString);
  const { setValue } = helpers;

  const handleDecreaseCount = () => {
    if (value > min) {
      setValue(value - 1);
    }
  };

  const handleIncreaseCount = () => {
    if (value < max) {
      setValue(value + 1);
    }
  };

  return (
    <div className={classNames("counter-field", generalClassname)}>
      <label htmlFor={label}>
        {label}
        {required ? <span className="required">*</span> : null}{" "}
      </label>
      <div className="range-filter">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <div className="counter">
          <button type="button" className="decrease" onClick={handleDecreaseCount}>
            <span>
              <MinusIcon />
            </span>
          </button>
          <div className="current-value">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input id={label} {...field} {...props} value={value} disabled />
          </div>
          <button type="button" className="increase" onClick={handleIncreaseCount}>
            <span>
              <PlusIcon />
            </span>
          </button>
        </div>
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>
    </div>
  );
};

Counter.propTypes = {
  label: PropTypes.string,
  generalClassname: PropTypes.string,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  defaultValue: PropTypes.number,
};

Counter.defaultProps = {
  label: "",
  generalClassname: "",
  required: false,
  defaultValue: 1,
  min: 0,
  max: 10,
};

export default Counter;

import React from "react";
import * as PropTypes from "prop-types";

import Modal from "../Modal";

import iconCancel from "../../assets/img/icons/icon-cancel-modal.svg";

const ModalDeletePin = ({ onToggle, onConfirmClick }) => {
  return (
    <Modal onToggle={onToggle} title="Delete Pin" size="xs">
      <div className="modal-default">
        <img src={iconCancel} className="modal-default__icon" alt="" />

        <p>Are you sure you want to delete this Pin?</p>

        <button type="button" className="custom_button__light custom_button-sm-width" onClick={onConfirmClick}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

ModalDeletePin.propTypes = {
  onToggle: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
};

export default ModalDeletePin;

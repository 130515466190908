import React, { useEffect, useState } from "react";

import * as PropTypes from "prop-types";
import { getCurrentUser } from "../firebase/auth";

import AccountInfoForm from "./AccountInfoForm";
import PasswordUpdateForm from "./PasswordUpdateForm";
import AccountDeactivation from "./AccountDeactivation";

const DEFAULT_CURRENT_USER = { displayName: "", email: "" };
const DEFAULT_IS_EDIT_MODE = {
  displayName: "",
  displayNameError: "",
};

const AccountView = ({ handleConfirmUserName }) => {
  // eslint-disable-next-line no-unused-vars
  const [currentUser, setCurrentUser] = useState(DEFAULT_CURRENT_USER);
  // eslint-disable-next-line no-unused-vars
  const [accountState, setAccountState] = useState(DEFAULT_IS_EDIT_MODE);

  useEffect(() => {
    const user = getCurrentUser();
    const { displayName, email } = user;
    setCurrentUser({ displayName, email });
    setAccountState(prevAccountState => ({ ...prevAccountState, displayName }));
  }, []);

  return (
    <div className="account-info">
      <AccountInfoForm handleConfirmUserName={handleConfirmUserName} />
      <PasswordUpdateForm />
      <AccountDeactivation />
    </div>
  );
};

AccountView.propTypes = {
  handleConfirmUserName: PropTypes.func.isRequired,
};

export default AccountView;

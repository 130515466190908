import React, { useState } from "react";
import PasswordForgetForm from "../components/PasswordForgetForm";
import LoginWrapper from "../components/LoginWrapper";

const PasswordForget = () => {
  const [error, setError] = useState("");

  return (
    <LoginWrapper title="Enter your email to restore password" titleBreak error={error} page="signIn">
      <PasswordForgetForm setError={setError} />
    </LoginWrapper>
  );
};

export default PasswordForget;

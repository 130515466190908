import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import * as PropTypes from "prop-types";
import * as routes from "../constants/routes";

import HeaderMain from "../components/HeaderMain";
import PageWrapper from "../components/PageWrapper";
import TourCreateForm from "../components/TourCreateForm";
import TourUploadImages from "../components/TourUploadImages";
import TourLinkRooms from "../components/TourLinkRooms";
import TourAnalytics from "../components/TourAnalytics";
import HeaderCustom from "../components/HeaderCustom";

import checkMobile from "../hooks/checkMobile";

import { doRemoveTour, doArchiveTour, doGetToursByID } from "../firebase/db";
import { getCurrentUser } from "../firebase/auth";

import arrowLeft from "../assets/img/icons/icon-arrow-left-blue.svg";
import iconTrash from "../assets/img/icons/icon-trash.svg";
import iconNotAllow from "../assets/img/icons/icon-not-allowed.svg";
import ModalDeleteTour from "../components/modals/ModalDeleteTour";
import ModalArchiveTour from "../components/modals/ModalArchiveTour";

const RENDER_TYPE = {
  tour: "tour",
  loading: "loading",
};

const checkIsUserOwner = async (currentUserID, currentTourId, isPublicTour) => {
  const fetchedTour = await doGetToursByID(isPublicTour ? "public" : currentUserID, currentTourId);
  const tour = await fetchedTour.val();

  if (tour?.ownerId === currentUserID) {
    return true;
  }

  return false;
};

const TourEdit = () => {
  const isMobile = checkMobile(767);
  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const { tourId } = useParams();

  const authUser = getCurrentUser();

  const tab = search.get("tab") || "info";

  const [modalArchiveVisible, setModalArchiveVisible] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [renderType, setRenderType] = useState(RENDER_TYPE.loading);
  const { uid } = authUser;
  const isPublic = search.get("public");

  const canRender = useCallback(async () => {
    const isUserOwner = await checkIsUserOwner(uid, tourId, isPublic);
    if (isUserOwner) {
      setRenderType(RENDER_TYPE.tour);
    } else {
      history.push(routes.PAGE_NOT_FOUND);
    }
  }, [uid, tourId, isPublic, history]);

  useEffect(() => {
    canRender();
  }, [canRender]);

  const handleTabClick = clickedTab => {
    search.set("tab", clickedTab);

    history.push({ pathname: location.pathname, search: search.toString() });
  };

  const onArchiveTourClick = async () => {
    try {
      // TODO Yevhenii: add modal to confirm deletion
      await doArchiveTour(authUser.uid, tourId, search.get("public"));
      history.push(routes.TOURS);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const onDeleteTourClick = async () => {
    try {
      // TODO Yevhenii: add modal to confirm deletion
      await doRemoveTour(search.get("public") ? "public" : authUser.uid, tourId);
      history.push(routes.TOURS);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return renderType === RENDER_TYPE.tour ? (
    <>
      {isMobile ? (
        <HeaderCustom onClick={() => history.push(routes.TOURS)} title="Back to all tours" />
      ) : (
        <HeaderMain />
      )}

      <PageWrapper>
        {!isMobile && (
          <div className="edit-tour-header">
            <div className="page-back-link">
              <a href={routes.TOURS}>
                {" "}
                <img src={arrowLeft} alt="" />
                <span>BACK TO ALL TOURS</span>
              </a>
            </div>

            <div className="edit-tour-buttons">
              <button className="btn-archive-tour" type="button" onClick={() => setModalArchiveVisible(true)}>
                <img src={iconNotAllow} alt="" />
                <span>Archive</span>
              </button>

              <button className="btn-delete-tour" type="button" onClick={() => setModalDeleteVisible(true)}>
                <img src={iconTrash} alt="" />
                <span>Delete</span>
              </button>
            </div>
          </div>
        )}

        <div className="edit-tour-wrapper">
          <div className="edit-tour-wrapper__title">Edit your tour</div>
          <div className="edit-tabs-header">
            <button
              className={`${tab === "info" ? "is-active" : ""} settings-tabs-button`}
              type="button"
              onClick={() => handleTabClick("info")}
            >
              Tour info
            </button>
            <button
              className={`${tab === "images" ? "is-active" : ""} settings-tabs-button`}
              type="button"
              onClick={() => handleTabClick("images")}
            >
              <span>Tour&#32;</span>
              images
            </button>
            <button
              className={`${tab === "preview" ? "is-active" : ""} settings-tabs-button`}
              type="button"
              onClick={() => handleTabClick("preview")}
            >
              <span>Tour&#32;</span>
              preview
            </button>
            <button
              className={`${tab === "analytics" ? "is-active" : ""} settings-tabs-button`}
              type="button"
              onClick={() => handleTabClick("analytics")}
            >
              Analytics
            </button>
          </div>

          <div className="edit-tour__content">
            {tab === "info" && (
              <>
                <TourCreateForm />
                {isMobile && (
                  <div className="edit-tour-buttons edit-tour-buttons__mobile">
                    <button className="btn-archive-tour" type="button" onClick={() => setModalArchiveVisible(true)}>
                      <img src={iconNotAllow} alt="" />
                      <span>Archive</span>
                    </button>

                    <button className="btn-delete-tour" type="button" onClick={() => setModalDeleteVisible(true)}>
                      <img src={iconTrash} alt="" />
                      <span>Delete</span>
                    </button>
                  </div>
                )}
              </>
            )}
            {tab === "images" && <TourUploadImages />}
            {tab === "preview" && <TourLinkRooms />}
            {tab === "analytics" && <TourAnalytics />}
          </div>
        </div>

        {modalArchiveVisible && (
          <ModalArchiveTour onToggle={() => setModalArchiveVisible(false)} onConfirm={onArchiveTourClick} />
        )}
        {modalDeleteVisible && (
          <ModalDeleteTour onToggle={() => setModalDeleteVisible(false)} onConfirm={onDeleteTourClick} />
        )}
      </PageWrapper>
    </>
  ) : null;
};

TourEdit.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
};

export default TourEdit;

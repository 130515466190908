import React from "react";
import * as PropTypes from "prop-types";

import Modal from "../Modal";
import SliderVertical from "../SliderVertical";

const ModalSliderVertical = ({ onToggle, ...props }) => {
  return (
    <Modal onToggle={onToggle} title="Link Room to pin" type="link-room">
      <SliderVertical {...props} />
    </Modal>
  );
};

ModalSliderVertical.propTypes = {
  ...SliderVertical.propTypes,
  onToggle: PropTypes.func.isRequired,
};

ModalSliderVertical.defaultProps = {
  ...SliderVertical.defaultProps,
};

export default ModalSliderVertical;

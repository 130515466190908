import React from "react";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import arrowLeft from "../assets/img/icons/icon-arrow-left.svg";

const HeaderCustom = ({ title, onClick }) => {
  const history = useHistory();

  const onBackClick = () => history.goBack();

  return (
    <button onClick={onClick || onBackClick} className="acw-header" type="button">
      <img src={arrowLeft} alt="" />

      <span>{title}</span>

      <div />
    </button>
  );
};

HeaderCustom.defaultProps = {
  onClick: null,
};

HeaderCustom.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default HeaderCustom;

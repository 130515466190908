import React, { useState, useCallback, useEffect } from "react";
import * as PropTypes from "prop-types";
import MinMaxFilter, { useMinMaxFilter } from "./MinMaxFilter";

import { FILTER_PARAMS } from "../constants/SortFilterParams";
import RangeFilter, { useRangeFilter } from "./RangeFilter";
import iconClose from "../assets/img/icons/icon-close-bold.svg";
import { MIN_VALUE, MAX_VALUE } from "../constants/filter";

const useFixedScroll = isEnabled => {
  const blockSroll = useCallback(() => {
    document.querySelector("body").classList.add("filter-is-opened");
  }, []);

  const unblockScroll = useCallback(() => {
    document.querySelector("body").classList.remove("filter-is-opened");
  }, []);

  useEffect(() => {
    if (isEnabled) {
      blockSroll();
    } else {
      unblockScroll();
    }

    return () => {
      unblockScroll();
    };
  }, [blockSroll, isEnabled, unblockScroll]);
};

const SearchFilterMobile = ({ isVisible, toggleFilter, minArea, maxArea, handleSave: handleSubmit, handleDelete }) => {
  const [bedroomValue, setBedroomValue] = useState();
  const [bathroomValue, setBathroomValue] = useState();
  const [squareValue, setSquareValue] = useState();

  useFixedScroll(isVisible);

  const {
    minCount: minCountBedroom,
    setMinCount: setMinCountBedroom,
    maxCount: maxCountBedroom,
    setMaxCount: setMaxCountBedroom,
    clear: clearBedroom,
  } = useMinMaxFilter(1, 1, FILTER_PARAMS.BEDROOMS);
  const {
    minCount: minCountBathroom,
    setMinCount: setMinCountBathroom,
    maxCount: maxCountBathroom,
    setMaxCount: setMaxCountBathroom,
    clear: clearBathroom,
  } = useMinMaxFilter(1, 1, FILTER_PARAMS.BATHROOM);

  const {
    currentValues: currentValuesSquare,
    setCurrentValues: setCurrentValuesSquare,
    clear: clearSquare,
  } = useRangeFilter(MIN_VALUE, MAX_VALUE, FILTER_PARAMS.SQUARE);

  const handleBedroomClear = useCallback(() => {
    setBedroomValue();
    clearBedroom();
  }, [clearBedroom]);

  const handleBathroomClear = useCallback(() => {
    setBathroomValue();
    clearBathroom();
  }, [clearBathroom]);

  const handleSquareClear = useCallback(() => {
    setSquareValue();
    clearSquare();
  }, [clearSquare]);

  const handleSave = useCallback(() => {
    handleSubmit(bedroomValue, bathroomValue, squareValue);
  }, [bathroomValue, bedroomValue, handleSubmit, squareValue]);

  const handleClearSearch = useCallback(() => {
    handleBedroomClear();
    handleBathroomClear();
    handleSquareClear();
    handleDelete();
  }, [handleBathroomClear, handleBedroomClear, handleDelete, handleSquareClear]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="search-filter-mobile">
      <div>
        <div className="search-filter-mobile__header">
          <span>Filter</span>
          <button type="button" onClick={toggleFilter}>
            <img src={iconClose} alt="" />
          </button>
        </div>
        <div className="search-filter-mobile__body">
          <div className="search-filter-mobile__title">Bedrooms</div>
          <MinMaxFilter
            name={FILTER_PARAMS.BEDROOMS}
            title="Bedrooms"
            placeholder="Add number of bedrooms"
            min={1}
            max={4}
            handleChange={setBedroomValue}
            handleClear={handleBedroomClear}
            isVisibleOverride
            minCount={minCountBedroom}
            setMinCount={setMinCountBedroom}
            maxCount={maxCountBedroom}
            setMaxCount={setMaxCountBedroom}
            largeIcons
          />

          <div className="search-filter-mobile__title">Bathrooms</div>
          <MinMaxFilter
            name={FILTER_PARAMS.BATHROOM}
            title="Bathroom"
            placeholder="Add number of bathrooms"
            min={1}
            max={5}
            handleChange={setBathroomValue}
            handleClear={handleBathroomClear}
            isVisibleOverride
            minCount={minCountBathroom}
            setMinCount={setMinCountBathroom}
            maxCount={maxCountBathroom}
            setMaxCount={setMaxCountBathroom}
            largeIcons
          />

          <div className="search-filter-mobile__title">Square</div>
          {maxArea > minArea && (
            <RangeFilter
              name={FILTER_PARAMS.SQUARE}
              title="Square"
              placeholder="sq ft"
              min={minArea}
              max={maxArea}
              handleChange={setSquareValue}
              handleClear={handleSquareClear}
              isVisibleOverride
              currentValues={currentValuesSquare}
              setCurrentValues={setCurrentValuesSquare}
            />
          )}
        </div>
      </div>

      <div className="search-filter-mobile__footer">
        <button onClick={handleSave} type="button" className="custom_button__light">
          Save
        </button>
        <button
          onClick={handleClearSearch}
          type="button"
          className="custom_button__light custom_button__light--outlined"
        >
          Clear
        </button>
      </div>
    </div>
  );
};

SearchFilterMobile.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  minArea: PropTypes.number,
  maxArea: PropTypes.number,
  handleSave: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

SearchFilterMobile.defaultProps = {
  minArea: 0,
  maxArea: 1,
};

export default SearchFilterMobile;

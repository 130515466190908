import qs from "qs";
import React, { useEffect, useState, useMemo } from "react";
import { Form, Formik } from "formik";
import { useHistory, useParams, useLocation, Prompt } from "react-router-dom";
import * as Yup from "yup";
import { toast, Slide } from "react-toastify";

import * as routes from "../constants/routes";
import { getCurrentUser } from "../firebase/auth";
import allCountries from "../constants/countries";
import { PUBLIC_TOUR } from "../constants/searchParam";
import { doCreatePublicTour, doCreateToursByUser, doGetToursByID, doEditTour } from "../firebase/db";

import Stepper from "./Stepper";
import TextField from "./FormComponents/TextField";
import CheckboxField from "./FormComponents/CheckboxField";
import SelectField from "./FormComponents/SelectField";
import Counter from "./FormComponents/Counter";
import SubscriptionFlow from "../components/SubscriptionFlow";
import ModalNumberToursLimited from "./modals/ModalNumberToursLimited";
import useSubscriptionType from "../hooks/useSubscriptionType";
import useCanTourUpdate from "../hooks/useCanTourUpdate";

const validationSchema = Yup.object().shape({
  address: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  country: Yup.object().required("Required"),
  city: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
});

const TourCreateForm = () => {
  const user = getCurrentUser();
  const { tourId } = useParams();
  const [error, setError] = useState("");
  const [visibleLimitedAlert, setVisibleLimitedAlert] = useState(false);

  const [countries, setCountries] = useState([]);
  const [tour, setTour] = useState();
  const [resetedForm, setResetedForm] = useState(() => () => undefined);
  const location = useLocation();
  const { isLoading } = useSubscriptionType();
  const { canAddActiveTour } = useCanTourUpdate();

  const searchParams = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);

  useEffect(() => {
    setCountries(allCountries.map(({ country }) => ({ label: country, value: country })));
  }, []);

  useEffect(() => {
    let isMounted = true;

    const getUserTour = async () => {
      let fetchedTours;

      try {
        fetchedTours = await doGetToursByID(searchParams[PUBLIC_TOUR] ? "public" : user.uid, tourId);
      } catch (err) {
        // return setError(err?.message);

        // eslint-disable-next-line no-console
        console.log("err", err?.message);
      }

      if (isMounted) {
        setTour(fetchedTours.val());
      }
    };

    if (tourId) {
      getUserTour();
    }

    return () => {
      isMounted = false;
    };
  }, [searchParams, tourId, user.uid]);

  const history = useHistory();

  const onToggleLimitedAlert = () => {
    setVisibleLimitedAlert(false);
  };

  const handleSubmit = async values => {
    const serialisedValues = {
      ...values,
      name: `${values.address ? `${`${values.address}, `}` : ""} ${values.unit ? `${`${values.unit}, `}` : ""} ${
        values.city ? `${`${values.city}`}` : ""
      }${values.state ? `${`, ${values.state}`}` : ""}`,
      country: values.country ? values.country.value : null,
      created_at: Date.now(),
    };
    let result;
    try {
      const authUser = getCurrentUser();
      const newTour = {
        ...serialisedValues,
        ownerId: authUser.uid,
        isTopBarHidden: searchParams[PUBLIC_TOUR] ? false : null,
      };

      if (!location.pathname.includes("edit")) {
        if (serialisedValues.publicTour) {
          result = await doCreatePublicTour(newTour);
        } else {
          result = await doCreateToursByUser(authUser.uid, newTour);
        }
      } else {
        await doEditTour(authUser.uid, tourId, tour.publicTour, newTour);
      }
    } catch (err) {
      return setError(err?.message);
    }

    if (location.pathname.includes("edit")) {
      const newTour = { ...tour, ...serialisedValues };
      setTour(newTour);
      resetedForm({
        values: {
          address: "",
          unit: "",
          city: "",
          state: "",
          zip: "",
          MLSUrl: "",
          videoUrl: "",
          area: "",
          price: "",
          bathroom_count: 0,
          bedroom_count: 0,
          publicTour: false,
          totalViews: 0,
          ...newTour,
          country: newTour
            ? { label: newTour.country, value: newTour.country }
            : { label: "United States", value: "United States" },
        },
      });

      const search = new URLSearchParams(location.search);

      if (serialisedValues.publicTour) {
        search.set(PUBLIC_TOUR, true);
      } else {
        search.delete(PUBLIC_TOUR);
      }

      toast.success("Changes have been saved", {
        position: "top-right",
        autoClose: 3000,
        transition: Slide,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      return history.replace({
        pathname: location.pathname,
        search: search.toString(),
      });
    }

    if (serialisedValues.publicTour) {
      return history.push(`${routes.TOUR_CREATE}/${result.key}/images?${PUBLIC_TOUR}=true`);
    }

    return history.push(`${routes.TOUR_CREATE}/${result.key}/images`);
  };

  const [shownModal, setShownModal] = useState(false);

  const toggleModal = () => setShownModal(!shownModal);

  const checkSubscription = values => {
    if (canAddActiveTour) {
      tryHandleSubmit(values);
    } else {
      toggleModal();
    }
  };

  const tryHandleSubmit = async values => {
    const isEdit = location.pathname.includes("edit");
    const isPublicTour = Boolean(values?.publicTour);
    const isPrevTourIsPublic = Boolean(tour?.publicTour);
    const isCreatePublicTour = !isEdit && isPublicTour;

    if (
      canAddActiveTour ||
      isCreatePublicTour ||
      (isEdit && !isPrevTourIsPublic) ||
      (isEdit && isPrevTourIsPublic && isPublicTour)
    ) {
      await handleSubmit(values);
    } else {
      setVisibleLimitedAlert(true);
    }
  };

  if (tourId && !tour) {
    return null;
  }

  return (
    <>
      <SubscriptionFlow onSubscribeModalClose={() => toggleModal(false)} isSubscribeModalVisible={shownModal} />
      <Stepper currentStep={0} />
      <div className="tour-wrapper__title">Add tour details</div>
      {error && <p className="error">{error}</p>}
      <Formik
        initialValues={{
          address: "",
          unit: "",
          city: "",
          state: "",
          zip: "",
          MLSUrl: "",
          videoUrl: "",
          area: "",
          price: "",
          bathroom_count: 0,
          bedroom_count: 0,
          totalViews: 0,
          publicTour: false,
          ...tour,
          country: tour
            ? { label: tour.country, value: tour.country }
            : { label: "United States", value: "United States" },
        }}
        validationSchema={validationSchema}
        onSubmit={checkSubscription}
      >
        {({ isValid, dirty, resetForm }) => {
          return (
            <Form>
              {location.pathname.includes("edit") && (
                <Prompt
                  when={dirty}
                  message={() =>
                    "Do you want to leave the tab with unsaved changes? All data will go to the previous state in this case."
                  }
                />
              )}

              {setResetedForm(() => resetForm)}
              <div className="create-tour__form">
                <div className="create-tour__first-column">
                  <div className="flex-row">
                    <TextField
                      generalClassname="long"
                      name="address"
                      type="text"
                      label="Address"
                      placeholder="Tour address"
                      required
                    />
                    <TextField
                      generalClassname="short"
                      name="unit"
                      type="text"
                      label="Unit"
                      placeholder="Unit Number"
                    />
                  </div>
                  <SelectField
                    name="country"
                    placeholder="Choose country"
                    id="country"
                    label="Country"
                    options={countries}
                    required
                    // value={countries && countries[0]}
                  />

                  <div className="flex-row">
                    <TextField
                      generalClassname="short"
                      name="city"
                      type="text"
                      label="City"
                      placeholder="Your City"
                      required
                    />
                    <TextField
                      generalClassname="short"
                      name="state"
                      type="text"
                      label="State"
                      placeholder="Your State"
                    />
                  </div>

                  <TextField
                    generalClassname="short"
                    name="zip"
                    type="number"
                    label="Zip/Postal"
                    placeholder="Zip Code"
                  />

                  <TextField name="MLSUrl" type="text" label="MLS url" placeholder="www.compass.com" />

                  <TextField
                    name="videoUrl"
                    type="text"
                    label="Video walkthrough"
                    placeholder="youtube.com, vimeo.com"
                  />
                </div>
                <div className="create-tour__second-column">
                  <TextField generalClassname="short" name="price" label="Price, $" placeholder="0" type="number" />
                  <TextField generalClassname="short" name="area" label="Square, sq ft" placeholder="0" type="number" />
                  <Counter name="bedroom_count" label="Bedrooms" min={0} max={10} defaultValue={0} />
                  <Counter name="bathroom_count" label="Bathrooms" min={0} max={10} defaultValue={0} />

                  <div className="checkbox checkbox-row">
                    <CheckboxField name="publicTour" label="Community Tour" />
                  </div>
                </div>
              </div>

              <div className="tour-wrapper__footer">
                <div className="ghost" />
                <div className="tour-wrapper__buttons">
                  <button
                    disabled={isLoading}
                    className="custom_button-nav custom_button-nav__light custom_button-nav-outlined"
                    type="button"
                    onClick={() => history.push(routes.TOURS)}
                  >
                    <span>BACK</span>
                  </button>
                  <button
                    className="custom_button-nav custom_button-nav__light"
                    type="submit"
                    disabled={!isValid || (!dirty && !tour) || (!tour && tourId) || isLoading}
                  >
                    <span>SAVE</span>
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {visibleLimitedAlert && <ModalNumberToursLimited onToggle={onToggleLimitedAlert} />}
    </>
  );
};

export default TourCreateForm;

import React, { useEffect, useState } from "react";
import FileUploader from "react-firebase-file-uploader";
import * as PropTypes from "prop-types";
import userIcon from "../assets/img/icons/icon-user.svg";
import cameraIcon from "../assets/img/icons/icon-camera.svg";

const DEFAULT_STATE = {
  avatar: "",
  isUploading: false,
  progress: 0,
  avatarURL: "",
};

const UploadProfilePhoto = ({ getStorageRef, getAvatarURL, handleSuccessUpload }) => {
  const [state, setState] = useState(DEFAULT_STATE);

  useEffect(() => {
    const setAvatar = async () => {
      const avatarURL = await getAvatarURL();
      if (avatarURL) {
        setState(prevAccountState => ({ ...prevAccountState, avatarURL }));
      }
    };
    setAvatar();
  }, [getAvatarURL]);

  const handleUploadStart = () => setState(prevState => ({ ...prevState, isUploading: true, progress: 0 }));
  const handleProgress = progress => setState(prevState => ({ ...prevState, progress }));
  const handleUploadError = () => {
    setState(prevState => ({ ...prevState, isUploading: false }));
  };
  const handleUploadSuccess = async filename => {
    setState(prevState => ({ ...prevState, avatar: filename, progress: 100, isUploading: false }));
    const downloadUrl = await getStorageRef().child(filename).getDownloadURL();
    await handleSuccessUpload(downloadUrl);
    setState(prevState => ({ ...prevState, avatarURL: downloadUrl }));
  };

  return (
    <div className="account-photo__wrapper">
      <div className={`account-photo ${state.avatarURL ? "" : "account-photo__placeholder"}`}>
        <img className="account-photo__img" src={state.avatarURL ? state.avatarURL : userIcon} alt="" />
        <div className="account-photo__camera">
          <img src={cameraIcon} alt="" />
        </div>
      </div>

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="account-photo-input">
        <FileUploader
          accept="image/*"
          name="avatar"
          randomizeFilename
          storageRef={getStorageRef()}
          onUploadStart={handleUploadStart}
          onUploadError={handleUploadError}
          onUploadSuccess={handleUploadSuccess}
          onProgress={handleProgress}
          hidden
        />
      </label>
    </div>
  );
};

UploadProfilePhoto.propTypes = {
  getStorageRef: PropTypes.func.isRequired,
  getAvatarURL: PropTypes.func.isRequired,
  handleSuccessUpload: PropTypes.func.isRequired,
};

export default UploadProfilePhoto;

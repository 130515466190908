import { v4 } from "uuid";
import moment from "moment";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";

import * as PropTypes from "prop-types";

import * as routes from "../constants/routes";

import ModalShareTour from "./modals/ModalShareTour";
import ModalDearchiveTour from "./modals/ModalDearchiveTour";

import checkMobile from "../hooks/checkMobile";

import { doGetToursByID, doDearchiveTour } from "../firebase/db";
import { getCurrentUser } from "../firebase/auth";

import { TOURS_CARD } from "../constants/pagination";
import { PUBLIC_TOUR } from "../constants/searchParam";

import noImageIcon from "../assets/img/no_image.jpg";
import iconClock from "../assets/img/icons/icon-clock.svg";
import iconShare from "../assets/img/icons/icon-share.svg";
import iconArea from "../assets/img/icons/icon-area.svg";
import iconBedroom from "../assets/img/icons/icon-bedroom.svg";
import iconBathroom from "../assets/img/icons/icon-bathroom.svg";
import iconEdit from "../assets/img/icons/icon-edit.svg";
import ModalNumberToursLimited from "./modals/ModalNumberToursLimited";
import useCanTourUpdate from "../hooks/useCanTourUpdate";

const escapeRegExp = string => {
  if (typeof string === "string") {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  return string;
};

const TourCard = ({
  wrapperClassName,
  dndEnabled,
  tourId,
  name,
  imageUrl,
  imagesUrls,
  tourView,
  bathroomCount,
  bedroomCount,
  area,
  created,
  totalViews,
  changeTourOrder,
  isLoading,
  ownerId,
  price,
}) => {
  const currentUser = getCurrentUser();
  const ref = useRef(null);
  const linkRef = useRef(null);
  const embedRef = useRef(null);
  const [visibleLimitedAlert, setVisibleLimitedAlert] = useState(false);
  const { canAddActiveTour } = useCanTourUpdate();
  const escapedImageUrl = useMemo(() => escapeRegExp(imageUrl), [imageUrl]);

  const isMobile = checkMobile(767);
  const [isVisibleSharePopup, setIsVisibleSharePopup] = useState(false);
  const [isVisibleDearchivePopup, setIsVisibleDearchivePopup] = useState(false);
  const [error, setError] = useState("");
  const [shareLinkTour, setShareLinkTour] = useState("");
  const [shareEmbedTour, setShareEmbedLinkTour] = useState("");

  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const renderName = () => {
    if (isMobile && name.length >= 30) {
      return `${name.substring(0, 30)}...`;
    }

    return name;
  };

  const dragConfig = useMemo(
    () => ({
      item: { type: TOURS_CARD, id: tourId },
      canDrag:
        dndEnabled && search.get("tab") !== "community" && (!search.get("sort") || search.get("sort") === "custom"),
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [dndEnabled, search, tourId]
  );

  const [{ isDragging }, connectDrag] = useDrag(dragConfig);
  const [, connectDrop] = useDrop({
    accept: TOURS_CARD,
    hover({ id: draggedId }) {
      if (draggedId !== tourId) {
        changeTourOrder(draggedId, tourId);
      }
    },
  });

  const history = useHistory();

  const handleClick = () => {
    if (window.location.href.includes("community-tours")) {
      window.location = shareLinkTour;

      return;
    }

    if (search.get("tab") === "community") {
      if (escapedImageUrl && ownerId !== currentUser?.uid) {
        window.location = shareLinkTour;
      }

      if (ownerId === currentUser?.uid) {
        history.push({ pathname: routes.TOUR_EDIT.replace(":tourId", tourId), search: `?${PUBLIC_TOUR}=true` });
      }

      return;
    }

    if (search.get("tab") === "archived") {
      return;
    }
    history.push(routes.TOUR_EDIT.replace(":tourId", tourId));
  };

  const currentUserId = currentUser?.uid;

  useEffect(() => {
    const getUserTour = async () => {
      let fetchedTours;
      try {
        fetchedTours = await doGetToursByID(search.get("tab") === "community" ? "public" : currentUserId, tourId);

        const tours = fetchedTours.val();
        const tourImagesUids = Object.keys(tours?.images || {});
        const link = `${window.location.origin}/tour/${(name || "tour")
          .trim()
          .replace(/\//g, "-")
          .replace(/,/g, "")
          .replace(/ +(?= )/g, "")
          .replace(/ /g, "-")}/${
          search.get("tab") === "community" || window.location.href.includes("community-tours")
            ? "public"
            : currentUserId
        }/${tourId}`;
        const embed = `<iframe width="560" height="315" src="${link}/${tourImagesUids[0]}" frameborder="0"></iframe>`;

        setShareLinkTour(link);
        setShareEmbedLinkTour(embed);
      } catch (err) {
        setError(err?.message);
      }
    };

    getUserTour();
  }, [currentUserId, search, tourId, name]);

  const opacity = isDragging ? 0 : 1;
  connectDrag(ref);
  connectDrop(ref);

  const renderPrice = () => (price ? <div className="tour-price">{`$${price}`}</div> : null);

  const handleShareClick = e => {
    e.stopPropagation();
    setIsVisibleSharePopup(true);
  };

  const onConfirmDearchive = () => {
    doDearchiveTour(currentUser?.uid, tourId);
  };

  const handleDearchiveClick = e => {
    if (canAddActiveTour) {
      e.stopPropagation();
      setIsVisibleDearchivePopup(true);
    } else {
      setVisibleLimitedAlert(true);
    }
  };

  const copyLinkToClipboard = e => {
    linkRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };

  const copyEmbedToClipboard = e => {
    embedRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };

  const renderOptionButton = () => {
    if (search.get("tab") === "archived") {
      return (
        <button type="button" className="btn-tour-dearchive" onClick={handleDearchiveClick}>
          Dearchive
        </button>
      );
    }

    if (escapedImageUrl) {
      return (
        <button
          type="button"
          className={`${escapedImageUrl ? "" : "disabled"} btn-tour-share`}
          onClick={handleShareClick}
        >
          <img src={iconShare} alt="" className="tour-share__icon" />
        </button>
      );
    }

    return null;
  };

  const onToggleLimitedAlert = () => {
    setVisibleLimitedAlert(false);
  };

  const renderTourItemClassName = () => {
    if (search.get("tab") === "archived") {
      return "tour-item tour-item__archived";
    }

    if (search.get("tab") === "community" && ownerId === currentUser?.uid) {
      return "tour-item tour-item__own";
    }

    if (search.get("tab") === "community" && !escapedImageUrl) {
      return "tour-item tour-item__disabled";
    }

    return "tour-item";
  };

  const daysInterval = moment(Date.now()).diff(created, "days");

  const renderDaysInterval = () => {
    if (search.get("tab") === "archived") {
      return null;
    }

    if (daysInterval === 0) {
      if (moment(Date.now()).format("D MMM") === moment(created).format("D MMM")) {
        return <span>• Today</span>;
      }

      return <span>• Yesterday</span>;
    }

    if (daysInterval === 1) {
      return <span>• Yesterday</span>;
    }

    return <span>{`• ${daysInterval} days ago`}</span>;
  };

  return (
    <>
      <div
        className={`${wrapperClassName} ${renderTourItemClassName()}`}
        ref={ref}
        style={{ opacity }}
        onClick={handleClick}
      >
        {isLoading ? (
          <div className="tour-image-container is-loading">
            <div className="tour-image" />
          </div>
        ) : (
          <div className={`${escapedImageUrl ? "" : "tour-image-container__empty"} tour-image-container`}>
            {renderPrice()}

            {search.get("tab") === "community" && ownerId === currentUser?.uid && (
              <img src={iconEdit} alt="" className="tour-edit__icon" />
            )}

            <div className="tour-views">
              <svg width="20" height="15" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.06228 2.31494C2.95256 2.31494 1.15086 3.61637 0.420898 5.45343C1.15086 7.2905 2.95256 8.59192 5.06228 8.59192C7.17199 8.59192 8.97369 7.2905 9.70365 5.45343C8.97369 3.61637 7.17199 2.31494 5.06228 2.31494ZM5.06276 7.54669C3.89819 7.54669 2.95304 6.60932 2.95304 5.45436C2.95304 4.29939 3.89819 3.36203 5.06276 3.36203C6.22732 3.36203 7.17247 4.29939 7.17247 5.45436C7.17247 6.60932 6.22732 7.54669 5.06276 7.54669ZM3.7963 5.45364C3.7963 4.75899 4.3617 4.19824 5.06213 4.19824C5.76255 4.19824 6.32796 4.75899 6.32796 5.45364C6.32796 6.14829 5.76255 6.70904 5.06213 6.70904C4.3617 6.70904 3.7963 6.14829 3.7963 5.45364Z"
                />
              </svg>

              {`${totalViews} ${totalViews === 1 ? "view" : "views"}`}
            </div>

            {escapedImageUrl ? (
              <div className="tour-image" style={{ backgroundImage: `url(${escapedImageUrl})` }} />
            ) : (
              <div className="tour-image tour-image__empty" style={{ backgroundImage: `url(${noImageIcon})` }} />
            )}
          </div>
        )}
        <div className="tour-info">
          {isLoading ? (
            <div className="tour-name is-loading">tour name default</div>
          ) : (
            <div className="tour-name">{renderName()}</div>
          )}

          <div className="tour-characteristics-container">
            {isLoading ? (
              <div className="appartments-conditions is-loading">
                <div className="tour-condition__row">
                  <img src={iconBedroom} alt="" />
                  1&nbsp;<span> bedroom</span>
                </div>

                <div className="tour-condition__row">
                  <img src={iconBathroom} alt="" />
                  1&nbsp;<span>bathroom</span>
                </div>

                <div className="tour-condition__row">
                  <img src={iconArea} alt="" />
                  20 sq ft
                </div>
              </div>
            ) : (
              <div className="appartments-conditions">
                {bedroomCount ? (
                  <div className="tour-condition__row">
                    <img src={iconBedroom} alt="" className="tour-condition__icon-bedroom" />
                    {bedroomCount}&nbsp;<span> bedroom</span>
                  </div>
                ) : undefined}
                {bathroomCount ? (
                  <div className="tour-condition__row">
                    <img src={iconBathroom} alt="" className="tour-condition__icon-bath" />
                    {bathroomCount}&nbsp;<span>bathroom</span>
                  </div>
                ) : undefined}
                {area ? (
                  <div className="tour-condition__row">
                    <img src={iconArea} alt="" className="tour-condition__icon-area" />
                    {area} sq ft
                  </div>
                ) : undefined}
              </div>
            )}

            <div className="tour-additional__images">
              {tourView === "list" &&
                !isMobile &&
                imagesUrls.map(imgUrl => (
                  <div key={v4()} className="tour-image" style={{ backgroundImage: `url(${imgUrl})` }} />
                ))}
            </div>

            {isLoading ? (
              <div className="tour-condition__row is-loading">
                <div>
                  <img src={iconClock} alt="" />
                  Jumy 4,&nbsp;2020&nbsp;<span> • few days ago</span>
                </div>
                <img src={iconShare} alt="" />
              </div>
            ) : (
              <>
                <div className="tour-condition__row">
                  <div className="tour-condition__row">
                    <img src={iconClock} alt="" />
                    {isMobile ? moment(created).format("MMM D, YYYY") : moment(created).format("MMMM D, YYYY")}&nbsp;
                    {renderDaysInterval()}
                  </div>
                  {renderOptionButton()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {isVisibleSharePopup && (
        <ModalShareTour
          onToggle={() => setIsVisibleSharePopup(false)}
          tourLink={shareLinkTour}
          linkRef={linkRef}
          embedLink={shareEmbedTour}
          embedRef={embedRef}
          onCopyLink={copyLinkToClipboard}
          onCopyEmbed={copyEmbedToClipboard}
          error={error}
        />
      )}

      {isVisibleDearchivePopup && (
        <ModalDearchiveTour onToggle={() => setIsVisibleDearchivePopup(false)} onConfirm={onConfirmDearchive} />
      )}

      {visibleLimitedAlert && <ModalNumberToursLimited onToggle={onToggleLimitedAlert} />}
    </>
  );
};

TourCard.propTypes = {
  wrapperClassName: PropTypes.string,
  dndEnabled: PropTypes.bool,
  tourId: PropTypes.string.isRequired,
  ownerId: PropTypes.string,
  imageUrl: PropTypes.string,
  imagesUrls: PropTypes.arrayOf(PropTypes.string),
  tourView: PropTypes.string.isRequired,
  name: PropTypes.string,
  bathroomCount: PropTypes.number,
  bedroomCount: PropTypes.number,
  area: PropTypes.number,
  created: PropTypes.number,
  views: PropTypes.shape({}),
  totalViews: PropTypes.number,
  changeTourOrder: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  price: PropTypes.number,
};

TourCard.defaultProps = {
  wrapperClassName: null,
  dndEnabled: true,
  ownerId: null,
  imageUrl: null,
  imagesUrls: [],
  name: "",
  bathroomCount: 0,
  bedroomCount: 0,
  area: 0,
  created: null,
  isLoading: false,
  price: 0,
  views: { time: 0 },
  totalViews: 0,
};

export default TourCard;

import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import * as routes from "../constants/routes";

import PageWrapper from "../components/PageWrapper";
import HeaderMain from "../components/HeaderMain";
import Footer from "../components/Footer";

import arrowRight from "../assets/img/icons/icon-arrow-right.svg";
import useScrollTopOnHistory from "../hooks/useScrollTopOnHistory";

import useSubscriptionType from "../hooks/useSubscriptionType";
import { getCurrentUser } from "../firebase/auth";
import { doGetAgent } from "../firebase/db";

const AccountMobileNav = () => {
  useScrollTopOnHistory();

  const { uid } = getCurrentUser();
  const { type } = useSubscriptionType();
  const [avatar, setAvatar] = useState(null);

  const canAddLogoCompany = type === "unlimited" || type === "lite" || avatar?.length;

  const loadCompanyLogo = useCallback(async () => {
    const { avatarURL } = (await doGetAgent(uid)).val();
    if (avatarURL) {
      setAvatar(avatarURL);
    }
  }, [uid]);

  useEffect(() => {
    loadCompanyLogo();
  }, [loadCompanyLogo]);

  return (
    <>
      <HeaderMain />

      <PageWrapper>
        <div className="settings-mobile__nav">
          <p className="settings-mobile__title">Account Info</p>
          <NavLink to={routes.ACCOUNT_INFO} className="settings-mobile__link">
            Account Information
            <img src={arrowRight} alt="" />
          </NavLink>

          {canAddLogoCompany && (
            <NavLink to={routes.ACCOUNT_COMPANY_LOGO} className="settings-mobile__link">
              Company Logo
              <img src={arrowRight} alt="" />
            </NavLink>
          )}

          <NavLink to={routes.ACCOUNT_CHANGE_PASSWORD} className="settings-mobile__link">
            Change Password
            <img src={arrowRight} alt="" />
          </NavLink>

          <NavLink to={routes.ACCOUNT_DEACTIVATION} className="settings-mobile__link">
            Account Deactivation
            <img src={arrowRight} alt="" />
          </NavLink>

          <p className="settings-mobile__title">Agent Info</p>
          <NavLink to={routes.AGENT_INFO} className="settings-mobile__link">
            Agent Information
            <img src={arrowRight} alt="" />
          </NavLink>

          <p className="settings-mobile__title">Subscription</p>
          <NavLink to={routes.SUBSCRIPTION_LEVEL} className="settings-mobile__link">
            Subscription level
            <img src={arrowRight} alt="" />
          </NavLink>

          <NavLink to={routes.SUBSCRIPTION_PAYMENT_METHOD} className="settings-mobile__link">
            Payment Method
            <img src={arrowRight} alt="" />
          </NavLink>
          <NavLink to={routes.SUBSCRIPTION_INVOICES} className="settings-mobile__link">
            Past Invoices
            <img src={arrowRight} alt="" />
          </NavLink>

          <NavLink to={routes.SUBSCRIPTION_CANCEL} className="settings-mobile__link">
            Cancel Subscription
            <img src={arrowRight} alt="" />
          </NavLink>
        </div>
      </PageWrapper>

      <Footer />
    </>
  );
};

export default AccountMobileNav;

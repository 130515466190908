import React, { useState } from "react";
import Spinner from "react-spinkit";

import * as PropTypes from "prop-types";

const Badge = ({ text, file, style }) => {
  const [isLoading, setIsLoading] = useState(false);

  if (file) {
    const download = async e => {
      setIsLoading(true);
      e.preventDefault();
      const blob = await (await fetch(file.url)).blob();
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = `${file.name}.${file.format}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsLoading(false);
    };

    return isLoading ? (
      <div className="card-badge" style={style}>
        <Spinner name="three-bounce" />;
      </div>
    ) : (
      <a className="card-badge" style={style} href={file.url} onClick={download}>
        {text}
      </a>
    );
  }

  return (
    <div className="card-badge" style={style}>
      {text}
    </div>
  );
};

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  style: PropTypes.shape({}),
};

export default Badge;

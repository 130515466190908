import React from "react";
import * as PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import Spinner from "react-spinkit";

export const renderingId = "render";

function VirtualStagingSlider({ images = [], active, onPress }) {
  return (
    <SimpleBar className="thumbs thumbs--no_scroll">
      {images.map(image => (
        <div
          key={image.id}
          onClick={() => {
            if (image.id !== renderingId) {
              onPress(image);
            }
          }}
          className={image.id === active ? "is-active thumb" : "thumb"}
        >
          {image.id === renderingId ? (
            <div
              className="slider-image-container rendering-image"
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            >
              <Spinner name="three-bounce" />
            </div>
          ) : (
            <div
              className="slider-image-container"
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
          )}
        </div>
      ))}
    </SimpleBar>
  );
}

VirtualStagingSlider.defaultProps = {
  active: "",
};

VirtualStagingSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      link: PropTypes.string,
    })
  ).isRequired,
  active: PropTypes.string,
  onPress: PropTypes.func.isRequired,
};

export default VirtualStagingSlider;

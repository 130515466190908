import React from "react";
import * as PropTypes from "prop-types";
import { useField } from "formik";

const CheckboxField = ({ label, generalClassname, required, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label htmlFor={label} className={field.value ? "checked" : ""}>
        {required ? <span className="required">*</span> : null}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input id={label} type="checkbox" checked={field.value} {...field} {...props} />
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        <span>{label}</span>
      </label>
    </>
  );
};

CheckboxField.propTypes = {
  label: PropTypes.string,
  generalClassname: PropTypes.string,
  required: PropTypes.bool,
};

CheckboxField.defaultProps = {
  label: undefined,
  generalClassname: "",
  required: false,
};

export default CheckboxField;

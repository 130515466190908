import React from "react";
import * as PropTypes from "prop-types";

import iconClose from "../../assets/img/icons/icon-close-white.svg";
import PurchasePlan from "../PurchasePlan";

const ModalPurchasePlan = ({ onToggle, id }) => {
  return (
    <div className="modal-wrapper">
      <div className="modal-overlay" onClick={onToggle} />
      <div className="modal-window background-color-blue">
        <div className="modal-header">
          <div className="modal-header__ghost" />
          <div className="modal-title color-white">Payment details</div>
          <button className="modal-close background-color-blue" type="button" onClick={onToggle}>
            <img src={iconClose} alt="" />
          </button>
        </div>

        <PurchasePlan id={id} />
      </div>
    </div>
  );
};

ModalPurchasePlan.propTypes = {
  id: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default ModalPurchasePlan;

import { useSelector } from "react-redux";
import moment from "moment";

import { FREE_SUBSCRIPTION_PLAN } from "../constants/subscriptions";

const useSubscriptionType = () => {
  const currentSubscription = useSelector(state => state.recurly.currentSubscription);
  const isLoading = useSelector(state => state.recurly.currentSubscriptionIsLoaded);

  let id;
  let type;
  let limit;
  let isCanceled;
  let pendingChange;
  let currentPeriodEndsAt;
  let currentSubscriptionMonthly;
  if (Array.isArray(currentSubscription) && currentSubscription.length) {
    id = currentSubscription[0].id;
    type = currentSubscription[0].type;
    isCanceled = currentSubscription[0].state === "canceled";
    pendingChange = currentSubscription[0].pendingChange;
    currentPeriodEndsAt = currentSubscription[0].currentPeriodEndsAt;

    currentSubscriptionMonthly =
      Math.round(
        moment(currentSubscription[0].currentPeriodEndsAt).diff(
          moment(currentSubscription[0].currentPeriodStartedAt),
          "months",
          true
        )
      ) <= 1;
  }

  if (type === FREE_SUBSCRIPTION_PLAN.type) {
    limit = FREE_SUBSCRIPTION_PLAN.limit;
  }

  return { type, limit, isCanceled, isLoading, pendingChange, currentPeriodEndsAt, currentSubscriptionMonthly, id };
};

export default useSubscriptionType;

import React from "react";
import * as PropTypes from "prop-types";

import Modal from "../Modal";

import iconSuccess from "../../assets/img/icons/icon-success-modal.svg";

const ModalTourCreated = ({ onToggle }) => {
  return (
    <Modal onToggle={onToggle} title="Success" size="sm">
      <div className="modal-default">
        <img src={iconSuccess} className="modal-default__icon" alt="" />

        <p>Your tour was successfully uploaded!</p>

        <button type="button" className="custom_button__light custom_button-sm-width" onClick={onToggle}>
          OK
        </button>
      </div>
    </Modal>
  );
};

ModalTourCreated.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

export default ModalTourCreated;

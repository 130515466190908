import React from "react";
import * as PropTypes from "prop-types";
import classNames from "classnames";

const PageWrapper = ({ children, id = "hero", className }) => {
  return (
    <div className={classNames("page-wrapper", className)} id={id}>
      <div className="is-container">{children}</div>
    </div>
  );
};

PageWrapper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

PageWrapper.defaultProps = {
  id: undefined,
  className: undefined,
};

export default PageWrapper;

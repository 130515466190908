import { SET_TOUR_DATA } from "../types";
import { db } from "../../firebase/firebase";

// eslint-disable-next-line import/prefer-default-export
export const fetchDB = (dispatch, payload) => {
  if (!payload) return;
  db.ref(`/db/${payload.userId}/tours/${payload.tourId}`)
    .once("value")
    // eslint-disable-next-line func-names
    .then(function (snapshot) {
      dispatch({ type: SET_TOUR_DATA, payload: snapshot.val() });
    });
};

import React, { useState } from "react";

import InvoicesList from "../InvoicesList";

import billIcon from "../../assets/img/icons/icon-bills.svg";
import arrowDown from "../../assets/img/icons/icon-arrow-down.svg";

const SubscriptionInvoices = () => {
  const [openedInvoices, setOpenedInvoices] = useState(false);
  const toggleInvoices = () => setOpenedInvoices(!openedInvoices);

  return (
    <section className={`settings-subscription__row invoices-wrapper ${openedInvoices ? "is-opened" : ""}`}>
      <div className="invoices-button" onClick={() => toggleInvoices()}>
        <div className="settings-form__title">
          <img src={billIcon} alt="" />
          Past invoices
        </div>
        <img className="invoices-icon" src={arrowDown} alt="" />
      </div>

      <InvoicesList />
    </section>
  );
};

export default SubscriptionInvoices;

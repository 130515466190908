import qs from "qs";
import { v4 } from "uuid";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { toast, Slide } from "react-toastify";

import "../libs/libpannellum";
import "../libs/pannellum";

import { useDispatch } from "react-redux";
import * as routes from "../constants/routes";

import Stepper from "./Stepper";
import Slider from "./Slider";
import SliderVertical from "./SliderVertical";
import RemovePinButton from "./RemovePinButton";
import ConfirmPinButton from "./ConfirmPinButton";

import ModalDeletePin from "./modals/ModalDeletePin";
import ModalSliderVertical from "./modals/ModalSliderVertical";
import ModalTourCreated from "./modals/ModalTourCreated";

import { doGetToursByID, doSetImagePins, doUpdateTourById } from "../firebase/db";
import { getCurrentUser } from "../firebase/auth";
import checkMobile from "../hooks/checkMobile";

import iconAddPin from "../assets/img/icons/icon-add-pin.svg";
import iconArrow from "../assets/img/icon-arrow.png";
import iconArrowLeft from "../assets/img/icons/icon-arrow-left-white.svg";
import { PUBLIC_TOUR } from "../constants/searchParam";
import { loadCurrentSubscriptions } from "../redux/actions/recurlyActions";
import useSubscriptionType from "../hooks/useSubscriptionType";
import { UNLIMITED_SUBSCRIPTION_PLAN } from "../constants/subscriptions";
import { usePannelum, createHotSpot } from "../hooks/usePannelum";

const rad = angle => (angle * Math.PI) / 180;
const ang = angle => (angle / Math.PI) * 180;

const vInner = (a, b) => a.x * b.x + a.y * b.y + a.z * b.z;
const vLength = a => Math.sqrt(vInner(a, a));

const angleBetweenVectors = (a, b) => ang(Math.acos(vInner(a, b) / (vLength(a) * vLength(b))));

export const convertPinAframe2Pannelum = pin => {
  const pitch = pin.pitch || 85 - ang(Math.acos(pin.pos.y / vLength(pin.pos)));
  const yaw = pin.yaw || ang(Math.atan(pin.pos.x / pin.pos.z)) + (pin.pos.z < 0 ? 90 : 270);

  return {
    ...pin,
    pitch,
    yaw,
  };
};

export const convertPinPannelum2Aframe = pin => {
  const pos = pin.pos || {
    x: -7.683451061262298 * Math.sin(rad(85 - pin.pitch)) * Math.sin(rad(pin.yaw - 90)),
    y: 7.683451061262298 * Math.cos(rad(85 - pin.pitch)),
    z: -7.683451061262298 * Math.sin(rad(85 - pin.pitch)) * Math.cos(rad(pin.yaw - 90)),
  };

  const rot = pin.rot || {
    z: 0,
    x: 90 - angleBetweenVectors(pos, { x: 0, y: 1, z: 0 }),
    y:
      pos.x < 0
        ? angleBetweenVectors(pos, { x: 0, y: 0, z: -1 })
        : -1 * angleBetweenVectors(pos, { x: 0, y: 0, z: -1 }),
  };

  return {
    ...pin,
    pos,
    rot,
  };
};

export const convertPins = pins => {
  return pins.map(pin => {
    const pos = pin.pos || {
      x: -7.683451061262298 * Math.sin(rad(85 - pin.pitch)) * Math.sin(rad(pin.yaw - 90)),
      y: 7.683451061262298 * Math.cos(rad(85 - pin.pitch)),
      z: -7.683451061262298 * Math.sin(rad(85 - pin.pitch)) * Math.cos(rad(pin.yaw - 90)),
    };

    const rot = pin.rot || {
      z: 0,
      x: 90 - angleBetweenVectors(pos, { x: 0, y: 1, z: 0 }),
      y:
        pos.x < 0
          ? angleBetweenVectors(pos, { x: 0, y: 0, z: -1 })
          : -1 * angleBetweenVectors(pos, { x: 0, y: 0, z: -1 }),
    };

    const pitch =
      pin.pitch ||
      85 - ang(Math.acos(pin.pos.y / Math.sqrt(pin.pos.x * pin.pos.x + pin.pos.y * pin.pos.y + pin.pos.z * pin.pos.z)));
    const yaw = pin.yaw || ang(Math.atan(pin.pos.x / pin.pos.z)) + (pin.pos.z < 0 ? 90 : 270);

    return {
      ...pin,
      pos,
      rot,
      pitch,
      yaw,
    };
  });
};

const TourLinkRooms = () => {
  const { type } = useSubscriptionType();

  const canRemovedTopBar = type === UNLIMITED_SUBSCRIPTION_PLAN.type;

  const dispatch = useDispatch();
  const history = useHistory();
  const user = getCurrentUser();
  const { tourId } = useParams();
  const location = useLocation();
  const [tours, setTours] = useState();
  const [removedTopBar, setRemovedTopBar] = useState(false);
  const [activeAddPinButton, setActiveAddPinButton] = useState(false);
  const [activeAddPinButtonMobile, setActiveAddPinButtonMobile] = useState(false);
  const [thumbnailToggler, setThumbnailToggler] = useState(null);
  const [pins, setPins] = useState();
  const [activeTourId, setActiveTourId] = useState();
  const [activePin, setActivePin] = useState();
  const [isVisibleRemoveButton, setIsVisibleRemoveButton] = useState(false);
  const [isVisibleConfirmButton, setIsVisibleConfirmButton] = useState(false);
  const [isSelectRoomLinkVisible, setIsSelectRoomLinkVisible] = useState(false);
  const [isVisibleAddPinTip, setIsVisibleAddPinTip] = useState(true);
  const [isVisibleLinkRoomTip, setIsVisibleLinkRoomTip] = useState(false);
  const [isDeletePinModalVisible, setIsDeletePinModalVisible] = useState(false);
  const [activeLinkedRoomId, setActiveLinkedRoomId] = useState();
  const [notActiveBottomSlider, setNotActiveBottomSlider] = useState(false);
  const [openedMobileViewer, setOpenedMobileViewer] = useState(false);
  const [isModalSuccessVisible, setIsModalSuccessVisible] = useState(false);

  const isEdit = location.pathname.includes("edit");
  const searchParams = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);

  const isMobile = checkMobile(767);

  const handlePinClick = (_event, { id }) => {
    setActivePin(id);
    setIsSelectRoomLinkVisible(true);

    setIsVisibleConfirmButton(true);
    setIsVisibleRemoveButton(true);
    if (pins[activeTourId]) {
      setActiveLinkedRoomId(pins[activeTourId].find(pin => pin.id === id)?.tour_id);
    }
    setNotActiveBottomSlider(true);
  };

  useEffect(() => {
    loadCurrentSubscriptions(dispatch);
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;

    const getUserTour = async () => {
      let fetchedTours;

      try {
        fetchedTours = await doGetToursByID(searchParams[PUBLIC_TOUR] ? "public" : user.uid, tourId);
      } catch (err) {
        // return setError(err?.message);

        // eslint-disable-next-line no-console
        console.log("err", err?.message);
      }

      const fetchedToursValue = fetchedTours.val();

      if (isMounted) {
        setTours(fetchedToursValue);
        setRemovedTopBar(fetchedToursValue.isTopBarHidden ?? false);

        const images =
          fetchedToursValue && Object.values(fetchedToursValue.images ?? {}).sort((a, b) => a.index - b.index);

        setActiveTourId(images[0]?.id);
        setPins(
          Object.keys(fetchedToursValue.images ?? {}).reduce((acc, imageId) => {
            if (fetchedToursValue.images && fetchedToursValue.images[imageId]?.pins) {
              acc[imageId] = convertPins(Object.values(fetchedToursValue.images[imageId].pins));
            }

            return acc;
          }, {})
        );
      }
    };

    getUserTour();

    return () => {
      isMounted = false;
    };
  }, [searchParams, tourId, user.uid]);

  const tourImages = useMemo(() => {
    return tours && Object.values(tours.images ?? {}).sort((a, b) => a.index - b.index);
  }, [tours]);

  const viewer = usePannelum(tourImages, pins, activeTourId, handlePinClick);

  const onBackClick = () => history.goBack();

  const onSaveClick = useCallback(() => {
    history.push(routes.TOURS);
  }, [history]);

  const toggleMobileViewer = () => {
    setOpenedMobileViewer(!openedMobileViewer);
    setActiveAddPinButtonMobile(false);
  };

  const onAddPinClick = () => {
    setActiveAddPinButton(!activeAddPinButton);
    setNotActiveBottomSlider(true);

    if (isMobile) {
      setActiveAddPinButtonMobile(true);
    }
  };

  const handleAddPin = event => {
    if (!activeAddPinButton) {
      return;
    }

    const coords = viewer.mouseEventToCoords(event);

    const AframeCoords = convertPinPannelum2Aframe({ pitch: coords[0], yaw: coords[1] });

    setIsVisibleAddPinTip(false);
    setIsVisibleLinkRoomTip(true);
    setIsVisibleRemoveButton(true);

    const newPin = {
      id: v4(),
      is_panoramic: false,
      pitch: coords[0],
      yaw: coords[1],
      tour_id: "new-pin",
      name: "",
      ...AframeCoords,
    };

    setPins({ ...pins, [activeTourId]: [...(pins[activeTourId] || []), newPin] });

    setActiveAddPinButton(false);

    setIsSelectRoomLinkVisible(true);

    setActivePin(newPin.id);
    viewer.addHotSpot({
      ...newPin,
      clickHandlerFunc: handlePinClick,
      clickHandlerArgs: { id: newPin.id, tour_id: newPin.tour_id },
      cssClass: "custom-hotspot",
      createTooltipFunc: createHotSpot,
      createTooltipArgs: {
        image: null,
        text: newPin.name,
      },
    });
  };

  const handleConfirmPin = () => {
    setIsSelectRoomLinkVisible(false);

    setIsVisibleRemoveButton(false);
    setIsVisibleConfirmButton(false);
    setActiveLinkedRoomId("");
    setActivePin(undefined);
    setNotActiveBottomSlider(false);
  };

  const handleCreateLink = roomId => {
    const room = tours?.images && tours.images[roomId];
    if (!room || !pins[activeTourId]?.length || !isSelectRoomLinkVisible) {
      return;
    }

    const pannellumHotSport = pins[activeTourId][pins[activeTourId].length - 1];

    if (pannellumHotSport.tour_id !== room.id) {
      const newPin = {
        ...pannellumHotSport,
        text: room.name,
        tour_id: room.id,
      };

      setPins({
        ...pins,
        [activeTourId]: [
          ...pins[activeTourId].slice(0, pins[activeTourId].length - 1),
          {
            name: room.name,
            tour_id: room.id,
            id: pannellumHotSport.id,
            pos: pannellumHotSport.pos,
            rot: pannellumHotSport.rot,
            is_panoramic: pannellumHotSport.is_panoramic,
          },
        ],
      });

      viewer.removeHotSpot(pannellumHotSport.id);
      viewer.addHotSpot({
        ...newPin,
        clickHandlerFunc: handlePinClick,
        clickHandlerArgs: { id: newPin.id, tour_id: newPin.tour_id },
        createTooltipFunc: createHotSpot,
        cssClass: "custom-hotspot",
        createTooltipArgs: {
          image: tourImages.find(item => item.id === room.id),
          text: room.name,
        },
      });
    }

    setActiveLinkedRoomId(roomId);
    setIsVisibleLinkRoomTip(false);
    setIsVisibleConfirmButton(true);

    if (isMobile) {
      setIsSelectRoomLinkVisible(false);

      setIsVisibleRemoveButton(false);
      setIsVisibleConfirmButton(false);
      setActiveLinkedRoomId("");
      setActivePin(undefined);
      setNotActiveBottomSlider(false);

      setActiveAddPinButton(false);
      setActiveAddPinButtonMobile(false);
    }
  };

  const toggleDeletePinModal = () => {
    setIsDeletePinModalVisible(!isDeletePinModalVisible);

    if (isMobile) {
      setIsSelectRoomLinkVisible(false);
    }
  };

  const handleRemovePin = () => {
    setPins({
      ...pins,
      [activeTourId]: pins[activeTourId].filter(({ id }) => id !== activePin),
    });
    setIsVisibleRemoveButton(false);
    setIsVisibleConfirmButton(false);
    viewer.removeHotSpot(activePin);
    setActivePin(undefined);
    setIsDeletePinModalVisible(false);
    setNotActiveBottomSlider(false);
    setActiveLinkedRoomId("");

    if (isMobile) {
      setActiveAddPinButtonMobile(false);
    }
  };

  // const activeTourImage = tours?.images && tours.images[activeTourId];

  const handleModalTourCreate = async () => {
    try {
      await doSetImagePins(tours.publicTour ? "public" : user.uid, tourId, pins);

      if (removedTopBar !== tours.isTopBarHidden) {
        const userId = tours.publicTour ? "public" : user.uid;
        await doUpdateTourById(userId, tourId, { isTopBarHidden: removedTopBar });
      }

      if (location.pathname.includes("edit")) {
        toast.success("Changes have been saved", {
          position: "top-right",
          autoClose: 3000,
          transition: Slide,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      } else {
        setIsModalSuccessVisible(!isModalSuccessVisible);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <>
      <Stepper currentStep={2} />

      <div className="tour-link-rooms">
        {tourImages?.length ? <div className="tour-link-rooms__mobile-title">Assign pins in Editor</div> : null}

        <div className={`is-scene-container ${openedMobileViewer ? "expanded-link-window" : ""}`}>
          <div className={`${tourImages?.length ? "tour-link-rooms__body" : "tour-link-rooms__body-empty"} `}>
            {tourImages?.length > 1 && (
              <div className="tour-link-rooms__add-pins">
                <div className="btn-default-pin__wrapper">
                  {isMobile && (
                    <button type="button" className="btn-default-pin btn-back-pin" onClick={toggleMobileViewer}>
                      <img src={iconArrowLeft} alt="" />
                    </button>
                  )}
                  <button
                    type="button"
                    className={`btn-default-pin btn-add-pin ${
                      activeAddPinButton || activeAddPinButtonMobile ? "is-active" : ""
                    }`}
                    onClick={onAddPinClick}
                  >
                    <img src={iconAddPin} alt="" />
                    <span>Add Pin</span>
                  </button>
                </div>

                {isVisibleAddPinTip && <div className="add-pin__tip">Add pin to link rooms</div>}

                <div className="btn-default-pin__wrapper">
                  <ConfirmPinButton onClick={handleConfirmPin} isVisible={isVisibleConfirmButton} />

                  <RemovePinButton onClick={toggleDeletePinModal} isVisible={isVisibleRemoveButton} />
                </div>
              </div>
            )}

            {tourImages?.length ? (
              <button onClick={toggleMobileViewer} className="custom_button__light btn-show-link-window" type="button">
                Open in Viewer
              </button>
            ) : null}

            {tourImages?.length ? (
              <div className="tour-link-rooms__scene" onClick={e => e.stopPropagation()}>
                <div id="panorama" onClick={handleAddPin} />

                <div
                  className={`is-thumb-container ${thumbnailToggler ? "is-hidden" : ""} ${
                    notActiveBottomSlider ? "is-not-active" : ""
                  }`}
                >
                  <div className="thumbnails-header">
                    <button
                      className="tour-button is-btn-toggle"
                      onClick={() => setThumbnailToggler(!thumbnailToggler)}
                      type="button"
                    >
                      <img className="icon-arrow" src={iconArrow} alt="Arrow toggle" />
                    </button>
                  </div>
                  {tours && <Slider rooms={tourImages} active={activeTourId} onPress={setActiveTourId} />}
                </div>
              </div>
            ) : (
              <p>Tour doesn&apos;t have any files. Please upload them first.</p>
            )}

            {tourImages?.length > 1 && !isMobile && (
              <div className="tour-link-rooms__rooms">
                <SliderVertical
                  linked={activeLinkedRoomId}
                  rooms={tourImages}
                  active={activeTourId}
                  onPress={handleCreateLink}
                />
                {isVisibleLinkRoomTip && (
                  <div className="add-room__tip add-room__tip-link-room">Сhoose Room to attach to the Pin</div>
                )}
              </div>
            )}
            {tourImages?.length > 1 && isMobile && isSelectRoomLinkVisible && (
              <ModalSliderVertical
                linked={activeLinkedRoomId}
                rooms={tourImages}
                active={activeTourId}
                onPress={handleCreateLink}
                onToggle={() => {
                  setIsSelectRoomLinkVisible(false);
                }}
              />
            )}
          </div>
        </div>

        <div className="tour-wrapper__footer">
          <div className="checkbox checkbox-sm">
            {tours && canRemovedTopBar && Boolean(!searchParams[PUBLIC_TOUR]) && (
              <label htmlFor="removedTopBar" className={`${removedTopBar ? "checked" : ""}`}>
                <input
                  type="checkbox"
                  id="removedTopBar"
                  name="removedTopBar"
                  checked={removedTopBar}
                  onChange={() => setRemovedTopBar(!removedTopBar)}
                />
                <span>Remove the top skyward bar</span>
              </label>
            )}
          </div>

          <div className="tour-wrapper__buttons">
            <button
              type="button"
              onClick={onBackClick}
              className="custom_button-nav custom_button-nav__light custom_button-nav-outlined"
            >
              Back
            </button>

            <button
              disabled={isEdit && !tourImages?.length}
              type="button"
              onClick={handleModalTourCreate}
              className="custom_button-nav custom_button-nav__light"
            >
              Save
            </button>
          </div>
        </div>

        {isDeletePinModalVisible && <ModalDeletePin onToggle={toggleDeletePinModal} onConfirmClick={handleRemovePin} />}
      </div>

      {isModalSuccessVisible && <ModalTourCreated onToggle={onSaveClick} />}
    </>
  );
};

export default TourLinkRooms;
